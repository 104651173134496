import { Component, EventEmitter, Output, ViewEncapsulation, Input, OnInit } from '@angular/core';

@Component({
    selector: 'user-conf-dialog',
    template: `
        <kendo-dialog *ngIf="opened" >
            <p style="margin: 30px; text-align: center;color:green" *ngIf="actionType === 'Add' "> 
            
            User has been Added Successfully.
            </p>

            <p style="margin: 30px; text-align: center;color:green" *ngIf="actionType === 'Update' "> 
            
            User details have been Updated Successfully.
            </p>

            <kendo-dialog-actions style="justify-content:center;" [layout]="actionsLayout">
            
                <button kendoButton (click)="onDialogClose()">Ok</button>
            </kendo-dialog-actions>
        </kendo-dialog>
    `,
    encapsulation: ViewEncapsulation.None,
    styles: ['.k-dialog-close { display: none; }']
})
export class UserConfirmDialogComponent implements OnInit {

    @Input('userFlag') userFlag:boolean = false;
    @Input('actionType') actionType: any;
    @Output() toggleUserDialog :  EventEmitter<any> = new EventEmitter<any>()
 
    ngOnInit(){
        this.opened = this.userFlag;
    }
    public opened;
    public actionsLayout = 'normal';

    public onDialogClose() {
        this.opened = false;
        this.toggleUserDialog.emit();
    }

}
