import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import {Session} from 'src/app/components/Models/session.model';
import {SessionResponse, SessionLoginResponse} from 'src/app/components/Models/sessionresponse.model';
import {ExcelandTransactionResponse} from 'src/app/components/Models/excelresponse.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeapiService {

  headers: any;
  apiurl = '';

  constructor(private oidcSecurityService: OidcSecurityService, private httpClient: HttpClient) {
      this.apiurl = environment.APIEndpoint;
      this.headers = new HttpHeaders({Authorization: this.getAuthorizationHeaderValue()});
  }

    getAuthorizationHeaderValue(): string {
      if (this.oidcSecurityService != null) {
        return 'Bearer ' + this.oidcSecurityService.getToken();
      } else {
        return '';
      }
    }

  public getAuthHash(body: Session) {
    const api = this.apiurl + 'session/search';
    return this.httpClient.post<SessionResponse>(api, body, {headers: this.headers});
  }

  public postAuthHash(body: Session) {
    const api = this.apiurl + 'session';
    return this.httpClient.post<SessionLoginResponse>(api, body, {headers: this.headers});
  }

  public AuthorizeUser(nnumber: string) {
    const api = this.apiurl + 'users/' + nnumber;
    return this.httpClient.get<ExcelandTransactionResponse>(api, {headers: this.headers});
  }
}
