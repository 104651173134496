import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthModule, LogLevel, OidcConfigService, OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';

export function loadConfig(oidcConfigService: OidcConfigService) {
  return () =>
    oidcConfigService.withConfig({
      stsServer: environment.STSServer,
      authWellknownEndpoint: environment.authWellknownEndpoint ,
      redirectUrl: window.location.origin,
      postLogoutRedirectUri: window.location.origin + '/#/logout',
      clientId: environment.clientId,
      scope: environment.scope,
      responseType: environment.responseType,
      silentRenew: true,
      maxIdTokenIatOffsetAllowedInSeconds: environment.maxIdTokenIatOffsetAllowedInSeconds,
      issValidationOff: true,
      autoUserinfo: false,
      silentRenewUrl: window.location.origin + '/silent-refresh.html',
      logLevel: LogLevel.Error,
      // customParams: {
      //   prompt: 'login', // login, consent
      // },
    });
}

@NgModule({
  imports: [AuthModule.forRoot()],
  providers: [
    OidcSecurityService,
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [OidcConfigService],
      multi: true,
    },
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
