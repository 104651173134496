import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { ExcelExportComponent } from 'src/app/components/excel-export/excel-export.component';
import { MatDialog , MatDialogConfig} from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ApiService } from 'src/app/services/api.service';



@Component({
  selector: 'catalyst-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  userData$: Observable<any>;
  isAuthenticated$: Observable<boolean>;
  isAdmin: boolean;
  isRoleAccess: boolean;
  userInfo: any;

  loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();

  constructor(public oidcSecurityService: OidcSecurityService,
              private router: Router, private navigationService: NavigationService ,
               private modalservice: MatDialog, private apiService: ApiService
) { }

  ngOnInit() {
    this.isAuthenticated$ = this.oidcSecurityService.isAuthenticated$;
    this.userData$ = this.oidcSecurityService.userData$;

    this.loadingSubject.next(true);
    this.navigationService.hidetab();
    this.navigationService.hidetransactiontab();
    this.navigationService.hideaddpage();
    this.navigationService.hideupdatebuttonflag();
    this.navigationService.hideusermaintenancepage();
    this.navigationService.hideusermaintenancetab();
    this.navigationService.removeAllMenus();
    this.navigationService.redirecttotrxn = false;
    this.navigationService.redirecttohome = false;

    let NNumber = sessionStorage.getItem('UserNumber');

    this.apiService.AuthorizeUser(NNumber).subscribe((response) => {
      if(response.statusCode === 200) {
        this.userInfo = response.body;
        
        if(this.userInfo[0].Is_Admin) {
          this.isAdmin = true
        } else {
          this.isAdmin = false
        }
        if(this.userInfo[0].Role) {
          this.isRoleAccess  = true
        } else {
          this.isRoleAccess = false
        }

        this.loadingSubject.next(false);
      }
    })
  }


transactions() {
  this.router.navigate(['transactions']);
}

userMaintenance() {
  this.router.navigate(['user-maintenance'])
}

excel_export() {
  const dialogConfig = new MatDialogConfig();
  this.navigationService.unhideonexcelclose = false;
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.hasBackdrop = true;
  dialogConfig.panelClass = 'custom-dialog-container';

  this.modalservice.open(ExcelExportComponent, dialogConfig);
}

}
