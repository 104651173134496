import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import {TransactionsComponent} from './components/transactions/transactions.component';
import {AddEditComponent} from './components/add-edit/add-edit.component';
import {ExcelExportComponent} from './components/excel-export/excel-export.component';
import {AddtransactioComponent} from './components/addtransactio/addtransactio.component';
import { AuthorizationGuard } from './services/authorization.guard';
import { AutoLoginComponent } from './components/auto-login/auto-login.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { LogoutComponent } from './logout/logout.component';
import { UserMaintenanceComponent } from './components/user-maintenance/user-maintenance.component';
import { AdminGuard } from './guards/admin.guard';
import { RoleGuard } from './guards/role.guard';
import { SuperUserGuard } from './guards/superuser.guard';
// import { AuthGuardService } from './services/auth-guard.service';
// import { AuthCallbackComponent } from './auth-callback/auth-callback.component';

const appRoutes: Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthorizationGuard] },
    { path: 'home', component: HomeComponent, canActivate: [AuthorizationGuard] },
    { path: 'autologin', component: AutoLoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'forbidden', component: ForbiddenComponent },
    { path: 'transactions', component: TransactionsComponent, canActivate: [AuthorizationGuard, RoleGuard] },
    { path: 'add-edit', component: AddEditComponent, canActivate: [AuthorizationGuard, RoleGuard] },
    { path: 'excel-export', component: ExcelExportComponent, canActivate: [AuthorizationGuard, RoleGuard] },
    { path: 'addtransactio', component: AddtransactioComponent, canActivate: [AuthorizationGuard, RoleGuard, SuperUserGuard] },
    { path: 'user-maintenance', component: UserMaintenanceComponent, canActivate: [AuthorizationGuard, AdminGuard] },
    // { path: 'auth-callback', component: AuthCallbackComponent }
];

export const routing = RouterModule.forRoot(appRoutes, { useHash: true });
