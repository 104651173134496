// core
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ModuleWithProviders, DoBootstrap, ApplicationRef } from '@angular/core';
import {HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';

// ngx translation
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

// components
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

// services
import { NavigationService } from './services/navigation.service';
import { DatePipe } from '@angular/common';

// primeng
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

// modules
import { SharedModule } from 'src/shared/shared.module';
import { ExampleProductModule } from 'src/products/example-product/example-product.module';

// catalyst
import { CatalystComponentsModule } from '@lmig/catalyst-component-library';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { AddEditComponent } from './components/add-edit/add-edit.component';
import { ExcelExportComponent } from './components/excel-export/excel-export.component';
import { AddtransactioComponent } from './components/addtransactio/addtransactio.component';
import { UserMaintenanceComponent } from './components/user-maintenance/user-maintenance.component';

// popup
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

// matexpansion
import { MatExpansionModule } from '@angular/material/expansion';

// matcard
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

// mattable
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';

// Validations
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// matautocomplete
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';

// Primng Toast
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { FlexLayoutModule } from '@angular/flex-layout';

// Date format declaration
// import {CustomDateFormatDirective}
// from './components/excel-export/excel-export.component';
import {DateAdapter} from '@angular/material/core';
import {CustomDateAdapter} from './components/excel-export/excel-export.component';


// Kendo
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
// import { LayoutModule } from '@progress/kendo-angular-layout';
// import { DropDownButton } from '@progress/kendo-angular-buttons';
import { LabelModule } from '@progress/kendo-angular-label';
import { SaveDialogueComponent } from './components/save-dialogue/save-dialogue.component';
// import { AuthGuardService } from './services/auth-guard.service';
// import { AuthService } from './services/auth.service';
import { LogoutComponent } from './logout/logout.component';
import { routing } from './app-routes';
import { AuthorizationGuard } from './services/authorization.guard';
import { AutoLoginComponent } from './components/auto-login/auto-login.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { AuthConfigModule } from './services/auth-config.module';
import { MenubarModule } from 'primeng/menubar';
import { UserConfirmDialogComponent } from './components/confirm-dialog/userconfirm-dialog.component';
import { AdminGuard } from './guards/admin.guard';
import { RoleGuard } from './guards/role.guard';
import { SuperUserGuard } from './guards/superuser.guard';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    TransactionsComponent,
    AddEditComponent,
    ExcelExportComponent,
    AddtransactioComponent,
    SaveDialogueComponent,
    LogoutComponent,
    AutoLoginComponent,
    ForbiddenComponent,
    UserMaintenanceComponent,
    UserConfirmDialogComponent,
   // CustomDateFormatDirective
  ],
  imports: [MatCheckboxModule, MatDatepickerModule, MatNativeDateModule,
    MatRippleModule, ReactiveFormsModule, MatAutocompleteModule,
    MatInputModule, MatPaginatorModule, MatProgressSpinnerModule, FormsModule,
    MatSortModule, MatTableModule, MatFormFieldModule, MatListModule,
    MatButtonModule, MatTooltipModule, MatIconModule,
    MatCardModule,
    MatDialogModule, MatExpansionModule,
    BrowserModule,
    BrowserAnimationsModule,
    CatalystComponentsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    routing,
    SharedModule,
    ExampleProductModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    FlexLayoutModule,
    GridModule,
    ChartsModule,
    InputsModule,
    PDFModule,
    ExcelModule,
    AuthConfigModule,
    DateInputsModule,
    // DropDownButton,
    // MenuModule,
    MenubarModule,
    LabelModule,
    ButtonsModule,
    DialogModule,
    // LayoutModule
  ],
  entryComponents: [ExcelExportComponent, SaveDialogueComponent],
  providers: [
    MessageService,
    NavigationService,
    MatDialog,
    AuthorizationGuard,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    DatePipe,
    AdminGuard,
    RoleGuard,
    SuperUserGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
