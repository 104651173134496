import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TransactionByIDResponse } from 'src/app/components/Models/transactionbyIDResponse.model';
import { Transactions } from 'src/app/components/Models/transactions.model';
import { IssuingPaper } from 'src/app/components/Models/issuingpaper.model';
import { Source } from 'src/app/components/Models/source.model';
import { TransactionType } from 'src/app/components/Models/transactiontype.model';
import { ProfitCenter } from 'src/app/components/Models/profitcenter.model';
import { TransactionStatus } from 'src/app/components/Models/transactionstatus.model';
import { ParticipantName } from 'src/app/components/Models/participantname.model';
import { PolicyNumberModel } from 'src/app/components/Models/policynumber.model';
import { WhoRequestedModel } from 'src/app/components/Models/whorequested.model';
import { AccountNameModel } from 'src/app/components/Models/accountname.model';
import { AccountPeriodModel } from 'src/app/components/Models/accountperiod.model';
import { CurrencyValues } from 'src/app/components/Models/currency.model';
import { ExcelandTransactionResponse } from 'src/app/components/Models/excelresponse.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { DecimalPipe } from '@angular/common';

import { TransactionSource } from 'src/app/components/Models/transactionsource.model';


@Component({
  selector: 'catalyst-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [MessageService, DatePipe, DecimalPipe]
})
export class AddEditComponent implements OnInit, OnDestroy {

  selectedrowid: number;
  public primaryinfoform: FormGroup;
  public monetarydetailsform: FormGroup;
  public participantform: FormGroup;
  public mfxtransmissionform: FormGroup;
  public updateinfoform: FormGroup;
  public glupdateform: FormGroup;

  public complexitycodes$ = [];
  public IsFacultative$ = [];
  public QuotaShare$ = [];
  public IsXOL$ = [];
  public IsIISResponsible$ = [];
  public ReinsuranceType$ = [];
  public Participantnamelis$ = [];

  localIsFacultative: string;
  localQuotaShare: string;
  localIsXOL: string;
  localReinsuranceType: string;
  localIsIISResponsible: string;
  localparticipantname1: string;
  localparticipantname2: string;
  localparticipantname3: string;
  localparticipantname4: string;
  localparticipantname5: string;

  public format = 'MM/dd/yyyy HH:mm';
  public format1 = 'MM/dd/yyyy';

  loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();

  isformvalid = false;
  summary;
  textMessage;
  validnumber = false;
  validpercentage = false;
  validprimaryinfo = false;
  isparticipantnamevalid = false;

  copysubscription: Subscription;
  updatesubscription: Subscription;

  genericresponse: TransactionByIDResponse;
  transactionsbody: Transactions[];
  specifictransactions: Transactions = new Transactions();

  IssuingPaperList: string[] = [];
  TransactionTypeList: string[] = [];
  TransactionSourceList: string[] = [];

  SourceList: string[] = [];
  ProfitCenterList: string[] = [];
  TransactionStatusList: string[] = [];
  PartcipantNameList: string[] = [];
  PartcipantNameCode: string[] = [];
  PolicyNumberList: string[] = [];
  WhoRequestedList: string[] = [];
  AccountNameList: string[] = [];
  AccountPeriodList: string[] = [];
  CurrencyList: string[] = [];

  date: Date;
  latestdate: string;
  minDate: Date;
  whorequestedfilteredOptions: Observable<string[]>;
  profitcenterfilteredOptions: Observable<string[]>;
  iisresponsiblefilteredOptions: Observable<string[]>;
  trxntypefilteredOptions: Observable<string[]>;
  accountnamefilteredOptions: Observable<string[]>;
  isfacultativefilteredOptions: Observable<string[]>;
  reinsurancetypefilteredOptions: Observable<string[]>;
  accountperiodfilteredOptions: Observable<string[]>;
  policynumberfilteredOptions: Observable<string[]>;
  quotasharefilteredOptions: Observable<string[]>;
  sourcefilteredOptions: Observable<string[]>;
  codefilteredOptions: Observable<string[]>;
  isxolfilteredOptions: Observable<string[]>;
  issuingpaperlistfilteredOptions: Observable<string[]>;
  currencyfilteredOptions: Observable<string[]>;
  participantname1filteredOptions: Observable<string[]>;
  participantname2filteredOptions: Observable<string[]>;
  participantname3filteredOptions: Observable<string[]>;
  participantname4filteredOptions: Observable<string[]>;
  participantname5filteredOptions: Observable<string[]>;
  trxstatusfilteredOptions: Observable<string[]>;

  trxnSourcefilteredOptions: Observable<string[]>;

  masterresponsebody: any;
  roleId: any;

  transactionobject: Transactions = new Transactions();

  trxnstatushash: IHash = { '': null };
  trxnstatushash2: IHash = { '': null };
  trxntypehash: IHash = { '': null };
  trxntypehash2: IHash = { '': null };
  IsFacultativehash: IHashnumber = { No: 0, Yes: -1, '': null };
  QuotaSharehash: IHashnumber = { 'N - Include in QS': 0, 'Y - Exclude from QS': -1, '': null };
  IsXOLhash: IHashnumber = { 'N - Exclude from XOL': 0, 'Y - Include in XOL': -1, '': null };
  IsIISResponsiblehash: IHashnumber = { No: 0, Yes: -1, '': null };
  participanthash: IHashnumber = { '': null };

  addtransactionresponse: ExcelandTransactionResponse = new ExcelandTransactionResponse();


  constructor(private navigationService: NavigationService, private router: Router,
              private apiService: ApiService, private fb: FormBuilder,
              private messageService: MessageService, private datepipe: DatePipe,
              private decimalpipe: DecimalPipe) {
    this.minDate = new Date('01/01/1900');
  }


  ngOnDestroy() {
    this.copysubscription.unsubscribe();
    this.updatesubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.navigationService.removeAllMenus();
    this.loadingSubject.next(true);
    this.roleId = sessionStorage.getItem('Role');
    this.initialzieformgropus();
    this.getmastertabledata();
    this.navigationService.stopupdatethistransaction();
    this.navigationService.stopcopytransactionupdateflag();
    this.navigationService.onaddpage = false;
    this.navigationService.onupdatepage = true;
    this.navigationService.onusermaintenancepage = false;
    this.updateapicall();
    this.copyrecord();
    this.complexitycodes$ = ['1', '2', '3'];
    this.IsFacultative$ = ['No', 'Yes'];
    this.QuotaShare$ = ['N - Include in QS', 'Y - Exclude from QS'];
    this.IsXOL$ = ['N - Exclude from XOL', 'Y - Include in XOL'];
    this.IsIISResponsible$ = ['No', 'Yes'];
    this.ReinsuranceType$ = ['Direct', 'Assumed'];
    this.Participantnamelis$ = ['Ironshore', 'CV Starr',
      'Hamilton Re Ltd', 'Antares Syndicate 1274', 'Non-Ironshore Other',
      'Oil Casualty Insurance, Ltd.', 'LIU'];
    this.navigationService.hidetab();
    this.navigationService.showupdatebuttonflag();
    this.navigationService.hideaddpage();
    this.navigationService.hideusermaintenancepage();
    this.navigationService.hideusermaintenancetab();
    this.navigationService.showtransactiontab();
  }


  openspecifictransaction() {
    this.selectedrowid = this.navigationService.rowid;
    this.apiService.getTransactionsbyID(this.selectedrowid).subscribe(data => {
      this.genericresponse = data as TransactionByIDResponse;
      this.transactionsbody = this.genericresponse.body;
      this.transactionsbody.forEach((value) => {
        this.specifictransactions = value;
        // console.log(this.specifictransactions);
      });
      if (this.specifictransactions.IsFacultative === 0) {
        this.localIsFacultative = 'No';
      } else if (this.specifictransactions.IsFacultative === -1) {
        this.localIsFacultative = 'Yes';
      }
      if (this.specifictransactions.IsQuoteShare === 0) {
        this.localQuotaShare = 'N - Include in QS';
      } else if (this.specifictransactions.IsQuoteShare === -1) {
        this.localQuotaShare = 'Y - Exclude from QS';
      }
      if (this.specifictransactions.IsXOL === 0) {
        this.localIsXOL = 'N - Exclude from XOL';
      } else if (this.specifictransactions.IsXOL === -1) {
        this.localIsXOL = 'Y - Include in XOL';
      }
      if (this.specifictransactions.IsIISResponsible === 0) {
        this.localIsIISResponsible = 'No';
      } else if (this.specifictransactions.IsIISResponsible === -1) {
        this.localIsIISResponsible = 'Yes';
      }
      if (this.specifictransactions.ParticipantName1 !== null) {
        if (this.specifictransactions.ParticipantName1 === 1) {
          this.localparticipantname1 = this.Participantnamelis$[0];
        } else if (this.specifictransactions.ParticipantName1 === 2) {
          this.localparticipantname1 = this.Participantnamelis$[1];
        } else if (this.specifictransactions.ParticipantName1 === 3) {
          this.localparticipantname1 = this.Participantnamelis$[2];
        } else if (this.specifictransactions.ParticipantName1 === 4) {
          this.localparticipantname1 = this.Participantnamelis$[3];
        } else if (this.specifictransactions.ParticipantName1 === 5) {
          this.localparticipantname1 = this.Participantnamelis$[4];
        } else if (this.specifictransactions.ParticipantName1 === 6) {
          this.localparticipantname1 = this.Participantnamelis$[5];
        } else if (this.specifictransactions.ParticipantName1 === 7) {
          this.localparticipantname1 = this.Participantnamelis$[6];
        }
      } else {
        this.localparticipantname1 = '';
      }
      if (this.specifictransactions.ParticipantName2 !== null) {
        if (this.specifictransactions.ParticipantName2 === 1) {
          this.localparticipantname2 = this.Participantnamelis$[0];
        } else if (this.specifictransactions.ParticipantName2 === 2) {
          this.localparticipantname2 = this.Participantnamelis$[1];
        } else if (this.specifictransactions.ParticipantName2 === 3) {
          this.localparticipantname2 = this.Participantnamelis$[2];
        } else if (this.specifictransactions.ParticipantName2 === 4) {
          this.localparticipantname2 = this.Participantnamelis$[3];
        } else if (this.specifictransactions.ParticipantName2 === 5) {
          this.localparticipantname2 = this.Participantnamelis$[4];
        } else if (this.specifictransactions.ParticipantName2 === 6) {
          this.localparticipantname2 = this.Participantnamelis$[5];
        } else if (this.specifictransactions.ParticipantName2 === 7) {
          this.localparticipantname2 = this.Participantnamelis$[6];
        }
      } else {
        this.localparticipantname2 = '';
      }
      if (this.specifictransactions.ParticipantName3 !== null) {
        if (this.specifictransactions.ParticipantName3 === 1) {
          this.localparticipantname3 = this.Participantnamelis$[0];
        } else if (this.specifictransactions.ParticipantName3 === 2) {
          this.localparticipantname3 = this.Participantnamelis$[1];
        } else if (this.specifictransactions.ParticipantName3 === 3) {
          this.localparticipantname3 = this.Participantnamelis$[2];
        } else if (this.specifictransactions.ParticipantName3 === 4) {
          this.localparticipantname3 = this.Participantnamelis$[3];
        } else if (this.specifictransactions.ParticipantName3 === 5) {
          this.localparticipantname3 = this.Participantnamelis$[4];
        } else if (this.specifictransactions.ParticipantName3 === 6) {
          this.localparticipantname3 = this.Participantnamelis$[5];
        } else if (this.specifictransactions.ParticipantName3 === 7) {
          this.localparticipantname3 = this.Participantnamelis$[6];
        }
      } else {
        this.localparticipantname3 = '';
      }
      if (this.specifictransactions.ParticipantName4 !== null) {
        if (this.specifictransactions.ParticipantName4 === 1) {
          this.localparticipantname4 = this.Participantnamelis$[0];
        } else if (this.specifictransactions.ParticipantName4 === 2) {
          this.localparticipantname4 = this.Participantnamelis$[1];
        } else if (this.specifictransactions.ParticipantName4 === 3) {
          this.localparticipantname4 = this.Participantnamelis$[2];
        } else if (this.specifictransactions.ParticipantName4 === 4) {
          this.localparticipantname4 = this.Participantnamelis$[3];
        } else if (this.specifictransactions.ParticipantName4 === 5) {
          this.localparticipantname4 = this.Participantnamelis$[4];
        } else if (this.specifictransactions.ParticipantName4 === 6) {
          this.localparticipantname4 = this.Participantnamelis$[5];
        } else if (this.specifictransactions.ParticipantName4 === 7) {
          this.localparticipantname4 = this.Participantnamelis$[6];
        }
      } else {
        this.localparticipantname4 = '';
      }
      if (this.specifictransactions.ParticipantName5 !== null) {
        if (this.specifictransactions.ParticipantName5 === 1) {
          this.localparticipantname5 = this.Participantnamelis$[0];
        } else if (this.specifictransactions.ParticipantName5 === 2) {
          this.localparticipantname5 = this.Participantnamelis$[1];
        } else if (this.specifictransactions.ParticipantName5 === 3) {
          this.localparticipantname5 = this.Participantnamelis$[2];
        } else if (this.specifictransactions.ParticipantName5 === 4) {
          this.localparticipantname5 = this.Participantnamelis$[3];
        } else if (this.specifictransactions.ParticipantName5 === 5) {
          this.localparticipantname5 = this.Participantnamelis$[4];
        } else if (this.specifictransactions.ParticipantName5 === 6) {
          this.localparticipantname5 = this.Participantnamelis$[5];
        } else if (this.specifictransactions.ParticipantName5 === 7) {
          this.localparticipantname5 = this.Participantnamelis$[6];
        }
      } else {
        this.localparticipantname5 = '';
      }
      this.patchvaluesinformgroups();
      //     this.whorequestedfilteredOptions = this.primaryinfoform.controls.WhoRequested.valueChanges.pipe(
      //   startWith(''),
      //   map(value => this._filterwhorequested(value))
      // );

      this.profitcenterfilteredOptions = this.primaryinfoform.controls.ProfitCenter.valueChanges.pipe(
        startWith(''),
        map(value => this._filterprofitcenter(value))
      );
      this.iisresponsiblefilteredOptions = this.primaryinfoform.controls.IISResponsible.valueChanges.pipe(
        startWith(''),
        map(value => this._filteriisresponsible(value))
      );
      this.trxntypefilteredOptions = this.primaryinfoform.controls.TxnType.valueChanges.pipe(
        startWith(''),
        map(value => this._filtertrxntype(value))
      );
      //     this.accountnamefilteredOptions = this.primaryinfoform.controls.AccountName.valueChanges.pipe(
      //   startWith(''),
      //   map(value => this._filteraccountname(value))
      // );
      this.isfacultativefilteredOptions = this.primaryinfoform.controls.Fac.valueChanges.pipe(
        startWith(''),
        map(value => this._filterisfacultive(value))
      );
      this.reinsurancetypefilteredOptions = this.primaryinfoform.controls.Reins.valueChanges.pipe(
        startWith(''),
        map(value => this._filterreinsurancetype(value))
      );
      this.accountperiodfilteredOptions = this.primaryinfoform.controls.AccountPeriod.valueChanges.pipe(
        startWith(''),
        map(value => this._filteraccountperiod(value))
      );
      //     this.policynumberfilteredOptions = this.primaryinfoform.controls.Policy.valueChanges.pipe(
      //   startWith(''),
      //   map(value => this._filterpolicynumber(value))
      // );
      this.quotasharefilteredOptions = this.primaryinfoform.controls.QuotaShare.valueChanges.pipe(
        startWith(''),
        map(value => this._filterquotashare(value))
      );
      this.sourcefilteredOptions = this.primaryinfoform.controls.Source.valueChanges.pipe(
        startWith(''),
        map(value => this._filtersource(value))
      );
      this.codefilteredOptions = this.primaryinfoform.controls.ComplexityCode.valueChanges.pipe(
        startWith(''),
        map(value => this._filtercode(value))
      );
      this.isxolfilteredOptions = this.primaryinfoform.controls.XOL.valueChanges.pipe(
        startWith(''),
        map(value => this._filterisxol(value))
      );
      this.issuingpaperlistfilteredOptions = this.primaryinfoform.controls.IssuingPaper.valueChanges.pipe(
        startWith(''),
        map(value => this._filterissuingpaper(value))
      );
      this.currencyfilteredOptions = this.monetarydetailsform.controls.Currency.valueChanges.pipe(
        startWith(''),
        map(value => this._filtercurrency(value))
      );
      this.participantname1filteredOptions = this.participantform.controls.ParticipantName1.valueChanges.pipe(
        startWith(''),
        map(value => this._filterparticipantname1(value))
      );
      this.participantname2filteredOptions = this.participantform.controls.ParticipantName2.valueChanges.pipe(
        startWith(''),
        map(value => this._filterparticipantname1(value))
      );
      this.participantname3filteredOptions = this.participantform.controls.ParticipantName3.valueChanges.pipe(
        startWith(''),
        map(value => this._filterparticipantname1(value))
      );
      this.participantname4filteredOptions = this.participantform.controls.ParticipantName4.valueChanges.pipe(
        startWith(''),
        map(value => this._filterparticipantname1(value))
      );
      this.participantname5filteredOptions = this.participantform.controls.ParticipantName5.valueChanges.pipe(
        startWith(''),
        map(value => this._filterparticipantname1(value))
      );
      this.trxstatusfilteredOptions = this.mfxtransmissionform.controls.TrxnStatus.valueChanges.pipe(
        startWith(''),
        map(value => this._filtertrxstatus(value))
      );
      this.trxnSourcefilteredOptions = this.primaryinfoform.controls.TxnSource.valueChanges.pipe(
        startWith(''),
        map(value => this._filtertrxnSource(value))
      );

      this.changeState();
      this.loadingSubject.next(false);
    });

  }

  transactions() {
    this.router.navigate(['transactions']);
  }

  home() {
    this.router.navigate(['']);
  }


  changeState() {
    this.participantform.get('ParticipantName2').valueChanges
      .subscribe(res => {
        this.participantform.controls.ParticipantPercent2.enable();
        this.participantform.controls.FeePercent2.enable();
      });
    this.participantform.get('ParticipantName3').valueChanges
      .subscribe(res => {
        this.participantform.controls.ParticipantPercent3.enable();
        this.participantform.controls.FeePercent3.enable();
      });
    this.participantform.get('ParticipantName4').valueChanges
      .subscribe(res => {
        this.participantform.controls.ParticipantPercent4.enable();
        this.participantform.controls.FeePercent4.enable();
      });
    this.participantform.get('ParticipantName5').valueChanges
      .subscribe(res => {
        this.participantform.controls.ParticipantPercent5.enable();
        this.participantform.controls.FeePercent5.enable();
      });
  }

  limitfocusOutFunction() {
    if (this.monetarydetailsform.get('Limits').value != null
      && !this.monetarydetailsform.get('Limits').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('Limits').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          Limits: this.decimalpipe.transform(value, '1.2-2', 'en')
        }
      );
    }
  }

  partoffocusOutFunction() {
    if (this.monetarydetailsform.get('PartOf').value != null
      && !this.monetarydetailsform.get('PartOf').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('PartOf').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          PartOf: this.decimalpipe.transform(value, '1.2-2', 'en')
        }
      );
    }
  }

  attachmentfocusOutFunction() {
    if (this.monetarydetailsform.get('Attachment').value != null
      && !this.monetarydetailsform.get('Attachment').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('Attachment').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          Attachment: this.decimalpipe.transform(value, '1.2-2', 'en')
        }
      );
    }
  }

  trxamountfocusOutFunction() {
    if (this.monetarydetailsform.get('TrxAmount').value != null
      && !this.monetarydetailsform.get('TrxAmount').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('TrxAmount').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          TrxAmount: this.decimalpipe.transform(value, '1.2-2', 'en')
        }
      );
    }
  }
  TaxesfocusOutFunction() {
    if (this.monetarydetailsform.get('Taxes').value != null
      && !this.monetarydetailsform.get('Taxes').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          Taxes: this.decimalpipe.transform(value, '1.2-2')
        }
      );
    }
  }
  FeesfocusOutFunction() {
    if (this.monetarydetailsform.get('Fees').value != null
      && !this.monetarydetailsform.get('Fees').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          Fees: this.decimalpipe.transform(value, '1.2-2')
        }
      );
    }
  }
  SurchargesfocusOutFunction() {
    if (this.monetarydetailsform.get('Surcharges').value != null
      && !this.monetarydetailsform.get('Surcharges').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          Surcharges: this.decimalpipe.transform(value, '1.2-2')
        }
      );
    }
  }

  commissionfocusOutFunction() {
    if (this.monetarydetailsform.get('Commission').value != null
      && !this.monetarydetailsform.get('Commission').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('Commission').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          Commission: this.decimalpipe.transform(value, '1.2-2')
        }
      );
    }
  }

  writtenlinefocusOutFunction() {
    if (this.monetarydetailsform.get('WrittenLine').value != null
      && !this.monetarydetailsform.get('WrittenLine').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('WrittenLine').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          WrittenLine: this.decimalpipe.transform(value, '1.5-5')
        }
      );
    }
  }
  percentage1focusOutFunction() {
    if (this.participantform.get('ParticipantPercent1').value != null
      && !this.participantform.get('ParticipantPercent1').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('ParticipantPercent1').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          ParticipantPercent1: value1
        }
      );
    }
  }

  perticipantname1focusOutFunction() {
    if (this.participantform.get('ParticipantName1').value === '') {
      this.participantform.get('ParticipantPercent1').reset(null);
      this.participantform.get('FeePercent1').reset(null);
    } else {
      if (this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName4').value
        || this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName5').value) {
        this.participantform.get('ParticipantName1').reset('');
        this.summary = 'ParticipantName1 Already selected';
        this.textMessage = 'This Participant Name Already selected';
        this.messageService.add({
          severity: 'error',
          summary: 'ParticipantName1 Already selected',
          detail: '',
        });
      }
    }
  }
  perticipantname2focusOutFunction() {
    if (this.participantform.get('ParticipantName2').value === '') {
      this.participantform.get('ParticipantPercent2').reset(null);
      this.participantform.get('FeePercent2').reset(null);
    } else {
      if (this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName4').value
        || this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName5').value) {
        this.participantform.get('ParticipantName2').reset('');
        this.summary = 'ParticipantName2 Already selected';
        this.textMessage = 'This Participant Name Already selected';
        this.messageService.add({
          severity: 'error',
          summary: 'ParticipantName2 Already selected',
          detail: '',
        });
      }
    }
  }
  perticipantname3focusOutFunction() {
    if (this.participantform.get('ParticipantName3').value === '') {
      this.participantform.get('ParticipantPercent3').reset(null);
      this.participantform.get('FeePercent3').reset(null);
    } else {
      if (this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName4').value
        || this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName5').value) {
        this.participantform.get('ParticipantName3').reset('');
        this.summary = 'ParticipantName3 Already selected';
        this.textMessage = 'This Participant Name Already selected';
        this.messageService.add({
          severity: 'error',
          summary: 'ParticipantName3 Already selected',
          detail: '',
        });
      }
    }
  }
  perticipantname4focusOutFunction() {
    if (this.participantform.get('ParticipantName4').value === '') {
      this.participantform.get('ParticipantPercent4').reset(null);
      this.participantform.get('FeePercent4').reset(null);
    } else {
      if (this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName5').value) {
        this.participantform.get('ParticipantName4').reset('');
        this.summary = 'ParticipantName4 Already selected';
        this.textMessage = 'This Participant Name Already selected';
        this.messageService.add({
          severity: 'error',
          summary: 'ParticipantName4 Already selected',
          detail: '',
        });
      }
    }
  }
  perticipantname5focusOutFunction() {
    if (this.participantform.get('ParticipantName5').value === '') {
      this.participantform.get('ParticipantPercent5').reset(null);
      this.participantform.get('FeePercent5').reset(null);
    } else {
      if (this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName4').value) {
        this.participantform.get('ParticipantName5').reset('');
        this.summary = 'ParticipantName5 Already selected';
        this.textMessage = 'This Participant Name Already selected';
        this.messageService.add({
          severity: 'error',
          summary: 'ParticipantName5 Already selected',
          detail: '',
        });
      }
    }
  }
  fee1focusOutFunction() {
    if (this.participantform.get('FeePercent1').value != null
      && !this.participantform.get('FeePercent1').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('FeePercent1').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          FeePercent1: value1
        }
      );
    }
  }
  percentage2focusOutFunction() {
    if (this.participantform.get('ParticipantPercent2').value != null
      && !this.participantform.get('ParticipantPercent2').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('ParticipantPercent2').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          ParticipantPercent2: value1
        }
      );
    }
  }
  fee2focusOutFunction() {
    if (this.participantform.get('FeePercent2').value != null
      && !this.participantform.get('FeePercent2').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('FeePercent2').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          FeePercent2: value1
        }
      );
    }
  }
  percentage3focusOutFunction() {
    if (this.participantform.get('ParticipantPercent3').value != null
      && !this.participantform.get('ParticipantPercent3').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('ParticipantPercent3').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          ParticipantPercent3: value1
        }
      );
    }
  }
  fee3focusOutFunction() {
    if (this.participantform.get('FeePercent3').value != null
      && !this.participantform.get('FeePercent3').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('FeePercent3').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          FeePercent3: value1
        }
      );
    }
  }
  percentage4focusOutFunction() {
    if (this.participantform.get('ParticipantPercent4').value != null
      && !this.participantform.get('ParticipantPercent4').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('ParticipantPercent4').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          ParticipantPercent4: value1
        }
      );
    }
  }
  fee4focusOutFunction() {
    if (this.participantform.get('FeePercent4').value != null
      && !this.participantform.get('FeePercent4').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('FeePercent4').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          FeePercent4: value1
        }
      );
    }
  }
  percentage5focusOutFunction() {
    if (this.participantform.get('ParticipantPercent5').value != null
      && !this.participantform.get('ParticipantPercent5').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('ParticipantPercent5').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          ParticipantPercent5: value1
        }
      );
    }
  }
  fee5focusOutFunction() {
    if (this.participantform.get('FeePercent5').value != null
      && !this.participantform.get('FeePercent5').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('FeePercent5').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          FeePercent5: value1
        }
      );
    }
  }

  modelChanged($event) {
    if (this.monetarydetailsform.get('Commission').value != null) {
      if (parseFloat(this.monetarydetailsform.get('Commission').value
        .toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        const comission = parseFloat(this.monetarydetailsform.get('Commission').value.toString().replace(/,/g, ''));
        if ($event !== '' && $event.toString().replace(/,/g, '').match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const trxnamount = parseFloat($event.replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CommissionD: this.decimalpipe.transform(((trxnamount * comission) / 100), '1.2-2', 'en')
          });
        } else {
          this.monetarydetailsform.patchValue({
            CommissionD: null
          });
        }
      }
    }
  }

  commissionmodelChanged($event) {
    if (this.monetarydetailsform.get('TrxAmount').value != null) {
      if (parseFloat(this.monetarydetailsform.get('TrxAmount')
        .value.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        const trxnamount = parseFloat(this.monetarydetailsform.get('TrxAmount').value.toString().replace(/,/g, ''));
        if ($event !== '' && $event.toString().replace(/,/g, '').match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const comission = parseFloat($event.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CommissionD: this.decimalpipe.transform(((trxnamount * comission) / 100), '1.2-2', 'en')
          });
        } else {
          this.monetarydetailsform.patchValue({
            CommissionD: null
          });
        }
      }
    }
  }

  taxesChanged($event) {
    const enteredvalue = $event;
    if (this.monetarydetailsform.get('Fees').value != null
      && parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
      if (this.monetarydetailsform.get('Surcharges').value != null
        && parseFloat(this.monetarydetailsform.get('Surcharges')
          .value.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
          const taxes = parseFloat(enteredvalue.toString().replace(/,/g, ''));
          const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees + taxes + surcharges, '1.2-2', 'en')
          });
        } else {
          const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
          const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees + surcharges, '1.2-2', 'en')
          });
        }
      } else {
        if ($event !== '' && parseFloat(enteredvalue.replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
          const taxes = parseFloat(enteredvalue.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees + taxes, '1.2-2', 'en')
          });
        } else {
          const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees, '1.2-2', 'en')
          });
        }
      }
    }
    if ((this.monetarydetailsform.get('Surcharges').value != null
      && this.monetarydetailsform.get('Surcharges').value.toString().match(/^-?[0-9]+(\.?[0-9]+)?$/))
      && (this.monetarydetailsform.get('Fees').value == null
        || this.monetarydetailsform.get('Fees').value === '')) {
      if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        const taxes = parseFloat($event.toString().replace(/,/g, ''));
        const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(surcharges + taxes, '1.2-2', 'en')
        });
      } else {
        const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(surcharges, '1.2-2', 'en')
        });
      }
    }
    if ((this.monetarydetailsform.get('Fees').value == null
      || this.monetarydetailsform.get('Fees').value === '')
      && (this.monetarydetailsform.get('Surcharges').value == null
        || this.monetarydetailsform.get('Surcharges').value === '')
      && enteredvalue != null) {
      if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(parseFloat($event.toString().replace(/,/g, '')), '1.2-2', 'en')
        });
      } else {
        this.monetarydetailsform.patchValue({
          CalculatedTFS: null
        });
      }
    }
  }

  feesChanged($event) {
    const enteredvalue = $event;
    if (this.monetarydetailsform.get('Taxes').value != null
      && parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
      if (this.monetarydetailsform.get('Surcharges').value != null
        && parseFloat(this.monetarydetailsform.get('Surcharges')
          .value.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
          const fees = parseFloat($event.toString().replace(/,/g, ''));
          const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees + taxes + surcharges, '1.2-2', 'en')
          });
        } else {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
          const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(taxes + surcharges, '1.2-2', 'en')
          });
        }
      } else {
        if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
          const fees = parseFloat($event.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees + taxes, '1.2-2', 'en')
          });
        } else {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(taxes, '1.2-2', 'en')
          });
        }
      }
    }
    if ((this.monetarydetailsform.get('Surcharges').value != null
      && this.monetarydetailsform.get('Surcharges').value.toString().match(/^-?[0-9]+(\.?[0-9]+)?$/))
      && (this.monetarydetailsform.get('Taxes').value == null
        || this.monetarydetailsform.get('Taxes').value === '')) {
      if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        const fees = parseFloat($event.toString().replace(/,/g, ''));
        const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(surcharges + fees, '1.2-2', 'en')
        });
      } else {
        const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(surcharges, '1.2-2', 'en')
        });
      }
    }
    if ((this.monetarydetailsform.get('Taxes').value == null
      || this.monetarydetailsform.get('Taxes').value === '')
      && (this.monetarydetailsform.get('Surcharges').value == null
        || this.monetarydetailsform.get('Surcharges').value === '')
      && enteredvalue != null) {
      if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(parseFloat($event.toString().replace(/,/g, '')), '1.2-2', 'en')
        });
      } else {
        this.monetarydetailsform.patchValue({
          CalculatedTFS: null
        });
      }
    }
  }

  surchargesChanged($event) {
    const enteredvalue = $event;
    if (this.monetarydetailsform.get('Taxes').value != null
      && parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
      if (this.monetarydetailsform.get('Fees').value != null
        && parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
          const surcharges = parseFloat($event.toString().replace(/,/g, ''));
          const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees + taxes + surcharges, '1.2-2', 'en')
          });
        } else {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
          const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees + taxes, '1.2-2', 'en')
          });
        }
      } else {
        if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
          const surcharges = parseFloat($event.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(surcharges + taxes, '1.2-2', 'en')
          });
        } else {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(taxes, '1.2-2', 'en')
          });
        }
      }
    }
    if ((this.monetarydetailsform.get('Fees').value != null
      && this.monetarydetailsform.get('Fees').value.toString().match(/^-?[0-9]+(\.?[0-9]+)?$/))
      && (this.monetarydetailsform.get('Taxes').value == null
        || this.monetarydetailsform.get('Taxes').value === '')) {
      if ($event !== '' && parseFloat(enteredvalue.replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        const surcharges = parseFloat($event.toString().replace(/,/g, ''));
        const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(surcharges + fees, '1.2-2', 'en')
        });
      } else {
        const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(fees, '1.2-2', 'en')
        });
      }
    }
    if ((this.monetarydetailsform.get('Fees').value == null
      || this.monetarydetailsform.get('Fees').value === '')
      && (this.monetarydetailsform.get('Taxes').value == null
        || this.monetarydetailsform.get('Taxes').value === '')
      && enteredvalue != null) {
      if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(parseFloat($event.toString().replace(/,/g, '')), '1.2-2', 'en')
        });
      } else {
        this.monetarydetailsform.patchValue({
          CalculatedTFS: null
        });
      }
    }
  }

  updateapicall() {
    this.updatesubscription = this.navigationService.isupdatetransaction.subscribe((data) => {
      if (data === true) {
        this.isformvalid = false;
        this.validnumber = false;
        this.validpercentage = false;
        this.validprimaryinfo = false;
        this.isparticipantnamevalid = false;
        this.checkprimaryinfovalidations();
        this.checknumericvalidations();
        this.sameparticipantnamevalidation();
        this.checkhighestpercentage();
        this.checkvalidations();
        if (this.isformvalid && this.validnumber && this.validpercentage
          && this.validprimaryinfo && this.isparticipantnamevalid) {
          this.loadingSubject.next(true);
          this.date = new Date();
          this.latestdate = this.datepipe.transform(this.date, 'yyyy-MM-dd HH:mm:ss');
          this.updatetransaction();
          console.log(this.transactionobject);
          this.apiService.updatetransaction(this.selectedrowid, this.transactionobject).subscribe(response => {
            this.addtransactionresponse = new ExcelandTransactionResponse();
            this.addtransactionresponse = response;
            if (this.addtransactionresponse.message === 'Transaction Updated!!') {
              this.summary = 'Transaction Updated';
              this.textMessage = 'Transaction Updated Successfully';
              this.messageService.add({
                severity: 'success',
                summary: 'Transaction Updated',
                detail: '',
              });
              this.updateinfoform.patchValue(
                {
                  WhoUpdated: this.navigationService.whoCreatedUpdated,
                  WhenUpdated: this.datepipe.transform(this.latestdate, 'MM-dd-yyyy HH:mm:ss'),
                }
              );
              if (this.navigationService.redirecttotrxn === true) {
                this.router.navigate(['transactions']);
              }
              if (this.navigationService.redirecttohome === true) {
                this.router.navigate(['']);
              }
            } else {
              this.summary = 'Something Went Wrong';
              this.textMessage = 'Transaction Not Updated';
              this.messageService.add({
                severity: 'error',
                summary: 'Something Went Wrong',
                detail: '',
              });
            }
            this.loadingSubject.next(false);
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.summary,
            detail: '',
          });
        }
      }
    });
  }

  _filteriisresponsible(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.IsIISResponsible$.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filterwhorequested(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.WhoRequestedList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filterprofitcenter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.ProfitCenterList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filtertrxntype(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.TransactionTypeList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filteraccountname(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.AccountNameList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filterisfacultive(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.IsFacultative$.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filterreinsurancetype(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.ReinsuranceType$.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filteraccountperiod(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.AccountPeriodList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filterpolicynumber(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.PolicyNumberList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filterquotashare(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.QuotaShare$.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filtersource(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.SourceList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filtercode(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.complexitycodes$.filter(option => option.toString().toLowerCase().indexOf(filterValue) === 0);
  }
  _filterisxol(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.IsXOL$.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filterissuingpaper(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.IssuingPaperList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filtercurrency(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.CurrencyList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filterparticipantname1(value: string): string[] {
    const filterValue = (value ? value : '').toLowerCase();

    return this.PartcipantNameList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filtertrxstatus(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.TransactionStatusList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filtertrxnSource(value: string): string[] {
    const filterValue = value.toLowerCase();
 
    return this.TransactionSourceList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }


  getmastertabledata() {
    // this.apiService.getmasterspolicynumber().subscribe(policynumberdata => {
    //     let policynumbervalues: PolicyNumberModel[] = [];
    //     policynumbervalues = policynumberdata.body;
    //     policynumbervalues.forEach((value) => {
    //         this.PolicyNumberList.push(value.PolicyNumber);
    //       });
    //      this.policynumberfilteredOptions = this.primaryinfoform.controls.Policy.valueChanges.pipe(
    //         startWith(''),
    //         map(value => this._filterpolicynumber(value))
    //       );
    //   });
    // this.apiService.getmasterwhorequested().subscribe(whorequesteddata => {
    //       let whorequestedvalues: WhoRequestedModel[] = [];
    //       whorequestedvalues = whorequesteddata.body;
    //       whorequestedvalues.forEach((value) => {
    //           this.WhoRequestedList.push(value.WhoRequested);
    //         });
    //       this.whorequestedfilteredOptions = this.primaryinfoform.controls.WhoRequested.valueChanges.pipe(
    //           startWith(''),
    //           map(value => this._filterwhorequested(value))
    //         );
    //     });
    // this.apiService.getmasteraccountname().subscribe(accountnamedata => {
    //         let accountnamevalues: AccountNameModel[] = [];
    //         accountnamevalues = accountnamedata.body;
    //         accountnamevalues.forEach((value) => {
    //             this.AccountNameList.push(value.AccountName);
    //           });
    //         this.accountnamefilteredOptions = this.primaryinfoform.controls.AccountName.valueChanges.pipe(
    //             startWith(''),
    //             map(value => this._filteraccountname(value))
    //           );
    //       });
    this.apiService.getmasteraccountperiod().subscribe(accountperioddata => {
      let accountperiod: AccountPeriodModel[] = [];
      accountperiod = accountperioddata.body;
      accountperiod.forEach((value) => {
        this.AccountPeriodList.push(value.Period);
      });
    });
    this.apiService.getmastercurrency().subscribe(currencydata => {
      let currencyvalues: CurrencyValues[] = [];
      currencyvalues = currencydata.body;
      currencyvalues.forEach((value) => {
        this.CurrencyList.push(value.currency);
      });
    });
    this.apiService.getmastersdata().subscribe(masterdata => {
      let issuingpaper: IssuingPaper[] = [];
      let transactiontype: TransactionType[] = [];
      let source: Source[] = [];
      let profitcenter: ProfitCenter[] = [];
      let transactionstatus: TransactionStatus[] = [];
      let participantname: ParticipantName[] = [];
      let transactionsource : TransactionSource[] = [];
      this.masterresponsebody = masterdata.body;
      issuingpaper = this.masterresponsebody[2];
      transactiontype = this.masterresponsebody[7];
      source = this.masterresponsebody[5];
      profitcenter = this.masterresponsebody[4];
      transactionstatus = this.masterresponsebody[6];
      participantname = this.masterresponsebody[3];

      transactionsource = this.masterresponsebody[9];

      issuingpaper.forEach((value) => {
        this.IssuingPaperList.push(value.issuingpaper.issuingpaper);
      });
      transactiontype.forEach((value) => {
        const code: string = value.transactiontype.transactiontypecode;
        const desc: string = value.transactiontype.transactiontypedesc;
        this.trxntypehash[desc] = code;
        this.trxntypehash2[code] = desc;
        this.TransactionTypeList.push(desc);
      });
      source.forEach((value) => {
        this.SourceList.push(value.source.sourcedesc);
      });
      profitcenter.forEach((value) => {
        this.ProfitCenterList.push(value.profitcenter.profitcenter);
      });
      transactionstatus.forEach((value) => {
        const code: string = value.transactionstatus.code;
        const desc: string = value.transactionstatus.description;
        this.trxnstatushash[code] = desc;
        this.trxnstatushash2[desc] = code;
        this.TransactionStatusList.push(code + '-' + desc);
      });
      participantname.forEach((value) => {
        const name: string = value.participantname.participantname;
        const id: string = value.participantname.ParticipantID.toString();
        this.PartcipantNameList.push(name);
        this.PartcipantNameCode.push(id);
        this.participanthash[name] = Number.parseInt(id, 10);

      });
      transactionsource.forEach((value) => {
        const transactionsourcedesc: string = value.TransactionSource.TransactionSourceDescription;
        this.TransactionSourceList.push(transactionsourcedesc);
      });

      this.openspecifictransaction();
    });
  }
  convertintodecimal(Value: any) {
    if (Value) {
      return this.decimalpipe.transform(Value, '1.2-2', 'en');
    }
  }

  participantconvertintodecimal(Value: any) {
    if (Value) {
      return this.decimalpipe.transform(Value, '1.4-4');
    }
  }
  writtenconvertintodecimal(Value: any) {
    if (Value) {
      return this.decimalpipe.transform(Value, '1.5-5', 'en');
    }
  }

  patchvaluesinformgroups() {
    let effdate1 = '';
    let effdate2 = '';
    let effdate3 = new Date();
    if (this.specifictransactions.EffectiveDate !== ''
      && this.specifictransactions.EffectiveDate != null) {
      effdate1 = this.specifictransactions.EffectiveDate.toString().replace('T', ' ');
      effdate2 = effdate1.replace('Z', '');
      effdate3 = new Date(effdate2);
    } else {
      effdate3 = null;
    }
    this.primaryinfoform.patchValue(
      {
        TransactionID: this.specifictransactions.TransactionID,
        WhoRequested: this.specifictransactions.WhoRequested,
        ProfitCenter: this.specifictransactions.ProfitCenter,
        IISResponsible: this.localIsIISResponsible,
        TxnType: this.trxntypehash2[this.specifictransactions.TransactionTypeCode],
        AccountName: this.specifictransactions.AccountName,
        Fac: this.localIsFacultative,
        Reins: this.specifictransactions.ReinsuranceType,
        AccountPeriod: this.specifictransactions.AccountingPeriodCode,
        Policy: this.specifictransactions.PolicyNumber,
        EffDate: effdate3,
        QuotaShare: this.localQuotaShare,
        Source: this.specifictransactions.Source,
        ComplexityCode: this.specifictransactions.ComplexityCodes,
        XOL: this.localIsXOL,
        IssuingPaper: this.specifictransactions.IssuingPaper,
        TxnSource: this.specifictransactions.TransactionSourceCode
      });
    this.monetarydetailsform.patchValue({
      Limits: this.specifictransactions.Limits,
      PartOf: this.specifictransactions.PartOf,
      Attachment: this.specifictransactions.Attachment,
      TrxAmount: this.specifictransactions.TrxAmount,
      Taxes: this.specifictransactions.Taxes,
      Fees: this.specifictransactions.Fees,
      Surcharges: this.specifictransactions.Surcharges,
      CalculatedTFS: this.specifictransactions.CalculatedTFS,
      Commission: this.specifictransactions.Commission,
      CommissionD: this.specifictransactions.CommissionD ??
        this.decimalpipe.transform(this.specifictransactions.CommissionD, '1.2-2', 'en'),
      WrittenLine: this.specifictransactions.WrittenLine ?? this.decimalpipe.transform(this.specifictransactions.WrittenLine, '1.5-5'),
      Currency: this.specifictransactions.Currency,
    });
    if (this.localparticipantname2 != null
      && this.localparticipantname2 !== '' && this.roleId === '2') {
      this.participantform.controls.ParticipantPercent2.enable();
      this.participantform.controls.FeePercent2.enable();
    }
    if (this.localparticipantname3 != null
      && this.localparticipantname3 !== '' && this.roleId === '2') {
      this.participantform.controls.ParticipantPercent3.enable();
      this.participantform.controls.FeePercent3.enable();
    }
    if (this.localparticipantname4 != null
      && this.localparticipantname4 !== '' && this.roleId === '2') {
      this.participantform.controls.ParticipantPercent4.enable();
      this.participantform.controls.FeePercent4.enable();
    }
    if (this.localparticipantname5 != null
      && this.localparticipantname5 !== '' && this.roleId === '2') {
      this.participantform.controls.ParticipantPercent5.enable();
      this.participantform.controls.FeePercent5.enable();
    }
    this.participantform.patchValue({
      ParticipantName1: this.localparticipantname1,
      ParticipantPercent1: this.specifictransactions.ParticipantPercent1,
      FeePercent1: this.specifictransactions.FeePercent1,
      ParticipantName2: this.localparticipantname2,
      ParticipantPercent2: this.specifictransactions.ParticipantPercent2,
      FeePercent2: this.specifictransactions.FeePercent2,
      ParticipantName3: this.localparticipantname3,
      ParticipantPercent3: this.specifictransactions.ParticipantPercent3,
      FeePercent3: this.specifictransactions.FeePercent3,
      ParticipantName4: this.localparticipantname4,
      ParticipantPercent4: this.specifictransactions.ParticipantPercent4,
      FeePercent4: this.specifictransactions.FeePercent4,
      ParticipantName5: this.localparticipantname5,
      ParticipantPercent5: this.specifictransactions.ParticipantPercent5,
      FeePercent5: this.specifictransactions.FeePercent5,
    });
    let localtrxnstatuscode = null;
    if (this.specifictransactions.TransactionStatusCode != null) {
      localtrxnstatuscode = this.specifictransactions.TransactionStatusCode
        + '-' + this.trxnstatushash[this.specifictransactions.TransactionStatusCode];
    }
    let recvdtobook1 = '';
    let recvdtobook2 = '';
    let recvdtobook3 = new Date();
    if (this.specifictransactions.WhenSentToMFX !== ''
      && this.specifictransactions.WhenSentToMFX != null) {
      recvdtobook1 = this.specifictransactions.WhenSentToMFX.toString().replace('T', ' ');
      recvdtobook2 = recvdtobook1.replace('Z', '');
      recvdtobook3 = new Date(recvdtobook2);
    } else {
      recvdtobook3 = null;
    }
    let recvdfrommfx1 = '';
    let recvdfrommfx2 = '';
    let recvdfrommfx3 = new Date();
    if (this.specifictransactions.WhenRcvdFromMFX !== ''
      && this.specifictransactions.WhenRcvdFromMFX != null) {
      recvdfrommfx1 = this.specifictransactions.WhenRcvdFromMFX.toString().replace('T', ' ');
      recvdfrommfx2 = recvdfrommfx1.replace('Z', '');
      recvdfrommfx3 = new Date(recvdfrommfx2);
    } else {
      recvdfrommfx3 = null;
    }
    let whenreconcd1 = '';
    let whenreconcd2 = '';
    let whenreconcd3 = new Date();
    if (this.specifictransactions.WhenReconciled !== ''
      && this.specifictransactions.WhenReconciled != null) {
      whenreconcd1 = this.specifictransactions.WhenReconciled.toString().replace('T', ' ');
      whenreconcd2 = whenreconcd1.replace('Z', '');
      whenreconcd3 = new Date(whenreconcd2);
    } else {
      whenreconcd3 = null;
    }
    this.mfxtransmissionform.patchValue({
      ReconciliationStatus: this.specifictransactions.ReconciliationStatus,
      WhenReconciled: whenreconcd3,
      WhenRcvdFromMFX: recvdfrommfx3,
      ReceivedtoBooking: recvdtobook3,
      TrxnStatus: localtrxnstatuscode,
    });
    let whencreated1 = '';
    let whencreated2 = '';
    let whencreated3 = '';
    let whenupdated1 = '';
    let whenupdated2 = '';
    let whenupdated3 = '';
    if (this.specifictransactions.WhenCreated !== ''
      && this.specifictransactions.WhenCreated != null) {
      whencreated1 = this.specifictransactions.WhenCreated.toString().replace('T', ' ');
      whencreated2 = whencreated1.replace('Z', '');
      whencreated3 = this.datepipe.transform(whencreated2, 'MM-dd-yyyy HH:mm:ss');
    }
    if (this.specifictransactions.WhenUpdated !== ''
      && this.specifictransactions.WhenUpdated != null) {
      whenupdated1 = this.specifictransactions.WhenUpdated.toString().replace('T', ' ');
      whenupdated2 = whenupdated1.replace('Z', '');
      whenupdated3 = this.datepipe.transform(whenupdated2, 'MM-dd-yyyy HH:mm:ss');
    }
    this.updateinfoform.patchValue({
      WhoCreated: this.specifictransactions.WhoCreated,
      WhenCreated: whencreated3,
      WhoUpdated: this.specifictransactions.WhoUpdated,
      WhenUpdated: whenupdated3,
    });
    this.glupdateform.patchValue({
      Notes: this.specifictransactions.Notes,
    });

  }
  public datechange(type: string, value: Date): void {
    console.log(value);
    if (type === 'ReceivedtoBooking') {
      this.mfxtransmissionform.patchValue({
        ReceivedtoBooking: value,
      });
    }
    if (type === 'WhenReconciled') {
      this.mfxtransmissionform.patchValue({
        WhenReconciled: value,
      });
    }
    if (type === 'WhenRcvdFromMFX') {
      this.mfxtransmissionform.patchValue({
        WhenRcvdFromMFX: value,
      });
    }
    if (type === 'EffDate') {
      // console.log('EffDate', value);
      // value = this.datepipe.transform(value, 'MM/dd/yyyy');
      console.log('EffDate', value);
      this.primaryinfoform.patchValue({
        EffDate: value,
      });
    }
  }
  initialzieformgropus() {
    if (this.roleId === '2') {
      this.primaryinfoform = this.fb.group({
        TransactionID: new FormControl({ value: null, disabled: true }),
        WhoRequested: new FormControl(null),
        ProfitCenter: new FormControl(null),
        IISResponsible: new FormControl(''),
        TxnType: new FormControl(''),
        AccountName: new FormControl(null),
        Fac: new FormControl(''),
        Reins: new FormControl(null),
        AccountPeriod: new FormControl(''),
        Policy: new FormControl(null),
        EffDate: new FormControl(null),
        QuotaShare: new FormControl(''),
        Source: new FormControl(null),
        ComplexityCode: new FormControl(null),
        XOL: new FormControl(''),
        IssuingPaper: new FormControl(null),
        TxnSource: new FormControl('')
      });

      this.monetarydetailsform = this.fb.group({
        Limits: new FormControl(null),
        PartOf: new FormControl(null),
        Attachment: new FormControl(null),
        TrxAmount: new FormControl(null),
        Taxes: new FormControl(null),
        Fees: new FormControl(null),
        Surcharges: new FormControl(null),
        CalculatedTFS: new FormControl({ value: null, disabled: true }),
        Commission: new FormControl(null),
        CommissionD: new FormControl({ value: null, disabled: true }),
        WrittenLine: new FormControl(null),
        Currency: new FormControl(null),
      });

      this.participantform = this.fb.group(
        {
          ParticipantName1: new FormControl(''),
          ParticipantPercent1: new FormControl(null),
          FeePercent1: new FormControl(null),
          ParticipantName2: new FormControl(''),
          ParticipantPercent2: new FormControl({ value: null, disabled: true }),
          FeePercent2: new FormControl({ value: null, disabled: true }),
          ParticipantName3: new FormControl(''),
          ParticipantPercent3: new FormControl({ value: null, disabled: true }),
          FeePercent3: new FormControl({ value: null, disabled: true }),
          ParticipantName4: new FormControl(''),
          ParticipantPercent4: new FormControl({ value: null, disabled: true }),
          FeePercent4: new FormControl({ value: null, disabled: true }),
          ParticipantName5: new FormControl(''),
          ParticipantPercent5: new FormControl({ value: null, disabled: true }),
          FeePercent5: new FormControl({ value: null, disabled: true }),
        }
      );

      this.mfxtransmissionform = this.fb.group({

        ReconciliationStatus: new FormControl(null),
        WhenReconciled: new FormControl(null),
        WhenRcvdFromMFX: new FormControl(null),
        ReceivedtoBooking: new FormControl(new Date(this.latestdate)),
        TrxnStatus: new FormControl(null),
      });

      this.glupdateform = this.fb.group({
        Notes: new FormControl(null)
      });
    } else if (this.roleId === '1') {
      this.primaryinfoform = this.fb.group({
        TransactionID: new FormControl({ value: null, disabled: true }),
        WhoRequested: new FormControl({value: null, disabled: true}),
        ProfitCenter: new FormControl({value: null, disabled: true}),
        IISResponsible: new FormControl({value: '', disabled: true}),
        TxnType: new FormControl({value: '', disabled: true}),
        AccountName: new FormControl({ value: null, disabled: true }),
        Fac: new FormControl({value: '', disabled: true}),
        Reins: new FormControl({value: null, disabled: true}),
        AccountPeriod: new FormControl({value: '', disabled: true}),
        Policy: new FormControl({value: null, disabled: true}),
        EffDate: new FormControl({value: null, disabled: true}),
        QuotaShare: new FormControl({value: '', disabled: true}),
        Source: new FormControl({value: null, disabled: true}),
        ComplexityCode: new FormControl({value: null, disabled: true}),
        XOL: new FormControl({value: '', disabled: true}),
        IssuingPaper: new FormControl({value: null, disabled: true}),
        TxnSource: new FormControl({value: '', disabled: true})
      });

      this.monetarydetailsform = this.fb.group({
        Limits: new FormControl({value: null, disabled: true}),
        PartOf: new FormControl({value: null, disabled: true}),
        Attachment: new FormControl({value: null, disabled: true}),
        TrxAmount: new FormControl({value: null, disabled: true}),
        Taxes: new FormControl({value: null, disabled: true}),
        Fees: new FormControl({value: null, disabled: true}),
        Surcharges: new FormControl({value: null, disabled: true}),
        CalculatedTFS: new FormControl({ value: null, disabled: true }),
        Commission: new FormControl({value: null, disabled: true}),
        CommissionD: new FormControl({ value: null, disabled: true }),
        WrittenLine: new FormControl({value: null, disabled: true}),
        Currency: new FormControl({value: null, disabled: true}),
      });

      this.participantform = this.fb.group(
        {
          ParticipantName1: new FormControl({value: '', disabled: true}),
          ParticipantPercent1: new FormControl({value: null, disabled: true}),
          FeePercent1: new FormControl({value: null, disabled: true}),
          ParticipantName2: new FormControl({value: '', disabled: true}),
          ParticipantPercent2: new FormControl({ value: null, disabled: true }),
          FeePercent2: new FormControl({ value: null, disabled: true }),
          ParticipantName3: new FormControl({value: '', disabled: true}),
          ParticipantPercent3: new FormControl({ value: null, disabled: true }),
          FeePercent3: new FormControl({ value: null, disabled: true }),
          ParticipantName4: new FormControl({value: '', disabled: true}),
          ParticipantPercent4: new FormControl({ value: null, disabled: true }),
          FeePercent4: new FormControl({ value: null, disabled: true }),
          ParticipantName5: new FormControl({value: '', disabled: true}),
          ParticipantPercent5: new FormControl({ value: null, disabled: true }),
          FeePercent5: new FormControl({ value: null, disabled: true }),
        }
      );

      this.mfxtransmissionform = this.fb.group({

        ReconciliationStatus: new FormControl({value: null, disabled: true}),
        WhenReconciled: new FormControl({value: null, disabled: true}),
        WhenRcvdFromMFX: new FormControl({value: null, disabled: true}),
        ReceivedtoBooking: new FormControl({value: new Date(this.latestdate), disabled: true}),
        TrxnStatus: new FormControl({value: null, disabled: true}),
      });

      this.glupdateform = this.fb.group({
        Notes: new FormControl({value: null, disabled: true})
      });
    }

    this.updateinfoform = this.fb.group({
      WhoCreated: new FormControl({ value: null, disabled: true }),
      WhenCreated: new FormControl({ value: null, disabled: true }),
      WhoUpdated: new FormControl({ value: null, disabled: true }),
      WhenUpdated: new FormControl({ value: null, disabled: true }),
    });

    this.primaryinfoform.controls.TxnType.valueChanges.subscribe(value=>{
      value=='' || value==null ? this.primaryinfoform.controls.TxnSource.disable() : this.primaryinfoform.controls.TxnSource.enable();
    })

  }

  checknumericvalidations() {
    const multicheck: boolean[] = [];
    const body = this.monetarydetailsform.getRawValue();
    this.validnumber = true;
    if (this.monetarydetailsform.get('Limits').value != null
      && this.monetarydetailsform.get('Limits').value !== '') {
      if (!this.monetarydetailsform.get('Limits').value.toString().replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('PartOf').value != null
      && this.monetarydetailsform.get('PartOf').value !== '') {
      if (!this.monetarydetailsform.get('PartOf').value.toString().replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('Attachment').value != null
      && this.monetarydetailsform.get('Attachment').value !== '') {
      if (!this.monetarydetailsform.get('Attachment').value.toString().replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('TrxAmount').value != null
      && this.monetarydetailsform.get('TrxAmount').value !== '') {
      if (!this.monetarydetailsform.get('TrxAmount').value.toString().replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('Taxes').value != null
      && this.monetarydetailsform.get('Taxes').value !== '') {
      if (!this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('Fees').value != null
      && this.monetarydetailsform.get('Fees').value !== '') {
      if (!this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('Surcharges').value != null
      && this.monetarydetailsform.get('Surcharges').value !== '') {
      if (!this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (body.CalculatedTFS != null
      && body.CalculatedTFS !== '') {
      if (!body.CalculatedTFS.toString().replace(/,/g, '').match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('Commission').value != null
      && this.monetarydetailsform.get('Commission').value !== '') {
      if (this.monetarydetailsform.get('Commission').value.toString().replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        if (this.monetarydetailsform.get('Commission').value >= -9999 &&
          this.monetarydetailsform.get('Commission').value <= 9999) {
          multicheck.push(true);
        } else {
          this.textMessage = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
          multicheck.push(false);
        }
      } else {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      }
    }
    if (body.CommissionD != null
      && body.CommissionD !== '') {
      if (!body.CommissionD.toString().replace(/,/g, '').match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('WrittenLine').value != null
      && this.monetarydetailsform.get('WrittenLine').value !== '') {
      if (this.monetarydetailsform.get('WrittenLine').value.toString().replace(/,/g, '').toString().match(/^[0-9]+(\.?[0-9]+)?$/)) {
        if (this.monetarydetailsform.get('WrittenLine').value >= 0 &&
          this.monetarydetailsform.get('WrittenLine').value <= 100) {
          multicheck.push(true);
        } else {
          this.textMessage = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
          multicheck.push(false);
        }
      } else {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      }
    }
    multicheck.forEach((value) => {
      if (value === false) {
        this.validnumber = false;
        this.summary = 'The value you entered isn\'t valid for this field.';
      }
    });
  }



  updatetransaction() {
    this.transactionobject = new Transactions();
    if (this.primaryinfoform.get('WhoRequested').value !== ''
      && this.primaryinfoform.get('WhoRequested').value != null) {
      this.transactionobject.WhoRequested = this.primaryinfoform.get('WhoRequested').value;
    } else {
      this.transactionobject.WhoRequested = null;
    }
    if (this.primaryinfoform.get('ProfitCenter').value !== ''
      && this.primaryinfoform.get('ProfitCenter').value != null) {
      this.transactionobject.ProfitCenter = this.primaryinfoform.get('ProfitCenter').value;
    } else {
      this.transactionobject.ProfitCenter = null;
    }
    this.transactionobject.IsIISResponsible = this.IsIISResponsiblehash[this.primaryinfoform.get('IISResponsible').value];
    if (this.primaryinfoform.get('TxnType').value !== ''
      && this.primaryinfoform.get('TxnType').value != null) {
      this.transactionobject.TransactionTypeCode = this.trxntypehash[this.primaryinfoform.get('TxnType').value];
    } else {
      this.transactionobject.TransactionTypeCode = null;
    }
    if (this.primaryinfoform.get('TxnSource').value !== ''
      && this.primaryinfoform.get('TxnSource').value != null) {
      this.transactionobject.TransactionSourceCode = this.primaryinfoform.get('TxnSource').value;
    } else {
      this.transactionobject.TransactionSourceCode = null;
    }

    if (this.primaryinfoform.get('AccountName').value !== ''
      && this.primaryinfoform.get('AccountName').value != null) {
      this.transactionobject.AccountName = this.primaryinfoform.get('AccountName').value;
    } else {
      this.transactionobject.AccountName = null;
    }
    this.transactionobject.IsFacultative = this.IsFacultativehash[this.primaryinfoform.get('Fac').value];
    if (this.primaryinfoform.get('Reins').value !== ''
      && this.primaryinfoform.get('Reins').value != null) {
      this.transactionobject.ReinsuranceType = this.primaryinfoform.get('Reins').value;
    } else {
      this.transactionobject.ReinsuranceType = null;
    }
    if (this.primaryinfoform.get('AccountPeriod').value !== '') {
      this.transactionobject.AccountingPeriodCode = this.primaryinfoform.get('AccountPeriod').value;
    } else {
      this.transactionobject.AccountingPeriodCode = null;
    }
    if (this.primaryinfoform.get('Policy').value !== ''
      && this.primaryinfoform.get('Policy').value != null) {
      this.transactionobject.PolicyNumber = this.primaryinfoform.get('Policy').value;
    } else {
      this.transactionobject.PolicyNumber = null;
    }
    if (this.primaryinfoform.get('EffDate').value !== ''
      && this.primaryinfoform.get('EffDate').value != null) {
      this.transactionobject.EffectiveDate = this.datepipe.transform(this.primaryinfoform.get('EffDate').value, 'yyyy-MM-dd');
    } else {
      this.transactionobject.EffectiveDate = null;
    }
    this.transactionobject.IsQuoteShare = this.QuotaSharehash[this.primaryinfoform.get('QuotaShare').value];
    if (this.primaryinfoform.get('Source').value !== ''
      && this.primaryinfoform.get('Source').value != null) {
      this.transactionobject.Source = this.primaryinfoform.get('Source').value;
    } else {
      this.transactionobject.Source = null;
    }
    if (this.primaryinfoform.get('ComplexityCode').value !== ''
      && this.primaryinfoform.get('ComplexityCode').value != null) {
      this.transactionobject.ComplexityCodes = this.primaryinfoform.get('ComplexityCode').value;
    } else {
      this.transactionobject.ComplexityCodes = null;
    }
    this.transactionobject.IsXOL = this.IsXOLhash[this.primaryinfoform.get('XOL').value];
    if (this.primaryinfoform.get('IssuingPaper').value !== ''
      && this.primaryinfoform.get('IssuingPaper').value != null) {
      this.transactionobject.IssuingPaper = this.primaryinfoform.get('IssuingPaper').value;
    } else {
      this.transactionobject.IssuingPaper = null;
    }
    if (this.monetarydetailsform.get('Limits').value != null
      && this.monetarydetailsform.get('Limits').value !== '') {
      this.transactionobject.Limits = this.monetarydetailsform.get('Limits').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Limits = null;
    }
    if (this.monetarydetailsform.get('PartOf').value != null
      && this.monetarydetailsform.get('PartOf').value !== '') {
      this.transactionobject.PartOf = this.monetarydetailsform.get('PartOf').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.PartOf = null;
    }
    if (this.monetarydetailsform.get('Attachment').value != null
      && this.monetarydetailsform.get('Attachment').value !== '') {
      this.transactionobject.Attachment = this.monetarydetailsform.get('Attachment').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Attachment = null;
    }
    if (this.monetarydetailsform.get('TrxAmount').value != null
      && this.monetarydetailsform.get('TrxAmount').value !== '') {
      this.transactionobject.TrxAmount = this.monetarydetailsform.get('TrxAmount').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.TrxAmount = null;
    }
    if (this.monetarydetailsform.get('Taxes').value != null
      && this.monetarydetailsform.get('Taxes').value !== '') {
      this.transactionobject.Taxes = this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Taxes = null;
    }
    if (this.monetarydetailsform.get('Fees').value != null
      && this.monetarydetailsform.get('Fees').value !== '') {
      this.transactionobject.Fees = this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Fees = null;
    }
    if (this.monetarydetailsform.get('Surcharges').value != null
      && this.monetarydetailsform.get('Surcharges').value !== '') {
      this.transactionobject.Surcharges = this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Surcharges = null;
    }
    if (this.monetarydetailsform.get('CalculatedTFS').value != null
      && this.monetarydetailsform.get('CalculatedTFS').value !== '') {
      this.transactionobject.CalculatedTFS = this.monetarydetailsform.get('CalculatedTFS').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.CalculatedTFS = null;
    }
    this.transactionobject.Commission = this.monetarydetailsform.get('Commission').value;
    if (this.monetarydetailsform.get('CommissionD').value != null
      && this.monetarydetailsform.get('CommissionD').value !== '') {
      this.transactionobject.CommissionD = this.monetarydetailsform.get('CommissionD').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.CommissionD = null;
    }
    this.transactionobject.WrittenLine = this.monetarydetailsform.get('WrittenLine').value;
    if (this.monetarydetailsform.get('Currency').value !== ''
      && this.monetarydetailsform.get('Currency').value != null) {
      this.transactionobject.Currency = this.monetarydetailsform.get('Currency').value;
    } else {
      this.transactionobject.Currency = null;
    }
    if (this.participantform.get('ParticipantName1').value !== ''
      && this.participantform.get('ParticipantName1').value != null) {
      this.transactionobject.ParticipantName1 = this.participanthash[this.participantform.get('ParticipantName1').value];
    } else {
      this.transactionobject.ParticipantName1 = null;
    }
    if (this.participantform.get('ParticipantPercent1').value !== ''
      && this.participantform.get('ParticipantPercent1').value != null) {
      this.transactionobject.ParticipantPercent1 = this.participantform.get('ParticipantPercent1').value;
    } else {
      this.transactionobject.ParticipantPercent1 = null;
    }
    if (this.participantform.get('FeePercent1').value !== ''
      && this.participantform.get('FeePercent1').value != null) {
      this.transactionobject.FeePercent1 = this.participantform.get('FeePercent1').value;
    } else {
      this.transactionobject.FeePercent1 = null;
    }
    if (this.participantform.get('ParticipantName2').value !== ''
      && this.participantform.get('ParticipantName2').value != null) {
      this.transactionobject.ParticipantName2 = this.participanthash[this.participantform.get('ParticipantName2').value];
    } else {
      this.transactionobject.ParticipantName2 = null;
    }
    if (this.participantform.get('ParticipantPercent2').value !== ''
      && this.participantform.get('ParticipantPercent2').value != null) {
      this.transactionobject.ParticipantPercent2 = this.participantform.get('ParticipantPercent2').value;
    } else {
      this.transactionobject.ParticipantPercent2 = null;
    }
    if (this.participantform.get('FeePercent2').value !== ''
      && this.participantform.get('FeePercent2').value != null) {
      this.transactionobject.FeePercent2 = this.participantform.get('FeePercent2').value;
    } else {
      this.transactionobject.FeePercent2 = null;
    }
    if (this.participantform.get('ParticipantName3').value !== ''
      && this.participantform.get('ParticipantName3').value != null) {
      this.transactionobject.ParticipantName3 = this.participanthash[this.participantform.get('ParticipantName3').value];
    } else {
      this.transactionobject.ParticipantName3 = null;
    }
    if (this.participantform.get('ParticipantPercent3').value !== ''
      && this.participantform.get('ParticipantPercent3').value != null) {
      this.transactionobject.ParticipantPercent3 = this.participantform.get('ParticipantPercent3').value;
    } else {
      this.transactionobject.ParticipantPercent3 = null;
    }
    if (this.participantform.get('FeePercent3').value !== ''
      && this.participantform.get('FeePercent3').value != null) {
      this.transactionobject.FeePercent3 = this.participantform.get('FeePercent3').value;
    } else {
      this.transactionobject.FeePercent3 = null;
    }
    if (this.participantform.get('ParticipantName4').value !== ''
      && this.participantform.get('ParticipantName4').value != null) {
      this.transactionobject.ParticipantName4 = this.participanthash[this.participantform.get('ParticipantName4').value];
    } else {
      this.transactionobject.ParticipantName4 = null;
    }
    if (this.participantform.get('ParticipantPercent4').value !== ''
      && this.participantform.get('ParticipantPercent4').value != null) {
      this.transactionobject.ParticipantPercent4 = this.participantform.get('ParticipantPercent4').value;
    } else {
      this.transactionobject.ParticipantPercent4 = null;
    }
    if (this.participantform.get('FeePercent4').value !== ''
      && this.participantform.get('FeePercent4').value != null) {
      this.transactionobject.FeePercent4 = this.participantform.get('FeePercent4').value;
    } else {
      this.transactionobject.FeePercent4 = null;
    }
    if (this.participantform.get('ParticipantName5').value !== ''
      && this.participantform.get('ParticipantName5').value != null) {
      this.transactionobject.ParticipantName5 = this.participanthash[this.participantform.get('ParticipantName5').value];
    } else {
      this.transactionobject.ParticipantName5 = null;
    }
    if (this.participantform.get('ParticipantPercent5').value !== ''
      && this.participantform.get('ParticipantPercent5').value != null) {
      this.transactionobject.ParticipantPercent5 = this.participantform.get('ParticipantPercent5').value;
    } else {
      this.transactionobject.ParticipantPercent5 = null;
    }
    if (this.participantform.get('FeePercent5').value !== ''
      && this.participantform.get('FeePercent5').value != null) {
      this.transactionobject.FeePercent5 = this.participantform.get('FeePercent5').value;
    } else {
      this.transactionobject.FeePercent5 = null;
    }
    this.transactionobject.ReconciliationStatus =
      this.mfxtransmissionform.get('ReconciliationStatus').value;
    this.transactionobject.WhenReconciled =
      this.datepipe.transform(this.mfxtransmissionform.get('WhenReconciled').value, 'yyyy-MM-dd HH:mm:ss');
    this.transactionobject.WhenRcvdFromMFX =
      this.datepipe.transform(this.mfxtransmissionform.get('WhenRcvdFromMFX').value, 'yyyy-MM-dd HH:mm:ss');
    this.transactionobject.WhenSentToMFX =
      this.datepipe.transform(this.mfxtransmissionform.get('ReceivedtoBooking').value, 'yyyy-MM-dd HH:mm:ss');
    if (this.mfxtransmissionform.get('TrxnStatus').value !== ''
      && this.mfxtransmissionform.get('TrxnStatus').value != null) {
      const trxnstatuscodesplit = this.mfxtransmissionform.get('TrxnStatus').value.split('-');
      this.transactionobject.TransactionStatusCode = trxnstatuscodesplit[0];
    } else {
      this.transactionobject.TransactionStatusCode = null;
    }
    this.transactionobject.WhoCreated = this.updateinfoform.get('WhoCreated').value;
    this.transactionobject.WhenCreated =
      this.datepipe.transform(this.updateinfoform.get('WhenCreated').value, 'yyyy-MM-dd HH:mm:ss');
    this.transactionobject.WhoUpdated = this.navigationService.whoCreatedUpdated;
    this.transactionobject.WhenUpdated = this.latestdate;
    this.transactionobject.Notes = this.glupdateform.get('Notes').value;
  }
  copyrecord() {
    this.copysubscription = this.navigationService.iscopytransactionupdate.subscribe((data) => {
      if (data === true) {
        this.addnewtransactionobject();
      }
    });
  }
  addnewtransactionobject() {
    this.isformvalid = false;
    this.validnumber = false;
    this.validpercentage = false;
    this.validprimaryinfo = false;
    this.isparticipantnamevalid = false;
    this.checkprimaryinfovalidations();
    this.checknumericvalidations();
    this.sameparticipantnamevalidation();
    this.checkhighestpercentage();
    this.checkvalidations();
    if (this.isformvalid && this.validnumber && this.validpercentage
      && this.validprimaryinfo && this.isparticipantnamevalid) {
      this.loadingSubject.next(true);
      this.date = new Date();
      this.latestdate = this.datepipe.transform(this.date, 'yyyy-MM-dd HH:mm:ss');
      this.addnewtransaction();
      console.log(this.transactionobject);
      this.apiService.postnewtransaction(this.transactionobject).subscribe(response => {
        this.addtransactionresponse = new ExcelandTransactionResponse();
        this.addtransactionresponse = response;
        if (this.addtransactionresponse.statusCode === 200) {
          const output = this.addtransactionresponse.body;
          const newtransactionid = output[0];
          if (newtransactionid['@Output'] != null) {
            this.date = new Date();
            this.primaryinfoform.patchValue({
              TransactionID: newtransactionid['@Output'],
            });
            this.selectedrowid = newtransactionid['@Output'];
            this.updateinfoform.patchValue(
              {
                WhoCreated: this.navigationService.whoCreatedUpdated,
                WhenCreated: this.datepipe.transform(this.latestdate, 'MM-dd-yyyy HH:mm:ss'),
                WhoUpdated: this.navigationService.whoCreatedUpdated,
                WhenUpdated: this.datepipe.transform(this.latestdate, 'MM-dd-yyyy HH:mm:ss'),
              }
            );
            this.summary = 'Transaction added';
            this.textMessage = 'Generated Transaction ID :' + newtransactionid['@Output'];
            this.messageService.add({
              severity: 'success',
              summary: 'Transaction added',
              detail: '',
            });
          } else {
            this.summary = 'Transaction Not Inserted';
            this.textMessage = 'Something Went Wrong';
            this.messageService.add({
              severity: 'error',
              summary: 'Transaction Not Inserted',
              detail: '',
            });
          }
        } else {
          this.summary =  'Transaction Not Inserted';
          this.textMessage =  'Something Went Wrong';
          this.messageService.add({
            severity: 'error',
            summary: 'Transaction Not Inserted',
            detail: '',
          });
        }
        this.loadingSubject.next(false);
      });
    } else {
      this.summary = 'Form is Invalid';
      this.messageService.add({
        severity: 'error',
        summary: 'Form is Invalid',
        detail: '',
      });
    }
  }

  addnewtransaction() {
    this.transactionobject = new Transactions();
    if (this.primaryinfoform.get('WhoRequested').value !== ''
      && this.primaryinfoform.get('WhoRequested').value != null) {
      this.transactionobject.WhoRequested = this.primaryinfoform.get('WhoRequested').value;
    } else {
      this.transactionobject.WhoRequested = null;
    }
    if (this.primaryinfoform.get('ProfitCenter').value !== ''
      && this.primaryinfoform.get('ProfitCenter').value != null) {
      this.transactionobject.ProfitCenter = this.primaryinfoform.get('ProfitCenter').value;
    } else {
      this.transactionobject.ProfitCenter = null;
    }
    this.transactionobject.IsIISResponsible = this.IsIISResponsiblehash[this.primaryinfoform.get('IISResponsible').value];
    if (this.primaryinfoform.get('TxnType').value !== ''
      && this.primaryinfoform.get('TxnType').value != null) {
      this.transactionobject.TransactionTypeCode = this.trxntypehash[this.primaryinfoform.get('TxnType').value];
    } else {
      this.transactionobject.TransactionTypeCode = null;
    }
    if (this.primaryinfoform.get('TxnSource').value !== ''
      && this.primaryinfoform.get('TxnSource').value != null) {
      this.transactionobject.TransactionSourceCode = this.primaryinfoform.get('TxnSource').value;
    } else {
      this.transactionobject.TransactionSourceCode = null;
    }
    
    if (this.primaryinfoform.get('AccountName').value !== ''
      && this.primaryinfoform.get('AccountName').value != null) {
      this.transactionobject.AccountName = this.primaryinfoform.get('AccountName').value;
    } else {
      this.transactionobject.AccountName = null;
    }
    this.transactionobject.IsFacultative = this.IsFacultativehash[this.primaryinfoform.get('Fac').value];
    if (this.primaryinfoform.get('Reins').value !== ''
      && this.primaryinfoform.get('Reins').value != null) {
      this.transactionobject.ReinsuranceType = this.primaryinfoform.get('Reins').value;
    } else {
      this.transactionobject.ReinsuranceType = null;
    }
    if (this.primaryinfoform.get('AccountPeriod').value !== '') {
      this.transactionobject.AccountingPeriodCode = this.primaryinfoform.get('AccountPeriod').value;
    } else {
      this.transactionobject.AccountingPeriodCode = null;
    }
    if (this.primaryinfoform.get('Policy').value !== ''
      && this.primaryinfoform.get('Policy').value != null) {
      this.transactionobject.PolicyNumber = this.primaryinfoform.get('Policy').value;
    } else {
      this.transactionobject.PolicyNumber = null;
    }
    if (this.primaryinfoform.get('EffDate').value !== ''
      && this.primaryinfoform.get('EffDate').value != null) {
      this.transactionobject.EffectiveDate = this.datepipe.transform(this.primaryinfoform.get('EffDate').value, 'yyyy-MM-dd');
    } else {
      this.transactionobject.EffectiveDate = null;
    }
    this.transactionobject.IsQuoteShare = this.QuotaSharehash[this.primaryinfoform.get('QuotaShare').value];
    if (this.primaryinfoform.get('Source').value !== ''
      && this.primaryinfoform.get('Source').value != null) {
      this.transactionobject.Source = this.primaryinfoform.get('Source').value;
    } else {
      this.transactionobject.Source = null;
    }
    if (this.primaryinfoform.get('ComplexityCode').value !== ''
      && this.primaryinfoform.get('ComplexityCode').value != null) {
      this.transactionobject.ComplexityCodes = this.primaryinfoform.get('ComplexityCode').value;
    } else {
      this.transactionobject.ComplexityCodes = null;
    }
    this.transactionobject.IsXOL = this.IsXOLhash[this.primaryinfoform.get('XOL').value];
    if (this.primaryinfoform.get('IssuingPaper').value !== ''
      && this.primaryinfoform.get('IssuingPaper').value != null) {
      this.transactionobject.IssuingPaper = this.primaryinfoform.get('IssuingPaper').value;
    } else {
      this.transactionobject.IssuingPaper = null;
    }
    if (this.monetarydetailsform.get('Limits').value != null
      && this.monetarydetailsform.get('Limits').value !== '') {
      this.transactionobject.Limits = this.monetarydetailsform.get('Limits').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Limits = null;
    }
    if (this.monetarydetailsform.get('PartOf').value != null
      && this.monetarydetailsform.get('PartOf').value !== '') {
      this.transactionobject.PartOf = this.monetarydetailsform.get('PartOf').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.PartOf = null;
    }
    if (this.monetarydetailsform.get('Attachment').value != null
      && this.monetarydetailsform.get('Attachment').value !== '') {
      this.transactionobject.Attachment = this.monetarydetailsform.get('Attachment').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Attachment = null;
    }
    if (this.monetarydetailsform.get('TrxAmount').value != null
      && this.monetarydetailsform.get('TrxAmount').value !== '') {
      this.transactionobject.TrxAmount = this.monetarydetailsform.get('TrxAmount').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.TrxAmount = null;
    }
    if (this.monetarydetailsform.get('Taxes').value != null
      && this.monetarydetailsform.get('Taxes').value !== '') {
      this.transactionobject.Taxes = this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Taxes = null;
    }
    if (this.monetarydetailsform.get('Fees').value != null
      && this.monetarydetailsform.get('Fees').value !== '') {
      this.transactionobject.Fees = this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Fees = null;
    }
    if (this.monetarydetailsform.get('Surcharges').value != null
      && this.monetarydetailsform.get('Surcharges').value !== '') {
      this.transactionobject.Surcharges = this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Surcharges = null;
    }
    if (this.monetarydetailsform.get('CalculatedTFS').value != null
      && this.monetarydetailsform.get('CalculatedTFS').value !== '') {
      this.transactionobject.CalculatedTFS = this.monetarydetailsform.get('CalculatedTFS').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.CalculatedTFS = null;
    }
    this.transactionobject.Commission = this.monetarydetailsform.get('Commission').value;
    if (this.monetarydetailsform.get('CommissionD').value != null
      && this.monetarydetailsform.get('CommissionD').value !== '') {
      this.transactionobject.CommissionD = this.monetarydetailsform.get('CommissionD').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.CommissionD = null;
    }
    this.transactionobject.WrittenLine = this.monetarydetailsform.get('WrittenLine').value;
    if (this.monetarydetailsform.get('Currency').value !== ''
      && this.monetarydetailsform.get('Currency').value != null) {
      this.transactionobject.Currency = this.monetarydetailsform.get('Currency').value;
    } else {
      this.transactionobject.Currency = null;
    }
    if (this.participantform.get('ParticipantName1').value !== ''
      && this.participantform.get('ParticipantName1').value != null) {
      this.transactionobject.ParticipantName1 = this.participanthash[this.participantform.get('ParticipantName1').value];
    } else {
      this.transactionobject.ParticipantName1 = null;
    }
    if (this.participantform.get('ParticipantPercent1').value !== ''
      && this.participantform.get('ParticipantPercent1').value != null) {
      this.transactionobject.ParticipantPercent1 = this.participantform.get('ParticipantPercent1').value;
    } else {
      this.transactionobject.ParticipantPercent1 = null;
    }
    if (this.participantform.get('FeePercent1').value !== ''
      && this.participantform.get('FeePercent1').value != null) {
      this.transactionobject.FeePercent1 = this.participantform.get('FeePercent1').value;
    } else {
      this.transactionobject.FeePercent1 = null;
    }
    if (this.participantform.get('ParticipantName2').value !== ''
      && this.participantform.get('ParticipantName2').value != null) {
      this.transactionobject.ParticipantName2 = this.participanthash[this.participantform.get('ParticipantName2').value];
    } else {
      this.transactionobject.ParticipantName2 = null;
    }
    if (this.participantform.get('ParticipantPercent2').value !== ''
      && this.participantform.get('ParticipantPercent2').value != null) {
      this.transactionobject.ParticipantPercent2 = this.participantform.get('ParticipantPercent2').value;
    } else {
      this.transactionobject.ParticipantPercent2 = null;
    }
    if (this.participantform.get('FeePercent2').value !== ''
      && this.participantform.get('FeePercent2').value != null) {
      this.transactionobject.FeePercent2 = this.participantform.get('FeePercent2').value;
    } else {
      this.transactionobject.FeePercent2 = null;
    }
    if (this.participantform.get('ParticipantName3').value !== ''
      && this.participantform.get('ParticipantName3').value != null) {
      this.transactionobject.ParticipantName3 = this.participanthash[this.participantform.get('ParticipantName3').value];
    } else {
      this.transactionobject.ParticipantName3 = null;
    }
    if (this.participantform.get('ParticipantPercent3').value !== ''
      && this.participantform.get('ParticipantPercent3').value != null) {
      this.transactionobject.ParticipantPercent3 = this.participantform.get('ParticipantPercent3').value;
    } else {
      this.transactionobject.ParticipantPercent3 = null;
    }
    if (this.participantform.get('FeePercent3').value !== ''
      && this.participantform.get('FeePercent3').value != null) {
      this.transactionobject.FeePercent3 = this.participantform.get('FeePercent3').value;
    } else {
      this.transactionobject.FeePercent3 = null;
    }
    if (this.participantform.get('ParticipantName4').value !== ''
      && this.participantform.get('ParticipantName4').value != null) {
      this.transactionobject.ParticipantName4 = this.participanthash[this.participantform.get('ParticipantName4').value];
    } else {
      this.transactionobject.ParticipantName4 = null;
    }
    if (this.participantform.get('ParticipantPercent4').value !== ''
      && this.participantform.get('ParticipantPercent4').value != null) {
      this.transactionobject.ParticipantPercent4 = this.participantform.get('ParticipantPercent4').value;
    } else {
      this.transactionobject.ParticipantPercent4 = null;
    }
    if (this.participantform.get('FeePercent4').value !== ''
      && this.participantform.get('FeePercent4').value != null) {
      this.transactionobject.FeePercent4 = this.participantform.get('FeePercent4').value;
    } else {
      this.transactionobject.FeePercent4 = null;
    }
    if (this.participantform.get('ParticipantName5').value !== ''
      && this.participantform.get('ParticipantName5').value != null) {
      this.transactionobject.ParticipantName5 = this.participanthash[this.participantform.get('ParticipantName5').value];
    } else {
      this.transactionobject.ParticipantName5 = null;
    }
    if (this.participantform.get('ParticipantPercent5').value !== ''
      && this.participantform.get('ParticipantPercent5').value != null) {
      this.transactionobject.ParticipantPercent5 = this.participantform.get('ParticipantPercent5').value;
    } else {
      this.transactionobject.ParticipantPercent5 = null;
    }
    if (this.participantform.get('FeePercent5').value !== ''
      && this.participantform.get('FeePercent5').value != null) {
      this.transactionobject.FeePercent5 = this.participantform.get('FeePercent5').value;
    } else {
      this.transactionobject.FeePercent5 = null;
    }
    this.transactionobject.ReconciliationStatus = this.mfxtransmissionform.get('ReconciliationStatus').value;
    this.transactionobject.WhenReconciled =
      this.datepipe.transform(this.mfxtransmissionform.get('WhenReconciled').value, 'yyyy-MM-dd HH:mm:ss');
    this.transactionobject.WhenRcvdFromMFX =
      this.datepipe.transform(this.mfxtransmissionform.get('WhenRcvdFromMFX').value, 'yyyy-MM-dd HH:mm:ss');
    this.transactionobject.WhenSentToMFX =
      this.datepipe.transform(this.mfxtransmissionform.get('ReceivedtoBooking').value, 'yyyy-MM-dd HH:mm:ss');
    if (this.mfxtransmissionform.get('TrxnStatus').value !== ''
      && this.mfxtransmissionform.get('TrxnStatus').value != null) {
      const trxnstatuscodesplit = this.mfxtransmissionform.get('TrxnStatus').value.split('-');
      this.transactionobject.TransactionStatusCode = this.trxnstatushash2[trxnstatuscodesplit[1]];
    } else {
      this.transactionobject.TransactionStatusCode = null;
    }
    this.transactionobject.WhoCreated = this.navigationService.whoCreatedUpdated;
    this.transactionobject.WhenCreated = this.latestdate;
    this.transactionobject.WhoUpdated = this.navigationService.whoCreatedUpdated;
    this.transactionobject.WhenUpdated = this.latestdate;
    this.transactionobject.Notes = this.glupdateform.get('Notes').value;
  }

  checkhighestpercentage() {
    let finalpercentage = 0;
    if (this.participantform.get('ParticipantPercent1').value != null
      && this.participantform.get('ParticipantPercent1').value !== '') {
      finalpercentage = Number.parseFloat(this.participantform.get('ParticipantPercent1').value) +
        Number.parseFloat(finalpercentage.toString());
    }
    if (this.participantform.get('ParticipantPercent2').value != null
      && this.participantform.get('ParticipantPercent2').value !== '') {
      finalpercentage = Number.parseFloat(this.participantform.get('ParticipantPercent2').value) +
        Number.parseFloat(finalpercentage.toString());
    }
    if (this.participantform.get('ParticipantPercent3').value != null
      && this.participantform.get('ParticipantPercent3').value !== '') {
      finalpercentage = Number.parseFloat(this.participantform.get('ParticipantPercent3').value) +
        Number.parseFloat(finalpercentage.toString());
    }
    if (this.participantform.get('ParticipantPercent4').value != null
      && this.participantform.get('ParticipantPercent4').value !== '') {
      finalpercentage = Number.parseFloat(this.participantform.get('ParticipantPercent4').value) +
        Number.parseFloat(finalpercentage.toString());
    }
    if (this.participantform.get('ParticipantPercent5').value != null
      && this.participantform.get('ParticipantPercent5').value !== '') {
      finalpercentage = Number.parseFloat(this.participantform.get('ParticipantPercent5').value) +
        Number.parseFloat(finalpercentage.toString());
    }
    if (finalpercentage != null) {
      if (finalpercentage > 100 || finalpercentage < 100) {
        this.validpercentage = false;
        this.summary = 'Form is Invalid';
        this.textMessage = 'The Total Sum of the Participants percent must be equal to 100%';
      } else {
        this.validpercentage = true;
      }
    }
  }

  checkprimaryinfovalidations() {
    this.validprimaryinfo = true;
    const multicheck: IHash = {};
    if (this.primaryinfoform.invalid) {
      this.validprimaryinfo = false;
      this.summary = 'Form is invalid';
      this.textMessage = 'Primary info is invalid!';
    }
    if (this.mfxtransmissionform.invalid) {
      this.validprimaryinfo = false;
      this.summary = 'Form is invalid';
      this.textMessage = 'Transmission info is invalid!';
    }
    if (this.primaryinfoform.get('TxnType').value !== ''
      && this.primaryinfoform.get('TxnType').value != null) {
      if (this.TransactionTypeList.includes(this.primaryinfoform.get('TxnType').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('TxnSource').value !== ''
    && this.primaryinfoform.get('TxnSource').value != null) {
    if (this.TransactionSourceList.includes(this.primaryinfoform.get('TxnSource').value)) {
      multicheck.true = 'true';
    } else {
      multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
    }
  }
    if (this.primaryinfoform.get('AccountPeriod').value !== ''
      && this.primaryinfoform.get('AccountPeriod').value != null) {
      if (this.AccountPeriodList.includes(this.primaryinfoform.get('AccountPeriod').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('Source').value !== ''
      && this.primaryinfoform.get('Source').value != null) {
      if (this.SourceList.includes(this.primaryinfoform.get('Source').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.monetarydetailsform.get('Currency').value !== ''
      && this.monetarydetailsform.get('Currency').value != null) {
      if (this.CurrencyList.includes(this.monetarydetailsform.get('Currency').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.mfxtransmissionform.get('TrxnStatus').value !== ''
      && this.mfxtransmissionform.get('TrxnStatus').value != null) {
      if (this.TransactionStatusList.includes(this.mfxtransmissionform.get('TrxnStatus').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.participantform.get('ParticipantName1').value !== ''
      && this.participantform.get('ParticipantName1').value != null) {
      if (this.PartcipantNameList.includes(this.participantform.get('ParticipantName1').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.participantform.get('ParticipantName2').value !== ''
      && this.participantform.get('ParticipantName2').value != null) {
      if (this.PartcipantNameList.includes(this.participantform.get('ParticipantName2').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.participantform.get('ParticipantName3').value !== ''
      && this.participantform.get('ParticipantName3').value != null) {
      if (this.PartcipantNameList.includes(this.participantform.get('ParticipantName3').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.participantform.get('ParticipantName4').value !== ''
      && this.participantform.get('ParticipantName4').value != null) {
      if (this.PartcipantNameList.includes(this.participantform.get('ParticipantName4').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.participantform.get('ParticipantName5').value !== ''
      && this.participantform.get('ParticipantName5').value != null) {
      if (this.PartcipantNameList.includes(this.participantform.get('ParticipantName5').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('ComplexityCode').value !== ''
      && this.primaryinfoform.get('ComplexityCode').value != null) {
      if (this.complexitycodes$.includes(this.primaryinfoform.get('ComplexityCode').value.toString())) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('ProfitCenter').value !== ''
      && this.primaryinfoform.get('ProfitCenter').value != null) {
      if (this.ProfitCenterList.includes(this.primaryinfoform.get('ProfitCenter').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('Fac').value !== ''
      && this.primaryinfoform.get('Fac').value != null) {
      if (this.IsFacultative$.includes(this.primaryinfoform.get('Fac').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('XOL').value !== ''
      && this.primaryinfoform.get('XOL').value != null) {
      if (this.IsXOL$.includes(this.primaryinfoform.get('XOL').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('IISResponsible').value !== ''
      && this.primaryinfoform.get('IISResponsible').value != null) {
      if (this.IsIISResponsible$.includes(this.primaryinfoform.get('IISResponsible').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('Reins').value !== ''
      && this.primaryinfoform.get('Reins').value != null) {
      if (this.ReinsuranceType$.includes(this.primaryinfoform.get('Reins').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('QuotaShare').value !== ''
      && this.primaryinfoform.get('QuotaShare').value != null) {
      if (this.QuotaShare$.includes(this.primaryinfoform.get('QuotaShare').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('IssuingPaper').value !== ''
      && this.primaryinfoform.get('IssuingPaper').value != null) {
      if (this.IssuingPaperList.includes(this.primaryinfoform.get('IssuingPaper').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    for (const key in multicheck) {
      if (key === 'false') {
        this.validprimaryinfo = false;
        this.summary = 'The text you entered isn\'t an item in the list.';
        this.textMessage = multicheck[key];
      }
    }
  }

  sameparticipantnamevalidation() {
    const multicheckhash: IHash = {};
    this.isparticipantnamevalid = true;
    if (this.participantform.get('ParticipantName5').value === '') {
      this.participantform.get('ParticipantPercent5').reset(null);
      this.participantform.get('FeePercent5').reset(null);
    } else {
      if (this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName4').value) {
        multicheckhash.false = 'This Participant Name Already selected';
      } else {
        multicheckhash.true = 'true';
      }
    }
    if (this.participantform.get('ParticipantName4').value === '') {
      this.participantform.get('ParticipantPercent4').reset(null);
      this.participantform.get('FeePercent4').reset(null);
    } else {
      if (this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName5').value) {
        multicheckhash.false = 'This Participant Name Already selected';
      } else {
        multicheckhash.true = 'true';
      }
    }
    if (this.participantform.get('ParticipantName3').value === '') {
      this.participantform.get('ParticipantPercent3').reset(null);
      this.participantform.get('FeePercent3').reset(null);
    } else {
      if (this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName4').value
        || this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName5').value) {
        multicheckhash.false = 'This Participant Name Already selected';
      } else {
        multicheckhash.true = 'true';
      }
    }
    if (this.participantform.get('ParticipantName2').value === '') {
      this.participantform.get('ParticipantPercent2').reset(null);
      this.participantform.get('FeePercent2').reset(null);
    } else {
      if (this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName4').value
        || this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName5').value) {
        multicheckhash.false = 'This Participant Name Already selected';
      } else {
        multicheckhash.true = 'true';
      }
    }
    if (this.participantform.get('ParticipantName1').value === '') {
      this.participantform.get('ParticipantPercent1').reset(null);
      this.participantform.get('FeePercent1').reset(null);
    } else {
      if (this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName4').value
        || this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName5').value) {
        multicheckhash.false = 'This Participant Name Already selected';
      } else {
        multicheckhash.true = 'true';
      }
    }
    for (const key in multicheckhash) {
      if (key === 'false') {
        this.isparticipantnamevalid = false;
        this.summary = 'Form is Invalid';
        this.textMessage = multicheckhash[key];
      }
    }
  }

  checkvalidations() {
    const multicheckhash: IHash = {};
    this.isformvalid = true;
    if (this.participantform.get('ParticipantName1').value !== ''
      || this.participantform.get('ParticipantName2').value !== ''
      || this.participantform.get('ParticipantName3').value !== ''
      || this.participantform.get('ParticipantName4').value !== ''
      || this.participantform.get('ParticipantName5').value !== ''
      || this.participantform.get('ParticipantPercent1').value != null
      || this.participantform.get('ParticipantPercent2').value != null
      || this.participantform.get('ParticipantPercent3').value != null
      || this.participantform.get('ParticipantPercent4').value != null
      || this.participantform.get('ParticipantPercent5').value != null
      || this.participantform.get('FeePercent1').value != null
      || this.participantform.get('FeePercent2').value != null
      || this.participantform.get('FeePercent3').value != null
      || this.participantform.get('FeePercent4').value != null
      || this.participantform.get('FeePercent5').value != null
    ) {
      if ((this.participantform.get('ParticipantName5').value !== ''
        && this.participantform.get('ParticipantName5').value != null)
        || (this.participantform.get('FeePercent5').value != null
          && this.participantform.get('FeePercent5').value !== '')
        || (this.participantform.get('ParticipantPercent5').value != null
          && this.participantform.get('ParticipantPercent5').value !== '')) {
        if (this.participantform.get('ParticipantName5').value !== ''
          && this.participantform.get('ParticipantName5').value !== null) {
          if (this.participantform.get('ParticipantPercent5').value != null
            && this.participantform.get('ParticipantPercent5').value !== '') {
            if (this.participantform.get('ParticipantPercent5').value >= 0 &&
              this.participantform.get('ParticipantPercent5').value <= 100) {
              if (this.participantform.get('FeePercent5').value != null
                && this.participantform.get('FeePercent5').value !== '') {
                if (this.participantform.get('FeePercent5').value >= 0 &&
                  this.participantform.get('FeePercent5').value <= 100) {
                  multicheckhash.true = 'true';
                } else {
                  multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
                }
              } else {
                multicheckhash.false = 'Fee Percentage 5 is required';
              }
            } else {
              multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
            }
          } else {
            multicheckhash.false = 'Participant Percent 5 is required';
          }
        } else {
          multicheckhash.false = 'Participant Name 5 is missing';
        }
      }

      if ((this.participantform.get('ParticipantName4').value !== ''
        && this.participantform.get('ParticipantName4').value != null)
        || (this.participantform.get('FeePercent4').value != null
          && this.participantform.get('FeePercent4').value !== '')
        || (this.participantform.get('ParticipantPercent4').value != null
          && this.participantform.get('ParticipantPercent4').value !== '')) {
        if (this.participantform.get('ParticipantName4').value !== ''
          && this.participantform.get('ParticipantName4').value !== null) {
          if (this.participantform.get('ParticipantPercent4').value != null
            && this.participantform.get('ParticipantPercent4').value !== '') {
            if (this.participantform.get('ParticipantPercent4').value >= 0 &&
              this.participantform.get('ParticipantPercent4').value <= 100) {
              if (this.participantform.get('FeePercent4').value != null
                && this.participantform.get('FeePercent4').value !== '') {
                if (this.participantform.get('FeePercent4').value >= 0 &&
                  this.participantform.get('FeePercent4').value <= 100) {
                  multicheckhash.true = 'true';
                } else {
                  multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
                }
              } else {
                multicheckhash.false = 'Fee Percentage 4 is required';
              }
            } else {
              multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
            }
          } else {
            multicheckhash.false = 'Participant Percent 4 is required';
          }
        } else {
          multicheckhash.false = 'Participant Name 4 is missing';
        }
      }
      if ((this.participantform.get('ParticipantName3').value !== ''
        && this.participantform.get('ParticipantName3').value != null)
        || (this.participantform.get('FeePercent3').value != null
          && this.participantform.get('FeePercent3').value !== '')
        || (this.participantform.get('ParticipantPercent3').value != null
          && this.participantform.get('ParticipantPercent3').value !== '')) {
        if (this.participantform.get('ParticipantName3').value !== ''
          && this.participantform.get('ParticipantName3').value !== null) {
          if (this.participantform.get('ParticipantPercent3').value != null
            && this.participantform.get('ParticipantPercent3').value !== '') {
            if (this.participantform.get('ParticipantPercent3').value >= 0 &&
              this.participantform.get('ParticipantPercent3').value <= 100) {
              if (this.participantform.get('FeePercent3').value != null
                && this.participantform.get('FeePercent3').value !== '') {
                if (this.participantform.get('FeePercent3').value >= 0 &&
                  this.participantform.get('FeePercent3').value <= 100) {
                  multicheckhash.true = 'true';
                } else {
                  multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
                }
              } else {
                multicheckhash.false = 'Fee Percentage 3 is required';
              }
            } else {
              multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
            }
          } else {
            multicheckhash.false = 'Participant Percent 3 is required';
          }
        } else {
          multicheckhash.false = 'Participant Name 3 is missing';
        }
      }

      if ((this.participantform.get('ParticipantName2').value !== ''
        && this.participantform.get('ParticipantName2').value != null)
        || (this.participantform.get('FeePercent2').value != null
          && this.participantform.get('FeePercent2').value !== '')
        || (this.participantform.get('ParticipantPercent2').value != null
          && this.participantform.get('ParticipantPercent2').value !== '')) {
        if (this.participantform.get('ParticipantName2').value !== ''
          && this.participantform.get('ParticipantName2').value !== null) {
          if (this.participantform.get('ParticipantPercent2').value != null
            && this.participantform.get('ParticipantPercent2').value !== '') {
            if (this.participantform.get('ParticipantPercent2').value >= 0 &&
              this.participantform.get('ParticipantPercent2').value <= 100) {
              if (this.participantform.get('FeePercent2').value != null
                && this.participantform.get('FeePercent2').value !== '') {
                if (this.participantform.get('FeePercent2').value >= 0 &&
                  this.participantform.get('FeePercent2').value <= 100) {
                  multicheckhash.true = 'true';
                } else {
                  multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
                }
              } else {
                multicheckhash.false = 'Fee Percentage 2 is required';
              }
            } else {
              multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
            }
          } else {
            multicheckhash.false = 'Participant Percent 2 is required';
          }
        } else {
          multicheckhash.false = 'Participant Name 2 is missing';
        }
      }
      if ((this.participantform.get('ParticipantName1').value !== ''
        && this.participantform.get('ParticipantName1').value != null)
        || (this.participantform.get('FeePercent1').value != null
          && this.participantform.get('FeePercent1').value !== '')
        || (this.participantform.get('ParticipantPercent1').value != null
          && this.participantform.get('ParticipantPercent1').value !== '')) {
        if (this.participantform.get('ParticipantName1').value !== ''
          && this.participantform.get('ParticipantName1').value != null) {
          if (this.participantform.get('ParticipantPercent1').value != null
            && this.participantform.get('ParticipantPercent1').value !== '') {
            if (this.participantform.get('ParticipantPercent1').value >= 0 &&
              this.participantform.get('ParticipantPercent1').value <= 100) {
              if (this.participantform.get('FeePercent1').value != null
                && this.participantform.get('FeePercent1').value !== '') {
                if (this.participantform.get('FeePercent1').value >= 0 &&
                  this.participantform.get('FeePercent1').value <= 100) {
                  multicheckhash.true = 'true';
                } else {
                  multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
                }
              } else {
                multicheckhash.false = 'Fee Percentage 1 is required';
              }
            } else {
              multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
            }
          } else {
            multicheckhash.false = 'Participant Percent 1 is required';
          }
        } else {
          multicheckhash.false = 'Participant Name 1 is missing';
        }
      }

      for (const key in multicheckhash) {
        if (key === 'false') {
          this.isformvalid = false;
          this.summary = 'Form is Invalid';
          this.textMessage = multicheckhash[key];
        }
      }
    } else {
      this.isformvalid = false;
      this.summary = 'Form is Invalid';
      this.textMessage = 'At least one Participant Name should be selected';
    }
  }
}

export interface IHash {
  [key: string]: string;
}
export interface IHashnumber {
  [key: string]: number;
}
