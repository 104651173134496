export enum actions{
    Add = "Add",
    Update = "Update"
}

export function stringIsNullOrEmpty(value) {
    if (value === undefined || value === null || value === '') {
      return true;
    }
    return false;
  }
