import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/shared/shared.module';
import { ExampleProductRoutingModule } from './example-product-routing.module';

// components
import { ExampleProductComponent } from './example-product.component';
import { ExampleProductHomeComponent } from './components/home/example-product-home.component';
import { ExampleProductNewApplicationComponent } from './components/new-application/example-product-new-application.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ExampleProductRoutingModule
  ],
  declarations: [
    ExampleProductComponent,
    ExampleProductHomeComponent,
    ExampleProductNewApplicationComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    ExampleProductComponent,
    ExampleProductHomeComponent,
    ExampleProductNewApplicationComponent
  ]
})
export class ExampleProductModule { }
