import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable , of} from 'rxjs';
import { delay, map, switchMap, take } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { userInfo } from 'os';
import { ApiService } from './api.service';
import { AuthorizeapiService } from './authorizeapi.service';
import { NavigationService } from './navigation.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard implements CanActivate {

  NNumber: string;
  constructor(private oidcSecurityService: OidcSecurityService, private router: Router
    ,         private apiservice: AuthorizeapiService
    ,         private navservice: NavigationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // return this.oidcSecurityService.isAuthenticated$.pipe(
    //  map((isAuthorized: boolean) => {
    //     console.log('AuthorizationGuard, canActivate isAuthorized: ' + isAuthorized);

    //     if (!isAuthorized) {
    //       this.router.navigate(['/forbidden']);
    //       return false;
    //     }

    //     // User is authenticated.. save in DB
    //     // Check same Session ID exists
    //    return true;
    //   })
    // );
    if (!this.navservice.checked) {
      this.navservice.authguardloader.next(true);
      return this.oidcSecurityService.isAuthenticated$.pipe(
       take(1),
       switchMap(isAuthorized => {
          return this.apiservice.AuthorizeUser(this.navservice.session.NNumber);
       }),
       map((res) => {
          if (res.body.length > 0) {
            console.log('checked');
            this.navservice.authguardloader.next(false);
            this.navservice.isforbidden.next(true);
            this.navservice.checked = true;
            return true;
            } else {
              this.navservice.authguardloader.next(false);
              this.navservice.isforbidden.next(false);
              this.router.navigate(['/forbidden']);
              return false;
          }
      })
      );
    } else {
      return of(true);
    }
  }

  getAuthorizationHeaderValue(): string {
    if (this.oidcSecurityService != null) {
      return 'Bearer ' + this.oidcSecurityService.getToken();
    } else {
      return '';
    }
  }

  getUserInfo(): Observable<CustomUserInfo> {
    const accesstoken = this.oidcSecurityService.getToken();
    console.log(accesstoken);
    if (accesstoken != null) {
      const decoded = jwt_decode(accesstoken);
      try {
        const obj: CustomUserInfo = JSON.parse(decoded.toString());
        const user = new Observable<CustomUserInfo>(observer => {
              observer.next(obj);
          });
        return user;
      } catch (error) {
        console.error('This is an error message', error);
      }
    }

    return null;
  }
}

export interface CustomUserInfo {
  family_name: string;
  given_name: string;
  name: string;
  unique_name: string;
  employeeid: string;
}
