export class GridColumn {
    PolicyNumber: any = { value: '', operator: '', sort: []};
    AccountName: any = { value: '', operator: '', sort: []};
    TransactionID: any = { value: '', operator: '', sort: []};
    TransactionTypeCode: any = { value: '', operator: '', sort: []};
    TransactionStatusCode: any = { value: '', operator: '', sort: []};
    AccountingPeriodCode: any = { value: '', operator: '', sort: []};
    ProfitCenter: any = { value: '', operator: '', sort: []};
    EffectiveDate: any = { value: '', operator: '', sort: []};
    Limits: any = { value: '', operator: '', sort: []};
    Attachment: any = { value: '', operator: '', sort: []};
    PartOf: any = { value: '', operator: '', sort: []};
    ParticipationPct: any = { value: '', operator: '', sort: []};
    TrxAmount: any = { value: '', operator: '', sort: []};
    TFS: any = { value: '', operator: '', sort: []};
    Commission: any = { value: '', operator: '', sort: []};
    IsFacultative: any = { value: '', operator: '', sort: []};
    IsQuoteShare: any = { value: '', operator: '', sort: []};
    IsXOL: any = { value: '', operator: '', sort: []};
    IsIISResponsible: any = { value: '', operator: '', sort: []};
    ReinsuranceType: any = { value: '', operator: '', sort: []};
    WhenReconciled: any = { value: '', operator: '', sort: []};
    ReconciliationStatus: any = { value: '', operator: '', sort: []};
    WhoRequested: any = { value: '', operator: '', sort: []};
    WhenSentToMFX: any = { value: '', operator: '', sort: []};
    WhenRcvdFromMFX: any = { value: '', operator: '', sort: []};
    SSSourceID: any = { value: '', operator: '', sort: []};
    WhenUpdated: any = { value: '', operator: '', sort: []};
    WhoUpdated: any = { value: '', operator: '', sort: []};
    WhenCreated: any = { value: '', operator: '', sort: []};
    WhoCreated: any = { value: '', operator: '', sort: []};
    Notes: any = { value: '', operator: '', sort: []};
    ComplexityCodes: any = { value: '', operator: '', sort: []};
    Taxes: any = { value: '', operator: '', sort: []};
    Fees: any = { value: '', operator: '', sort: []};
    Surcharges: any = { value: '', operator: '', sort: []};
    CalculatedTFS: any = { value: '', operator: '', sort: []};
    CommissionD: any = { value: '', operator: '', sort: []};
    ParticipantName1: any = { value: '', operator: '', sort: []};
    ParticipantPercent1: any = { value: '', operator: '', sort: []};
    FeePercent1: any = { value: '', operator: '', sort: []};
    ParticipantName2: any = { value: '', operator: '', sort: []};
    ParticipantPercent2: any = { value: '', operator: '', sort: []};
    FeePercent2: any = { value: '', operator: '', sort: []};
    ParticipantName3: any = { value: '', operator: '', sort: []};
    ParticipantPercent3: any = { value: '', operator: '', sort: []};
    FeePercent3: any = { value: '', operator: '', sort: []};
    ParticipantName4: any = { value: '', operator: '', sort: []};
    ParticipantPercent4: any = { value: '', operator: '', sort: []};
    FeePercent4: any = { value: '', operator: '', sort: []};
    ParticipantName5: any = { value: '', operator: '', sort: []};
    ParticipantPercent5: any = { value: '', operator: '', sort: []};
    FeePercent5: any = { value: '', operator: '', sort: []};
    Source: any = { value: '', operator: '', sort: []};
    Currency: any = { value: '', operator: '', sort: []};
    WrittenLine: any = { value: '', operator: '', sort: []};
    IssuingPaper: any = { value: '', operator: '', sort: []};
    TransactionTypeDesc: any = { value: '', operator: '', sort: []};
}

export class FilterState {
    TransactionID: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
     group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    WhoRequested: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
     group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    TransactionTypeCode: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
    group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    AccountingPeriodCode: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
    group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    AccountName: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
     group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    PolicyNumber: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
     group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    IssuingPaper: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
    group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    EffectiveDate: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
     group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    ProfitCenter: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
    group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    TrxAmount: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
    group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    Taxes: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
     group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    Fees: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
     group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    Surcharges: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
     group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    CalculatedTFS: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
    group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    Commission: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
     group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    TransactionTypeDesc: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
     group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    WhenSentToMFX: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
     group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    WhenRcvdFromMFX: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
     group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    WhenReconciled: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
    group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    WhoUpdated: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
    group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    WhenUpdated: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
    group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
    Notes: any = { filter: {filters: [{ value: '', operator: '', sort: []}], logic: ''},
     group: [], skip: 0, sort: [{field: '', dir: ''}], take: 0};
}


