export class Transactionsgrid {
  TransactionID: number;
  WhoRequested: string;
  TransactionType: string;
  AccountingPeriod: string;
  AccountName: string;
  PolicyNumber: string;
  IssuingPaper: string;
  EffectiveDate: string;
  ProfitCenter: string;
  TrxAmount: string;
  Taxes: string;
  Fees: string;
  Surcharges: string;
  CalculatedTFS: string;
  Commission: string;
  TransactionStatus: string;
  ReceivedtoBooking: string;
  CompletedDate: string;
  DateRec: string;
  WhoUpdated: string;
  WhenUpdated: string;
  Notes: string;

}
