import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ApiService} from 'src/app/services/api.service';
import {NavigationService} from 'src/app/services/navigation.service';
import {Router} from '@angular/router';
import {IssuingPaper} from 'src/app/components/Models/issuingpaper.model';
import {Source} from 'src/app/components/Models/source.model';
import {TransactionType} from 'src/app/components/Models/transactiontype.model';
import {ProfitCenter} from 'src/app/components/Models/profitcenter.model';
import {TransactionStatus} from 'src/app/components/Models/transactionstatus.model';
import {ParticipantName} from 'src/app/components/Models/participantname.model';
import {AccountPeriodModel} from 'src/app/components/Models/accountperiod.model';
import {CurrencyValues} from 'src/app/components/Models/currency.model';
import {Transactions} from 'src/app/components/Models/transactions.model';
import {ExcelandTransactionResponse} from 'src/app/components/Models/excelresponse.model';
import {map, startWith} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {DatePipe, DecimalPipe} from '@angular/common';
import { TransactionSource } from 'src/app/components/Models/transactionsource.model';

@Component({
  selector: 'catalyst-addtransactio',
  templateUrl: './addtransactio.component.html',
  styleUrls: ['./addtransactio.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [MessageService, DatePipe, DecimalPipe]
})

export class AddtransactioComponent implements OnInit, OnDestroy {


  public primaryinfoform: FormGroup;
  public monetarydetailsform: FormGroup;
  public participantform: FormGroup;
  public mfxtransmissionform: FormGroup;
  public updateinfoform: FormGroup;
  public glupdateform: FormGroup;

  public complexitycodes$ = [];
  public IsFacultative$ = [];
  public QuotaShare$ = [];
  public IsXOL$ = [];
  public IsIISResponsible$ = [];
  public ReinsuranceType$ = [];

  localIsFacultative: string;
  localQuotaShare: string;
  localIsXOL: string;
  localReinsuranceType: string;
  localIsIISResponsible: string;

  loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();

  IssuingPaperList: string[] = [];
  TransactionTypeList: string[] = [];
  TransactionSourceList: string[] = [];

  SourceList: string[] = [];
  ProfitCenterList: string[] = [];
  TransactionStatusList: string[] = [];
  PartcipantNameList: string[] = [];
  PartcipantNameCode: string[] = [];
  PolicyNumberList: string[] = [];
  WhoRequestedList: string[] = [];
  AccountNameList: string[] = [];
  AccountPeriodList: string[] = [];
  CurrencyList: string[] = [];

  whorequestedfilteredOptions: Observable<string[]>;
  profitcenterfilteredOptions: Observable<string[]>;
  iisresponsiblefilteredOptions: Observable<string[]>;
  trxntypefilteredOptions: Observable<string[]>;
  accountnamefilteredOptions: Observable<string[]>;
  isfacultativefilteredOptions: Observable<string[]>;
  reinsurancetypefilteredOptions: Observable<string[]>;
  accountperiodfilteredOptions: Observable<string[]>;
  policynumberfilteredOptions: Observable<string[]>;
  quotasharefilteredOptions: Observable<string[]>;
  sourcefilteredOptions: Observable<string[]>;
  codefilteredOptions: Observable<string[]>;
  isxolfilteredOptions: Observable<string[]>;
  issuingpaperlistfilteredOptions: Observable<string[]>;
  currencyfilteredOptions: Observable<string[]>;
  participantname1filteredOptions: Observable<string[]>;
  participantname2filteredOptions: Observable<string[]>;
  participantname3filteredOptions: Observable<string[]>;
  participantname4filteredOptions: Observable<string[]>;
  participantname5filteredOptions: Observable<string[]>;
  trxstatusfilteredOptions: Observable<string[]>;
  trxnSourcefilteredOptions: Observable<string[]>;

  trxnstatushash: IHash = {'': null};
  trxntypehash: IHash = {'': null};
  IsFacultativehash: IHashnumber = {No: 0, Yes: -1, '': null};
  QuotaSharehash: IHashnumber = {'N - Include in QS': 0, 'Y - Exclude from QS': -1, '': null};
  IsXOLhash: IHashnumber = {'N - Exclude from XOL': 0, 'Y - Include in XOL': -1, '': null};
  IsIISResponsiblehash: IHashnumber = {No: 0, Yes: -1, '': null};
  participanthash: IHashnumber = {'': null};

  masterresponsebody: any;
  minDate: Date;
  isformvalid = false;
  summary;
  textMessage;
  validnumber = false;
  validpercentage = false;
  validprimaryinfo = false;
  isparticipantnamevalid = false;

  addcopysubscription: Subscription;

  date: Date;
  latestdate: string;

  public format = 'MM/dd/yyyy HH:mm';
  public format1 = 'MM/dd/yyyy';

  transactionobject: Transactions = new Transactions();

  addtransactionresponse: ExcelandTransactionResponse = new ExcelandTransactionResponse();

  constructor(private router: Router, private navigationService: NavigationService,
              private apiService: ApiService, private fb: FormBuilder,
              private messageService: MessageService,
              private datepipe: DatePipe,
              private decimalpipe: DecimalPipe) {
    this.minDate = new Date('01/01/1900');
  }

  ngOnDestroy() {
    this.addcopysubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.navigationService.removeAllMenus();
    this.date = new Date();
    this.latestdate = this.datepipe.transform(this.date, 'MM-dd-yyyy HH:mm:ss');
    this.loadingSubject.next(true);
    this.initialzieformgropus();
    this.getmastertabledata();
    this.patchcurrencydropdown();
    this.navigationService.stopaddnewtransaction();
    this.navigationService.stopcopytransactionflag();
    this.navigationService.onaddpage = true;
    this.navigationService.onupdatepage = false;
    this.navigationService.onusermaintenancepage = false;
    this.posttransactionobject();
    this.copyrecord();
    this.complexitycodes$ = ['1', '2', '3'];
    this.IsFacultative$ = ['No', 'Yes'];
    this.QuotaShare$ = ['N - Include in QS', 'Y - Exclude from QS'];
    this.IsXOL$ = ['N - Exclude from XOL', 'Y - Include in XOL'];
    this.IsIISResponsible$ = ['No', 'Yes'];
    this.ReinsuranceType$ = ['Direct', 'Assumed'];
    this.navigationService.hidetab();
    this.navigationService.showtransactiontab();
    this.navigationService.showaddpage();
    this.navigationService.hideupdatebuttonflag();
    this.navigationService.hideusermaintenancepage();
    this.navigationService.hideusermaintenancetab();
    this.changeState();
    this.apiService.getmastersdata().subscribe(masterdata => {
      let issuingpaper: IssuingPaper[] = [];
      let transactiontype: TransactionType[] = [];
      let source: Source[] = [];
      let profitcenter: ProfitCenter[] = [];
      let transactionstatus: TransactionStatus[] = [];
      let participantname: ParticipantName[] = [];
      let transactionsource : TransactionSource[] = [];
      this.masterresponsebody = masterdata.body;
      issuingpaper = this.masterresponsebody[2];
      transactiontype = this.masterresponsebody[7];
      source = this.masterresponsebody[5];
      profitcenter = this.masterresponsebody[4];
      transactionstatus = this.masterresponsebody[6];
      participantname = this.masterresponsebody[3];
      transactionsource = this.masterresponsebody[9];

      issuingpaper.forEach((value) => {
        this.IssuingPaperList.push(value.issuingpaper.issuingpaper);
      });
      transactiontype.forEach((value) => {
        const code: string = value.transactiontype.transactiontypecode;
        const desc: string = value.transactiontype.transactiontypedesc;
        this.trxntypehash[desc] = code;
        this.TransactionTypeList.push(desc);
      });
      source.forEach((value) => {
        this.SourceList.push(value.source.sourcedesc);
      });
      profitcenter.forEach((value) => {
        this.ProfitCenterList.push(value.profitcenter.profitcenter);
      });
      transactionstatus.forEach((value) => {
        const code: string = value.transactionstatus.code;
        const desc: string = value.transactionstatus.description;
        this.trxnstatushash[desc] = code;
        this.TransactionStatusList.push(code + '-' + desc);
      });
      participantname.forEach((value) => {
        const name: string = value.participantname.participantname;
        const id: string = value.participantname.ParticipantID.toString();
        this.PartcipantNameList.push(name);
        this.PartcipantNameCode.push(id);
        this.participanthash[name] = Number.parseInt(id, 10);
      });
      transactionsource.forEach((value) => {
        const transactionsourcedesc: string = value.TransactionSource.TransactionSourceDescription;
        this.TransactionSourceList.push(transactionsourcedesc);
      });

      this.whorequestedfilteredOptions = this.primaryinfoform.controls.WhoRequested.valueChanges.pipe(
        startWith(''),
        map(value => this._filterwhorequested(value))
      );
      this.profitcenterfilteredOptions = this.primaryinfoform.controls.ProfitCenter.valueChanges.pipe(
        startWith(''),
        map(value => this._filterprofitcenter(value))
      );
      this.iisresponsiblefilteredOptions = this.primaryinfoform.controls.IISResponsible.valueChanges.pipe(
        startWith(''),
        map(value => this._filteriisresponsible(value))
      );
      this.trxntypefilteredOptions = this.primaryinfoform.controls.TxnType.valueChanges.pipe(
        startWith(''),
        map(value => this._filtertrxntype(value))
      );
      this.isfacultativefilteredOptions = this.primaryinfoform.controls.Fac.valueChanges.pipe(
        startWith(''),
        map(value => this._filterisfacultive(value))
      );
      this.reinsurancetypefilteredOptions = this.primaryinfoform.controls.Reins.valueChanges.pipe(
        startWith(''),
        map(value => this._filterreinsurancetype(value))
      );
      this.quotasharefilteredOptions = this.primaryinfoform.controls.QuotaShare.valueChanges.pipe(
        startWith(''),
        map(value => this._filterquotashare(value))
      );
      this.sourcefilteredOptions = this.primaryinfoform.controls.Source.valueChanges.pipe(
        startWith(''),
        map(value => this._filtersource(value))
      );
      this.codefilteredOptions = this.primaryinfoform.controls.ComplexityCode.valueChanges.pipe(
        startWith(''),
        map(value => this._filtercode(value))
      );
      this.isxolfilteredOptions = this.primaryinfoform.controls.XOL.valueChanges.pipe(
        startWith(''),
        map(value => this._filterisxol(value))
      );
      this.issuingpaperlistfilteredOptions = this.primaryinfoform.controls.IssuingPaper.valueChanges.pipe(
        startWith(''),
        map(value => this._filterissuingpaper(value))
      );
      this.participantname1filteredOptions = this.participantform.controls.ParticipantName1.valueChanges.pipe(
        startWith(''),
        map(value => this._filterparticipantname1(value))
      );
      this.participantname2filteredOptions = this.participantform.controls.ParticipantName2.valueChanges.pipe(
        startWith(''),
        map(value => this._filterparticipantname1(value))
      );
      this.participantname3filteredOptions = this.participantform.controls.ParticipantName3.valueChanges.pipe(
        startWith(''),
        map(value => this._filterparticipantname1(value))
      );
      this.participantname4filteredOptions = this.participantform.controls.ParticipantName4.valueChanges.pipe(
        startWith(''),
        map(value => this._filterparticipantname1(value))
      );
      this.participantname5filteredOptions = this.participantform.controls.ParticipantName5.valueChanges.pipe(
        startWith(''),
        map(value => this._filterparticipantname1(value))
      );
      this.trxstatusfilteredOptions = this.mfxtransmissionform.controls.TrxnStatus.valueChanges.pipe(
        startWith(''),
        map(value => this._filtertrxstatus(value))
      );
      this.trxnSourcefilteredOptions = this.primaryinfoform.controls.TxnSource.valueChanges.pipe(
        startWith(''),
        map(value => this._filtertrxnSource(value))
      );

      this.loadingSubject.next(false);
    });
  }

  _filteriisresponsible(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.IsIISResponsible$.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filterwhorequested(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.WhoRequestedList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filterprofitcenter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.ProfitCenterList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filtertrxntype(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.TransactionTypeList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filteraccountname(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.AccountNameList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filterisfacultive(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.IsFacultative$.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filterreinsurancetype(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.ReinsuranceType$.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filteraccountperiod(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.AccountPeriodList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filterpolicynumber(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.PolicyNumberList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filterquotashare(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.QuotaShare$.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filtersource(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.SourceList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filtercode(value: number): string[] {
    const filterValue = value.toString().toLowerCase();

    return this.complexitycodes$.filter(option => option.toString().toLowerCase().indexOf(filterValue) === 0);
  }

  _filterisxol(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.IsXOL$.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filterissuingpaper(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.IssuingPaperList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filtercurrency(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.CurrencyList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filterparticipantname1(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.PartcipantNameList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  _filtertrxstatus(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.TransactionStatusList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  _filtertrxnSource(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.TransactionSourceList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  patchcurrencydropdown() {
    this.monetarydetailsform.patchValue(
      {
        Currency: 'USD - United States Dollar'
      }
    );
  }

  changeState() {
    this.participantform.get('ParticipantName2').valueChanges
      .subscribe(res => {
        this.participantform.controls.ParticipantPercent2.enable();
        this.participantform.controls.FeePercent2.enable();
      });
    this.participantform.get('ParticipantName3').valueChanges
      .subscribe(res => {
        this.participantform.controls.ParticipantPercent3.enable();
        this.participantform.controls.FeePercent3.enable();
      });
    this.participantform.get('ParticipantName4').valueChanges
      .subscribe(res => {
        this.participantform.controls.ParticipantPercent4.enable();
        this.participantform.controls.FeePercent4.enable();
      });
    this.participantform.get('ParticipantName5').valueChanges
      .subscribe(res => {
        this.participantform.controls.ParticipantPercent5.enable();
        this.participantform.controls.FeePercent5.enable();
      });
  }

  limitfocusOutFunction() {
    if (this.monetarydetailsform.get('Limits').value != null
      && !this.monetarydetailsform.get('Limits').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('Limits').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          Limits: this.decimalpipe.transform(value, '1.2-2', 'en')
        }
      );
    }
  }

  partoffocusOutFunction() {
    if (this.monetarydetailsform.get('PartOf').value != null
      && !this.monetarydetailsform.get('PartOf').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('PartOf').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          PartOf: this.decimalpipe.transform(value, '1.2-2', 'en')
        }
      );
    }
  }

  attachmentfocusOutFunction() {
    if (this.monetarydetailsform.get('Attachment').value != null
      && !this.monetarydetailsform.get('Attachment').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('Attachment').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          Attachment: this.decimalpipe.transform(value, '1.2-2', 'en')
        }
      );
    }
  }

  trxamountfocusOutFunction() {
    if (this.monetarydetailsform.get('TrxAmount').value != null
      && !this.monetarydetailsform.get('TrxAmount').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('TrxAmount').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          TrxAmount: this.decimalpipe.transform(value, '1.2-2', 'en')
        }
      );
    }
  }

  TaxesfocusOutFunction() {
    if (this.monetarydetailsform.get('Taxes').value != null
      && !this.monetarydetailsform.get('Taxes').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          Taxes: this.decimalpipe.transform(value, '1.2-2')
        }
      );
    }
  }

  FeesfocusOutFunction() {
    if (this.monetarydetailsform.get('Fees').value != null
      && !this.monetarydetailsform.get('Fees').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          Fees: this.decimalpipe.transform(value, '1.2-2')
        }
      );
    }
  }

  SurchargesfocusOutFunction() {
    if (this.monetarydetailsform.get('Surcharges').value != null
      && !this.monetarydetailsform.get('Surcharges').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          Surcharges: this.decimalpipe.transform(value, '1.2-2')
        }
      );
    }
  }

  commissionfocusOutFunction() {
    if (this.monetarydetailsform.get('Commission').value != null
      && !this.monetarydetailsform.get('Commission').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('Commission').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          Commission: this.decimalpipe.transform(value, '1.2-2')
        }
      );
    }
  }

  writtenlinefocusOutFunction() {
    if (this.monetarydetailsform.get('WrittenLine').value != null
      && !this.monetarydetailsform.get('WrittenLine').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = parseFloat(this.monetarydetailsform.get('WrittenLine').value.toString().replace(/,/g, ''));
      this.monetarydetailsform.patchValue(
        {
          WrittenLine: this.decimalpipe.transform(value, '1.5-5')
        }
      );
    }
  }

  percentage1focusOutFunction() {
    if (this.participantform.get('ParticipantPercent1').value != null
      && !this.participantform.get('ParticipantPercent1').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('ParticipantPercent1').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          ParticipantPercent1: value1
        }
      );
    }
  }

  fee1focusOutFunction() {
    if (this.participantform.get('FeePercent1').value != null
      && !this.participantform.get('FeePercent1').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('FeePercent1').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          FeePercent1: value1
        }
      );
    }
  }

  percentage2focusOutFunction() {
    if (this.participantform.get('ParticipantPercent2').value != null
      && !this.participantform.get('ParticipantPercent2').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('ParticipantPercent2').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          ParticipantPercent2: value1
        }
      );
    }
  }

  fee2focusOutFunction() {
    if (this.participantform.get('FeePercent2').value != null
      && !this.participantform.get('FeePercent2').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('FeePercent2').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          FeePercent2: value1
        }
      );
    }
  }

  percentage3focusOutFunction() {
    if (this.participantform.get('ParticipantPercent3').value != null
      && !this.participantform.get('ParticipantPercent3').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('ParticipantPercent3').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          ParticipantPercent3: value1
        }
      );
    }
  }

  fee3focusOutFunction() {
    if (this.participantform.get('FeePercent3').value != null
      && !this.participantform.get('FeePercent3').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('FeePercent3').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          FeePercent3: value1
        }
      );
    }
  }

  percentage4focusOutFunction() {
    if (this.participantform.get('ParticipantPercent4').value != null
      && !this.participantform.get('ParticipantPercent4').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('ParticipantPercent4').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          ParticipantPercent4: value1
        }
      );
    }
  }

  fee4focusOutFunction() {
    if (this.participantform.get('FeePercent4').value != null
      && !this.participantform.get('FeePercent4').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('FeePercent4').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          FeePercent4: value1
        }
      );
    }
  }

  percentage5focusOutFunction() {
    if (this.participantform.get('ParticipantPercent5').value != null
      && !this.participantform.get('ParticipantPercent5').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('ParticipantPercent5').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          ParticipantPercent5: value1
        }
      );
    }
  }

  fee5focusOutFunction() {
    if (this.participantform.get('FeePercent5').value != null
      && !this.participantform.get('FeePercent5').value.toString().match(/^[a-zA-Z  *!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
      const value = this.decimalpipe.transform(this.participantform.get('FeePercent5').value, '1.4-4');
      const value1 = value.toString().replace(/,/g, '');
      this.participantform.patchValue(
        {
          FeePercent5: value1
        }
      );
    }
  }

  modelChanged($event) {
    if (this.monetarydetailsform.get('Commission').value != null) {
      if (parseFloat(this.monetarydetailsform.get('Commission').value.replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        const comission = parseFloat(this.monetarydetailsform.get('Commission').value.replace(/,/g, ''));
        if ($event !== '' && $event.toString().replace(/,/g, '').match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const trxnamount = parseFloat($event.replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CommissionD: this.decimalpipe.transform(((trxnamount * comission) / 100), '1.2-2', 'en')
          });
        } else {
          this.monetarydetailsform.patchValue({
            CommissionD: null
          });
        }
      }
    }
  }

  commissionmodelChanged($event) {
    if (this.monetarydetailsform.get('TrxAmount').value != null) {
      if (parseFloat(this.monetarydetailsform.get('TrxAmount').value.replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        const trxnamount = parseFloat(this.monetarydetailsform.get('TrxAmount').value.replace(/,/g, ''));
        if ($event !== '' && $event.toString().replace(/,/g, '').match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const comission = parseFloat($event.replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CommissionD: this.decimalpipe.transform(((trxnamount * comission) / 100), '1.2-2', 'en')
          });
        } else {
          this.monetarydetailsform.patchValue({
            CommissionD: null
          });
        }
      }
    }
  }

  taxesChanged($event) {
    const enteredvalue = $event;
    if (this.monetarydetailsform.get('Fees').value != null
      && parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
      if (this.monetarydetailsform.get('Surcharges').value != null
        && parseFloat(this.monetarydetailsform.get('Surcharges')
          .value.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
          const taxes = parseFloat(enteredvalue.toString().replace(/,/g, ''));
          const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees + taxes + surcharges, '1.2-2', 'en')
          });
        } else {
          const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
          const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees + surcharges, '1.2-2', 'en')
          });
        }
      } else {
        if ($event !== '' && parseFloat(enteredvalue.replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
          const taxes = parseFloat(enteredvalue.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees + taxes, '1.2-2', 'en')
          });
        } else {
          const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees, '1.2-2', 'en')
          });
        }
      }
    }
    if ((this.monetarydetailsform.get('Surcharges').value != null
        && this.monetarydetailsform.get('Surcharges').value.toString().match(/^-?[0-9]+(\.?[0-9]+)?$/))
      && (this.monetarydetailsform.get('Fees').value == null
        || this.monetarydetailsform.get('Fees').value === '')) {
      if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        const taxes = parseFloat($event.toString().replace(/,/g, ''));
        const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(surcharges + taxes, '1.2-2', 'en')
        });
      } else {
        const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(surcharges, '1.2-2', 'en')
        });
      }
    }
    if ((this.monetarydetailsform.get('Fees').value == null
        || this.monetarydetailsform.get('Fees').value === '')
      && (this.monetarydetailsform.get('Surcharges').value == null
        || this.monetarydetailsform.get('Surcharges').value === '')
      && enteredvalue != null) {
      if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(parseFloat($event.toString().replace(/,/g, '')), '1.2-2', 'en')
        });
      } else {
        this.monetarydetailsform.patchValue({
          CalculatedTFS: null
        });
      }
    }
  }

  feesChanged($event) {
    const enteredvalue = $event;
    if (this.monetarydetailsform.get('Taxes').value != null
      && parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
      if (this.monetarydetailsform.get('Surcharges').value != null
        && parseFloat(this.monetarydetailsform.get('Surcharges')
          .value.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
          const fees = parseFloat($event.toString().replace(/,/g, ''));
          const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees + taxes + surcharges, '1.2-2', 'en')
          });
        } else {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
          const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(taxes + surcharges, '1.2-2', 'en')
          });
        }
      } else {
        if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
          const fees = parseFloat($event.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees + taxes, '1.2-2', 'en')
          });
        } else {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(taxes, '1.2-2', 'en')
          });
        }
      }
    }
    if ((this.monetarydetailsform.get('Surcharges').value != null
        && this.monetarydetailsform.get('Surcharges').value.toString().match(/^-?[0-9]+(\.?[0-9]+)?$/))
      && (this.monetarydetailsform.get('Taxes').value == null
        || this.monetarydetailsform.get('Taxes').value === '')) {
      if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        const fees = parseFloat($event.toString().replace(/,/g, ''));
        const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(surcharges + fees, '1.2-2', 'en')
        });
      } else {
        const surcharges = parseFloat(this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, ''));
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(surcharges, '1.2-2', 'en')
        });
      }
    }
    if ((this.monetarydetailsform.get('Taxes').value == null
        || this.monetarydetailsform.get('Taxes').value === '')
      && (this.monetarydetailsform.get('Surcharges').value == null
        || this.monetarydetailsform.get('Surcharges').value === '')
      && enteredvalue != null) {
      if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(parseFloat($event.toString().replace(/,/g, '')), '1.2-2', 'en')
        });
      } else {
        this.monetarydetailsform.patchValue({
          CalculatedTFS: null
        });
      }
    }
  }

  surchargesChanged($event) {
    const enteredvalue = $event;
    if (this.monetarydetailsform.get('Taxes').value != null
      && parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
      if (this.monetarydetailsform.get('Fees').value != null
        && parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
          const surcharges = parseFloat($event.toString().replace(/,/g, ''));
          const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees + taxes + surcharges, '1.2-2', 'en')
          });
        } else {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
          const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(fees + taxes, '1.2-2', 'en')
          });
        }
      } else {
        if ($event !== '' && parseFloat(enteredvalue.replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, ''));
          const surcharges = parseFloat($event.toString().replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(surcharges + taxes, '1.2-2', 'en')
          });
        } else {
          const taxes = parseFloat(this.monetarydetailsform.get('Taxes').value.replace(/,/g, ''));
          this.monetarydetailsform.patchValue({
            CalculatedTFS: this.decimalpipe.transform(taxes, '1.2-2', 'en')
          });
        }
      }
    }
    if ((this.monetarydetailsform.get('Fees').value != null
        && this.monetarydetailsform.get('Fees').value.toString().match(/^-?[0-9]+(\.?[0-9]+)?$/))
      && (this.monetarydetailsform.get('Taxes').value == null
        || this.monetarydetailsform.get('Taxes').value === '')) {
      if ($event !== '' && parseFloat(enteredvalue.replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        const surcharges = parseFloat($event.toString().replace(/,/g, ''));
        const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(surcharges + fees, '1.2-2', 'en')
        });
      } else {
        const fees = parseFloat(this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, ''));
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(fees, '1.2-2', 'en')
        });
      }
    }
    if ((this.monetarydetailsform.get('Fees').value == null
        || this.monetarydetailsform.get('Fees').value === '')
      && (this.monetarydetailsform.get('Taxes').value == null
        || this.monetarydetailsform.get('Taxes').value === '')
      && enteredvalue != null) {
      if ($event !== '' && parseFloat(enteredvalue.toString().replace(/,/g, '')).toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.monetarydetailsform.patchValue({
          CalculatedTFS: this.decimalpipe.transform(parseFloat($event.toString().replace(/,/g, '')), '1.2-2', 'en')
        });
      } else {
        this.monetarydetailsform.patchValue({
          CalculatedTFS: null
        });
      }
    }
  }

  copyrecord() {
    this.navigationService.iscopytransaction.subscribe((data) => {
      if (data === true) {
        this.addnewtransactionobject();
      }
    });
  }

  addnewtransactionobject() {
    this.isformvalid = false;
    this.validnumber = false;
    this.validpercentage = false;
    this.validprimaryinfo = false;
    this.isparticipantnamevalid = false;
    this.checkprimaryinfovalidations();
    this.checknumericvalidations();
    this.sameparticipantnamevalidation();
    this.checkhighestpercentage();
    this.checkvalidations();
    if (this.isformvalid && this.validnumber && this.validpercentage
      && this.validprimaryinfo && this.isparticipantnamevalid) {
      this.loadingSubject.next(true);
      this.date = new Date();
      this.latestdate = this.datepipe.transform(this.date, 'yyyy-MM-dd HH:mm:ss');
      this.addnewtransaction();
      console.log(this.transactionobject);
      this.apiService.postnewtransaction(this.transactionobject).subscribe(response => {
        this.addtransactionresponse = new ExcelandTransactionResponse();
        this.addtransactionresponse = response;
        if (this.addtransactionresponse.statusCode === 200) {
          const output = this.addtransactionresponse.body;
          const newtransactionid = output[0];
          if (newtransactionid['@Output'] != null) {
            this.primaryinfoform.patchValue(
              {
                TransactionID: newtransactionid['@Output'],
              }
            );
            this.summary = 'Transaction added';
            this.textMessage = 'Generated Transaction ID :' + newtransactionid['@Output'];
            this.messageService.add({
              severity: 'success',
              summary: 'Transaction added',
              detail: '',
            });
            this.resetformgropus();
            if (this.navigationService.redirecttotrxn === true) {
              this.router.navigate(['transactions']);
            }
            if (this.navigationService.redirecttohome === true) {
              this.router.navigate(['']);
            }
          } else {
            this.summary = 'Transaction Not Inserted';
            this.textMessage = 'Something Went Wrong';
            this.messageService.add({
              severity: 'error',
              summary: 'Transaction Not Inserted',
              detail: '',
            });
          }
        } else {
          this.summary = 'Transaction Not Inserted';
          this.textMessage = 'Something Went Wrong';
          this.messageService.add({
            severity: 'error',
            summary: 'Transaction Not Inserted',
            detail: '',
          });
        }
        this.loadingSubject.next(false);
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: this.summary,
        detail: this.textMessage,
      });
    }
  }

  posttransactionobject() {
    this.addcopysubscription = this.navigationService.isaddtransaction.subscribe((data) => {
      if (data === true) {
        this.addnewtransactionobject();
      }
    });
  }

  addnewtransaction() {
    this.transactionobject = new Transactions();
    if (this.primaryinfoform.get('WhoRequested').value !== ''
      && this.primaryinfoform.get('WhoRequested').value != null) {
      this.transactionobject.WhoRequested = this.primaryinfoform.get('WhoRequested').value;
    } else {
      this.transactionobject.WhoRequested = null;
    }
    if (this.primaryinfoform.get('ProfitCenter').value !== ''
      && this.primaryinfoform.get('ProfitCenter').value != null) {
      this.transactionobject.ProfitCenter = this.primaryinfoform.get('ProfitCenter').value;
    } else {
      this.transactionobject.ProfitCenter = null;
    }
    this.transactionobject.IsIISResponsible = this.IsIISResponsiblehash[this.primaryinfoform.get('IISResponsible').value];
    if (this.primaryinfoform.get('TxnType').value !== ''
      && this.primaryinfoform.get('TxnType').value != null) {
      this.transactionobject.TransactionTypeCode = this.trxntypehash[this.primaryinfoform.get('TxnType').value];
    } else {
      this.transactionobject.TransactionTypeCode = null;
    }
    if (this.primaryinfoform.get('TxnSource').value !== ''
      && this.primaryinfoform.get('TxnSource').value != null) {
       this.transactionobject.TransactionSourceCode = this.primaryinfoform.get('TxnSource').value;
    } else {
      this.transactionobject.TransactionSourceCode = null;
    }

    if (this.primaryinfoform.get('AccountName').value !== ''
      && this.primaryinfoform.get('AccountName').value != null) {
      this.transactionobject.AccountName = this.primaryinfoform.get('AccountName').value;
    } else {
      this.transactionobject.AccountName = null;
    }
    this.transactionobject.IsFacultative = this.IsFacultativehash[this.primaryinfoform.get('Fac').value];
    if (this.primaryinfoform.get('Reins').value !== ''
      && this.primaryinfoform.get('Reins').value != null) {
      this.transactionobject.ReinsuranceType = this.primaryinfoform.get('Reins').value;
    } else {
      this.transactionobject.ReinsuranceType = null;
    }
    if (this.primaryinfoform.get('AccountPeriod').value !== '') {
      this.transactionobject.AccountingPeriodCode = this.primaryinfoform.get('AccountPeriod').value;
    } else {
      this.transactionobject.AccountingPeriodCode = null;
    }
    if (this.primaryinfoform.get('Policy').value !== ''
      && this.primaryinfoform.get('Policy').value != null) {
      this.transactionobject.PolicyNumber = this.primaryinfoform.get('Policy').value;
    } else {
      this.transactionobject.PolicyNumber = null;
    }
    if (this.primaryinfoform.get('EffDate').value !== ''
      && this.primaryinfoform.get('EffDate').value != null) {
      this.transactionobject.EffectiveDate = this.datepipe.transform(this.primaryinfoform.get('EffDate').value, 'yyyy-MM-dd');
    } else {
      this.transactionobject.EffectiveDate = null;
    }
    this.transactionobject.IsQuoteShare = this.QuotaSharehash[this.primaryinfoform.get('QuotaShare').value];
    if (this.primaryinfoform.get('Source').value !== ''
      && this.primaryinfoform.get('Source').value != null) {
      this.transactionobject.Source = this.primaryinfoform.get('Source').value;
    } else {
      this.transactionobject.Source = null;
    }
    if (this.primaryinfoform.get('ComplexityCode').value !== ''
      && this.primaryinfoform.get('ComplexityCode').value != null) {
      this.transactionobject.ComplexityCodes = this.primaryinfoform.get('ComplexityCode').value;
    } else {
      this.transactionobject.ComplexityCodes = null;
    }
    this.transactionobject.IsXOL = this.IsXOLhash[this.primaryinfoform.get('XOL').value];
    if (this.primaryinfoform.get('IssuingPaper').value !== ''
      && this.primaryinfoform.get('IssuingPaper').value != null) {
      this.transactionobject.IssuingPaper = this.primaryinfoform.get('IssuingPaper').value;
    } else {
      this.transactionobject.IssuingPaper = null;
    }
    if (this.monetarydetailsform.get('Limits').value != null
      && this.monetarydetailsform.get('Limits').value !== '') {
      this.transactionobject.Limits = this.monetarydetailsform.get('Limits').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Limits = null;
    }
    if (this.monetarydetailsform.get('PartOf').value != null
      && this.monetarydetailsform.get('PartOf').value !== '') {
      this.transactionobject.PartOf = this.monetarydetailsform.get('PartOf').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.PartOf = null;
    }
    if (this.monetarydetailsform.get('Attachment').value != null
      && this.monetarydetailsform.get('Attachment').value !== '') {
      this.transactionobject.Attachment = this.monetarydetailsform.get('Attachment').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Attachment = null;
    }
    if (this.monetarydetailsform.get('TrxAmount').value != null
      && this.monetarydetailsform.get('TrxAmount').value !== '') {
      this.transactionobject.TrxAmount = this.monetarydetailsform.get('TrxAmount').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.TrxAmount = null;
    }
    if (this.monetarydetailsform.get('Taxes').value != null
      && this.monetarydetailsform.get('Taxes').value !== '') {
      this.transactionobject.Taxes = this.monetarydetailsform.get('Taxes').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Taxes = null;
    }
    if (this.monetarydetailsform.get('Fees').value != null
      && this.monetarydetailsform.get('Fees').value !== '') {
      this.transactionobject.Fees = this.monetarydetailsform.get('Fees').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Fees = null;
    }
    if (this.monetarydetailsform.get('Surcharges').value != null
      && this.monetarydetailsform.get('Surcharges').value !== '') {
      this.transactionobject.Surcharges = this.monetarydetailsform.get('Surcharges').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.Surcharges = null;
    }
    if (this.monetarydetailsform.get('CalculatedTFS').value != null
      && this.monetarydetailsform.get('CalculatedTFS').value !== '') {
      this.transactionobject.CalculatedTFS = this.monetarydetailsform.get('CalculatedTFS').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.CalculatedTFS = null;
    }
    this.transactionobject.Commission = this.monetarydetailsform.get('Commission').value;
    if (this.monetarydetailsform.get('CommissionD').value != null
      && this.monetarydetailsform.get('CommissionD').value !== '') {
      this.transactionobject.CommissionD = this.monetarydetailsform.get('CommissionD').value.toString().replace(/,/g, '');
    } else {
      this.transactionobject.CommissionD = null;
    }
    this.transactionobject.WrittenLine = this.monetarydetailsform.get('WrittenLine').value;
    if (this.monetarydetailsform.get('Currency').value !== ''
      && this.monetarydetailsform.get('Currency').value != null) {
      this.transactionobject.Currency = this.monetarydetailsform.get('Currency').value;
    } else {
      this.transactionobject.Currency = null;
    }
    if (this.participantform.get('ParticipantName1').value !== ''
      && this.participantform.get('ParticipantName1').value != null) {
      this.transactionobject.ParticipantName1 = this.participanthash[this.participantform.get('ParticipantName1').value];
    } else {
      this.transactionobject.ParticipantName1 = null;
    }
    if (this.participantform.get('ParticipantPercent1').value !== ''
      && this.participantform.get('ParticipantPercent1').value != null) {
      this.transactionobject.ParticipantPercent1 = this.participantform.get('ParticipantPercent1').value;
    } else {
      this.transactionobject.ParticipantPercent1 = null;
    }
    if (this.participantform.get('FeePercent1').value !== ''
      && this.participantform.get('FeePercent1').value != null) {
      this.transactionobject.FeePercent1 = this.participantform.get('FeePercent1').value;
    } else {
      this.transactionobject.FeePercent1 = null;
    }
    if (this.participantform.get('ParticipantName2').value !== ''
      && this.participantform.get('ParticipantName2').value != null) {
      this.transactionobject.ParticipantName2 = this.participanthash[this.participantform.get('ParticipantName2').value];
    } else {
      this.transactionobject.ParticipantName2 = null;
    }
    if (this.participantform.get('ParticipantPercent2').value !== ''
      && this.participantform.get('ParticipantPercent2').value != null) {
      this.transactionobject.ParticipantPercent2 = this.participantform.get('ParticipantPercent2').value;
    } else {
      this.transactionobject.ParticipantPercent2 = null;
    }
    if (this.participantform.get('FeePercent2').value !== ''
      && this.participantform.get('FeePercent2').value != null) {
      this.transactionobject.FeePercent2 = this.participantform.get('FeePercent2').value;
    } else {
      this.transactionobject.FeePercent2 = null;
    }
    if (this.participantform.get('ParticipantName3').value !== ''
      && this.participantform.get('ParticipantName3').value != null) {
      this.transactionobject.ParticipantName3 = this.participanthash[this.participantform.get('ParticipantName3').value];
    } else {
      this.transactionobject.ParticipantName3 = null;
    }
    if (this.participantform.get('ParticipantPercent3').value !== ''
      && this.participantform.get('ParticipantPercent3').value != null) {
      this.transactionobject.ParticipantPercent3 = this.participantform.get('ParticipantPercent3').value;
    } else {
      this.transactionobject.ParticipantPercent3 = null;
    }
    if (this.participantform.get('FeePercent3').value !== ''
      && this.participantform.get('FeePercent3').value != null) {
      this.transactionobject.FeePercent3 = this.participantform.get('FeePercent3').value;
    } else {
      this.transactionobject.FeePercent3 = null;
    }
    if (this.participantform.get('ParticipantName4').value !== ''
      && this.participantform.get('ParticipantName4').value != null) {
      this.transactionobject.ParticipantName4 = this.participanthash[this.participantform.get('ParticipantName4').value];
    } else {
      this.transactionobject.ParticipantName4 = null;
    }
    if (this.participantform.get('ParticipantPercent4').value !== ''
      && this.participantform.get('ParticipantPercent4').value != null) {
      this.transactionobject.ParticipantPercent4 = this.participantform.get('ParticipantPercent4').value;
    } else {
      this.transactionobject.ParticipantPercent4 = null;
    }
    if (this.participantform.get('FeePercent4').value !== ''
      && this.participantform.get('FeePercent4').value != null) {
      this.transactionobject.FeePercent4 = this.participantform.get('FeePercent4').value;
    } else {
      this.transactionobject.FeePercent4 = null;
    }
    if (this.participantform.get('ParticipantName5').value !== ''
      && this.participantform.get('ParticipantName5').value != null) {
      this.transactionobject.ParticipantName5 = this.participanthash[this.participantform.get('ParticipantName5').value];
    } else {
      this.transactionobject.ParticipantName5 = null;
    }
    if (this.participantform.get('ParticipantPercent5').value !== ''
      && this.participantform.get('ParticipantPercent5').value != null) {
      this.transactionobject.ParticipantPercent5 = this.participantform.get('ParticipantPercent5').value;
    } else {
      this.transactionobject.ParticipantPercent5 = null;
    }
    if (this.participantform.get('FeePercent5').value !== ''
      && this.participantform.get('FeePercent5').value != null) {
      this.transactionobject.FeePercent5 = this.participantform.get('FeePercent5').value;
    } else {
      this.transactionobject.FeePercent5 = null;
    }
    this.transactionobject.ReconciliationStatus = this.mfxtransmissionform.get('ReconciliationStatus').value;
    this.transactionobject.WhenReconciled =
      this.datepipe.transform(this.mfxtransmissionform.get('WhenReconciled').value, 'yyyy-MM-dd HH:mm:ss');
    this.transactionobject.WhenRcvdFromMFX =
      this.datepipe.transform(this.mfxtransmissionform.get('WhenRcvdFromMFX').value, 'yyyy-MM-dd HH:mm:ss');
    this.transactionobject.WhenSentToMFX =
      this.datepipe.transform(this.mfxtransmissionform.get('ReceivedtoBooking').value, 'yyyy-MM-dd HH:mm:ss');
    console.log(this.transactionobject.WhenSentToMFX);
    if (this.mfxtransmissionform.get('TrxnStatus').value !== ''
      && this.mfxtransmissionform.get('TrxnStatus').value != null) {
      const trxnstatuscodesplit = this.mfxtransmissionform.get('TrxnStatus').value.split('-');
      this.transactionobject.TransactionStatusCode = this.trxnstatushash[trxnstatuscodesplit[1]];
    } else {
      this.transactionobject.TransactionStatusCode = null;
    }
    this.transactionobject.WhoCreated = this.navigationService.whoCreatedUpdated;
    this.transactionobject.WhenCreated = this.latestdate;
    // this.datepipe.transform(this.updateinfoform.get('WhenCreated').value, 'yyyy-MM-dd HH:mm:ss');
    this.transactionobject.WhoUpdated = this.navigationService.whoCreatedUpdated;
    this.transactionobject.WhenUpdated = this.latestdate;
    // this.datepipe.transform(this.updateinfoform.get('WhenUpdated').value, 'yyyy-MM-dd HH:mm:ss');
    this.transactionobject.Notes = this.glupdateform.get('Notes').value;
  }

  initialzieformgropus() {
    this.primaryinfoform = this.fb.group({
      TransactionID: new FormControl({value: '{New}', disabled: true}),
      WhoRequested: new FormControl(null),
      ProfitCenter: new FormControl(null),
      IISResponsible: new FormControl(''),
      TxnType: new FormControl(''),
      AccountName: new FormControl(null),
      Fac: new FormControl(''),
      Reins: new FormControl(null),
      AccountPeriod: new FormControl(''),
      Policy: new FormControl(null),
      EffDate: new FormControl(null),
      QuotaShare: new FormControl(''),
      Source: new FormControl(null),
      ComplexityCode: new FormControl(null),
      XOL: new FormControl(''),
      IssuingPaper: new FormControl(null),
      TxnSource: new FormControl('')
    });


    this.monetarydetailsform = this.fb.group({
      Limits: new FormControl(null),
      PartOf: new FormControl(null),
      Attachment: new FormControl(null),
      TrxAmount: new FormControl(null),
      Taxes: new FormControl(null),
      Fees: new FormControl(null),
      Surcharges: new FormControl(null),
      CalculatedTFS: new FormControl({value: null, disabled: true}),
      Commission: new FormControl(null),
      CommissionD: new FormControl({value: null, disabled: true}),
      WrittenLine: new FormControl(null),
      Currency: new FormControl(null),
    });

    this.participantform = this.fb.group(
      {
        ParticipantName1: new FormControl(''),
        ParticipantPercent1: new FormControl(null),
        FeePercent1: new FormControl(null),
        ParticipantName2: new FormControl(''),
        ParticipantPercent2: new FormControl({value: null, disabled: true}),
        FeePercent2: new FormControl({value: null, disabled: true}),
        ParticipantName3: new FormControl(''),
        ParticipantPercent3: new FormControl({value: null, disabled: true}),
        FeePercent3: new FormControl({value: null, disabled: true}),
        ParticipantName4: new FormControl(''),
        ParticipantPercent4: new FormControl({value: null, disabled: true}),
        FeePercent4: new FormControl({value: null, disabled: true}),
        ParticipantName5: new FormControl(''),
        ParticipantPercent5: new FormControl({value: null, disabled: true}),
        FeePercent5: new FormControl({value: null, disabled: true}),
      }
    );
    this.mfxtransmissionform = this.fb.group({

      ReconciliationStatus: new FormControl(null),
      WhenReconciled: new FormControl(null),
      WhenRcvdFromMFX: new FormControl(null),
      ReceivedtoBooking: new FormControl(new Date(this.date)),
      TrxnStatus: new FormControl(''),
    });

    this.updateinfoform = this.fb.group({
      WhoCreated: new FormControl({value: this.navigationService.whoCreatedUpdated, disabled: true}),
      WhenCreated: new FormControl({value: this.latestdate, disabled: true}),
      WhoUpdated: new FormControl({value: this.navigationService.whoCreatedUpdated, disabled: true}),
      WhenUpdated: new FormControl({value: this.latestdate, disabled: true}),
    });
    this.glupdateform = this.fb.group({
      Notes: new FormControl(null)
    });
    this.primaryinfoform.controls.TxnType.value=='' || this.primaryinfoform.controls.TxnType.value== null ? this.primaryinfoform.controls.TxnSource.disable() : this.primaryinfoform.controls.TxnSource.enable();
    this.primaryinfoform.controls.TxnType.valueChanges.subscribe(value=>{
      value=='' || value==null ? this.primaryinfoform.controls.TxnSource.disable() : this.primaryinfoform.controls.TxnSource.enable();
    });
  }

  resetformgropus() {
    this.date = new Date();
    this.latestdate = this.datepipe.transform(this.date, 'MM-dd-yyyy HH:mm:ss');
    this.primaryinfoform.reset({
      TransactionID: '{New}',
      WhoRequested: null,
      ProfitCenter: '',
      IISResponsible: '',
      TxnType: '',
      AccountName: null,
      Fac: '',
      Reins: '',
      AccountPeriod: '',
      Policy: null,
      EffDate: null,
      QuotaShare: '',
      Source: '',
      ComplexityCode: '',
      XOL: '',
      IssuingPaper: '',
      TxnSource:''
    });


    this.monetarydetailsform.reset({
      Limits: null,
      PartOf: null,
      Attachment: null,
      TrxAmount: null,
      Taxes: null,
      Fees: null,
      Surcharges: null,
      CalculatedTFS: null,
      Commission: null,
      CommissionD: null,
      WrittenLine: null,
      Currency: 'USD - United States Dollar',
    });

    this.participantform.reset(
      {
        ParticipantName1: '',
        ParticipantPercent1: null,
        FeePercent1: null,
        ParticipantName2: '',
        ParticipantPercent2: null,
        FeePercent2: null,
        ParticipantName3: '',
        ParticipantPercent3: null,
        FeePercent3: null,
        ParticipantName4: '',
        ParticipantPercent4: null,
        FeePercent4: null,
        ParticipantName5: '',
        ParticipantPercent5: null,
        FeePercent5: null,
      }
    );
    this.mfxtransmissionform.reset({

      ReconciliationStatus: null,
      WhenReconciled: null,
      WhenRcvdFromMFX: null,
      ReceivedtoBooking: new Date(this.date),
      TrxnStatus: '',
    });

    this.updateinfoform.reset({
      WhoCreated: this.navigationService.whoCreatedUpdated,
      WhenCreated: this.latestdate,
      WhoUpdated: this.navigationService.whoCreatedUpdated,
      WhenUpdated: this.latestdate,
    });
    this.glupdateform.reset({
      Notes: null
    });
  }

  public datechange(type: string, value: Date): void {
    if (type === 'ReceivedtoBooking') {
      this.mfxtransmissionform.patchValue({
        ReceivedtoBooking: value,
      });
    }
    if (type === 'WhenReconciled') {
      this.mfxtransmissionform.patchValue({
        WhenReconciled: value,
      });
    }
    if (type === 'WhenRcvdFromMFX') {
      this.mfxtransmissionform.patchValue({
        WhenRcvdFromMFX: value,
      });
    }
    if (type === 'EffDate') {
      this.primaryinfoform.patchValue({
        EffDate: value,
      });
    }
  }


  getmastertabledata() {
    // this.apiService.getmasterspolicynumber().subscribe(policynumberdata => {
    //     let policynumber: PolicyNumberModel[] = [];
    //     policynumber = policynumberdata.body;
    //     policynumber.forEach((value) => {
    //         this.PolicyNumberList.push(value.PolicyNumber);
    //       });
    //     this.policynumberfilteredOptions = this.primaryinfoform.controls.Policy.valueChanges.pipe(
    //         startWith(''),
    //         map(value => this._filterpolicynumber(value))
    //       );
    //   });
    // this.apiService.getmasterwhorequested().subscribe(whorequesteddata => {
    //       let whorequested: WhoRequestedModel[] = [];
    //       whorequested = whorequesteddata.body;
    //       whorequested.forEach((value) => {
    //           this.WhoRequestedList.push(value.WhoRequested);
    //         });
    //       this.whorequestedfilteredOptions = this.primaryinfoform.controls.WhoRequested.valueChanges.pipe(
    //           startWith(''),
    //           map(value => this._filterwhorequested(value))
    //         );
    //     });
    // this.apiService.getmasteraccountname().subscribe(accountnamedata => {
    //         let accountname: AccountNameModel[] = [];
    //         accountname = accountnamedata.body;
    //         accountname.forEach((value) => {
    //             this.AccountNameList.push(value.AccountName);
    //           });
    //         this.accountnamefilteredOptions = this.primaryinfoform.controls.AccountName.valueChanges.pipe(
    //             startWith(''),
    //             map(value => this._filteraccountname(value))
    //           );
    //       });
    this.apiService.getmasteraccountperiod().subscribe(accountperioddata => {
      let accountperiod: AccountPeriodModel[] = [];
      accountperiod = accountperioddata.body;
      accountperiod.forEach((value) => {
        this.AccountPeriodList.push(value.Period);
      });
      this.accountperiodfilteredOptions = this.primaryinfoform.controls.AccountPeriod.valueChanges.pipe(
        startWith(''),
        map(value => this._filteraccountperiod(value))
      );
    });
    this.apiService.getmastercurrency().subscribe(currencydata => {
      let currency: CurrencyValues[] = [];
      currency = currencydata.body;
      currency.forEach((value) => {
        this.CurrencyList.push(value.currency);
      });
      this.currencyfilteredOptions = this.monetarydetailsform.controls.Currency.valueChanges.pipe(
        startWith(''),
        map(value => this._filtercurrency(value))
      );
    });

  }

  checknumericvalidations() {
    const multicheck: boolean[] = [];
    const body = this.monetarydetailsform.getRawValue();
    this.validnumber = true;
    if (this.monetarydetailsform.get('Limits').value != null
      && this.monetarydetailsform.get('Limits').value !== '') {
      if (!this.monetarydetailsform.get('Limits').value.replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('PartOf').value != null
      && this.monetarydetailsform.get('PartOf').value !== '') {
      if (!this.monetarydetailsform.get('PartOf').value.replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('Attachment').value != null
      && this.monetarydetailsform.get('Attachment').value !== '') {
      if (!this.monetarydetailsform.get('Attachment').value.replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('TrxAmount').value != null
      && this.monetarydetailsform.get('TrxAmount').value !== '') {
      if (!this.monetarydetailsform.get('TrxAmount').value.replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('Taxes').value != null
      && this.monetarydetailsform.get('Taxes').value !== '') {
      if (!this.monetarydetailsform.get('Taxes').value.replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('Fees').value != null
      && this.monetarydetailsform.get('Fees').value !== '') {
      if (!this.monetarydetailsform.get('Fees').value.replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('Surcharges').value != null
      && this.monetarydetailsform.get('Surcharges').value !== '') {
      if (!this.monetarydetailsform.get('Surcharges').value.replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (body.CalculatedTFS != null
      && body.CalculatedTFS !== '') {
      if (!body.CalculatedTFS.toString().replace(/,/g, '').match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('Commission').value != null
      && this.monetarydetailsform.get('Commission').value !== '') {
      if (this.monetarydetailsform.get('Commission').value.replace(/,/g, '').toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        if (this.monetarydetailsform.get('Commission').value >= -9999 &&
          this.monetarydetailsform.get('Commission').value <= 9999) {
          multicheck.push(true);
        } else {
          this.textMessage = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
          multicheck.push(false);
        }
      } else {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      }
    }
    if (body.CommissionD != null
      && body.CommissionD !== '') {
      if (!body.CommissionD.toString().replace(/,/g, '').match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      } else {
        multicheck.push(true);
      }
    }
    if (this.monetarydetailsform.get('WrittenLine').value != null
      && this.monetarydetailsform.get('WrittenLine').value !== '') {
      if (this.monetarydetailsform.get('WrittenLine').value.replace(/,/g, '').toString().match(/^[0-9]+(\.?[0-9]+)?$/)) {
        if (this.monetarydetailsform.get('WrittenLine').value >= 0 &&
          this.monetarydetailsform.get('WrittenLine').value <= 100) {
          multicheck.push(true);
        } else {
          this.textMessage = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
          multicheck.push(false);
        }
      } else {
        this.textMessage = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
     than the field size setting permits.';
        multicheck.push(false);
      }
    }
    multicheck.forEach((value) => {
      if (value === false) {
        this.validnumber = false;
        this.summary = 'The value you entered isn\'t valid for this field.';
      }
    });
  }

  checkhighestpercentage() {
    let finalpercentage = 0;
    if (this.participantform.get('ParticipantPercent1').value != null
      && this.participantform.get('ParticipantPercent1').value !== '') {
      finalpercentage = Number.parseFloat(this.participantform.get('ParticipantPercent1').value) +
        Number.parseFloat(finalpercentage.toString());
    }
    if (this.participantform.get('ParticipantPercent2').value != null
      && this.participantform.get('ParticipantPercent2').value !== '') {
      finalpercentage = Number.parseFloat(this.participantform.get('ParticipantPercent2').value) +
        Number.parseFloat(finalpercentage.toString());
    }
    if (this.participantform.get('ParticipantPercent3').value != null
      && this.participantform.get('ParticipantPercent3').value !== '') {
      finalpercentage = Number.parseFloat(this.participantform.get('ParticipantPercent3').value) +
        Number.parseFloat(finalpercentage.toString());
    }
    if (this.participantform.get('ParticipantPercent4').value != null
      && this.participantform.get('ParticipantPercent4').value !== '') {
      finalpercentage = Number.parseFloat(this.participantform.get('ParticipantPercent4').value) +
        Number.parseFloat(finalpercentage.toString());
    }
    if (this.participantform.get('ParticipantPercent5').value != null
      && this.participantform.get('ParticipantPercent5').value !== '') {
      finalpercentage = Number.parseFloat(this.participantform.get('ParticipantPercent5').value) +
        Number.parseFloat(finalpercentage.toString());
    }
    if (finalpercentage != null) {
      if (finalpercentage > 100 || finalpercentage < 100) {
        this.validpercentage = false;
        this.summary = 'Form is Invalid';
        this.textMessage = 'The Total Sum of the Participants percent must be equal to 100%';
      } else {
        this.validpercentage = true;
      }
    }
  }

  checkprimaryinfovalidations() {
    this.validprimaryinfo = true;
    const multicheck: IHash = {};
    if (this.primaryinfoform.invalid) {
      this.validprimaryinfo = !this.primaryinfoform.invalid;
      this.summary = 'Form is invalid';
      this.textMessage = 'Primary info is invalid!';
    }
    if (this.mfxtransmissionform.invalid) {
      this.validprimaryinfo = false;
      this.summary = 'Form is invalid';
      this.textMessage = 'Transmission info is invalid!';
    }
    if (this.primaryinfoform.get('TxnType').value !== ''
      && this.primaryinfoform.get('TxnType').value != null) {
      if (this.TransactionTypeList.includes(this.primaryinfoform.get('TxnType').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('TxnSource').value !== ''
    && this.primaryinfoform.get('TxnSource').value != null) {
    if (this.TransactionSourceList.includes(this.primaryinfoform.get('TxnSource').value)) {
      multicheck.true = 'true';
    } else {
      multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
    }
  }
    if (this.primaryinfoform.get('AccountPeriod').value !== ''
      && this.primaryinfoform.get('AccountPeriod').value != null) {
      if (this.AccountPeriodList.includes(this.primaryinfoform.get('AccountPeriod').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('Source').value !== ''
      && this.primaryinfoform.get('Source').value != null) {
      if (this.SourceList.includes(this.primaryinfoform.get('Source').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.monetarydetailsform.get('Currency').value !== ''
      && this.monetarydetailsform.get('Currency').value != null) {
      if (this.CurrencyList.includes(this.monetarydetailsform.get('Currency').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.mfxtransmissionform.get('TrxnStatus').value !== ''
      && this.mfxtransmissionform.get('TrxnStatus').value != null) {
      if (this.TransactionStatusList.includes(this.mfxtransmissionform.get('TrxnStatus').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.participantform.get('ParticipantName1').value !== ''
      && this.participantform.get('ParticipantName1').value != null) {
      if (this.PartcipantNameList.includes(this.participantform.get('ParticipantName1').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.participantform.get('ParticipantName2').value !== ''
      && this.participantform.get('ParticipantName2').value != null) {
      if (this.PartcipantNameList.includes(this.participantform.get('ParticipantName2').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.participantform.get('ParticipantName3').value !== ''
      && this.participantform.get('ParticipantName3').value != null) {
      if (this.PartcipantNameList.includes(this.participantform.get('ParticipantName3').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.participantform.get('ParticipantName4').value !== ''
      && this.participantform.get('ParticipantName4').value != null) {
      if (this.PartcipantNameList.includes(this.participantform.get('ParticipantName4').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.participantform.get('ParticipantName5').value !== ''
      && this.participantform.get('ParticipantName5').value != null) {
      if (this.PartcipantNameList.includes(this.participantform.get('ParticipantName5').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('ComplexityCode').value !== ''
      && this.primaryinfoform.get('ComplexityCode').value != null) {
      if (this.complexitycodes$.includes(this.primaryinfoform.get('ComplexityCode').value.toString())) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('ProfitCenter').value !== ''
      && this.primaryinfoform.get('ProfitCenter').value != null) {
      if (this.ProfitCenterList.includes(this.primaryinfoform.get('ProfitCenter').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('Fac').value !== ''
      && this.primaryinfoform.get('Fac').value != null) {
      if (this.IsFacultative$.includes(this.primaryinfoform.get('Fac').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('XOL').value !== ''
      && this.primaryinfoform.get('XOL').value != null) {
      if (this.IsXOL$.includes(this.primaryinfoform.get('XOL').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('IISResponsible').value !== ''
      && this.primaryinfoform.get('IISResponsible').value != null) {
      if (this.IsIISResponsible$.includes(this.primaryinfoform.get('IISResponsible').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('Reins').value !== ''
      && this.primaryinfoform.get('Reins').value != null) {
      if (this.ReinsuranceType$.includes(this.primaryinfoform.get('Reins').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('QuotaShare').value !== ''
      && this.primaryinfoform.get('QuotaShare').value != null) {
      if (this.QuotaShare$.includes(this.primaryinfoform.get('QuotaShare').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    if (this.primaryinfoform.get('IssuingPaper').value !== ''
      && this.primaryinfoform.get('IssuingPaper').value != null) {
      if (this.IssuingPaperList.includes(this.primaryinfoform.get('IssuingPaper').value)) {
        multicheck.true = 'true';
      } else {
        multicheck.false = 'Select an item from the list, or enter text that matches one of the listed items.';
      }
    }
    for (const key in multicheck) {
      if (key === 'false') {
        this.validprimaryinfo = false;
        this.summary = 'The text you entered isn\'t an item in the list.';
        this.textMessage = multicheck[key];
      }
    }
  }

  perticipantname1focusOutFunction() {
    if (this.participantform.get('ParticipantName1').value === '') {
      this.participantform.get('ParticipantPercent1').reset(null);
      this.participantform.get('FeePercent1').reset(null);
    } else {
      if (this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName4').value
        || this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName5').value) {
        this.participantform.get('ParticipantName1').reset('');
        this.summary = 'ParticipantName1 Already selected';
        this.textMessage = 'This Participant Name Already selected';
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: 'This Participant Name Already selected',
        });
      }
    }
  }

  perticipantname2focusOutFunction() {
    if (this.participantform.get('ParticipantName2').value === '') {
      this.participantform.get('ParticipantPercent2').reset(null);
      this.participantform.get('FeePercent2').reset(null);
    } else {
      if (this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName4').value
        || this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName5').value) {
        this.participantform.get('ParticipantName2').reset('');
        this.summary = 'ParticipantName2 Already selected';
        this.textMessage = 'This Participant Name Already selected';
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: 'This Participant Name Already selected',
        });
      }
    }
  }

  perticipantname3focusOutFunction() {
    if (this.participantform.get('ParticipantName3').value === '') {
      this.participantform.get('ParticipantPercent3').reset(null);
      this.participantform.get('FeePercent3').reset(null);
    } else {
      if (this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName4').value
        || this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName5').value) {
        this.participantform.get('ParticipantName3').reset('');
        this.summary = 'ParticipantName3 Already selected';
        this.textMessage = 'This Participant Name Already selected';
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: 'This Participant Name Already selected',
        });
      }
    }
  }

  perticipantname4focusOutFunction() {
    if (this.participantform.get('ParticipantName4').value === '') {
      this.participantform.get('ParticipantPercent4').reset(null);
      this.participantform.get('FeePercent4').reset(null);
    } else {
      if (this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName5').value) {
        this.participantform.get('ParticipantName4').reset('');
        this.summary = 'ParticipantName4 Already selected';
        this.textMessage = 'This Participant Name Already selected';
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: 'This Participant Name Already selected',
        });
      }
    }
  }

  perticipantname5focusOutFunction() {
    if (this.participantform.get('ParticipantName5').value === '') {
      this.participantform.get('ParticipantPercent5').reset(null);
      this.participantform.get('FeePercent5').reset(null);
    } else {
      if (this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName4').value) {
        this.participantform.get('ParticipantName5').reset('');
        this.summary = 'ParticipantName5 Already selected';
        this.textMessage = 'This Participant Name Already selected';
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: 'This Participant Name Already selected',
        });
      }
    }
  }

  sameparticipantnamevalidation() {
    const multicheckhash: IHash = {};
    this.isparticipantnamevalid = true;
    if (this.participantform.get('ParticipantName5').value === '') {
      this.participantform.get('ParticipantPercent5').reset(null);
      this.participantform.get('FeePercent5').reset(null);
    } else {
      if (this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName5').value === this.participantform.get('ParticipantName4').value) {
        multicheckhash.false = 'This Participant Name Already selected';
      } else {
        multicheckhash.true = 'true';
      }
    }
    if (this.participantform.get('ParticipantName4').value === '') {
      this.participantform.get('ParticipantPercent4').reset(null);
      this.participantform.get('FeePercent4').reset(null);
    } else {
      if (this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName4').value === this.participantform.get('ParticipantName5').value) {
        multicheckhash.false = 'This Participant Name Already selected';
      } else {
        multicheckhash.true = 'true';
      }
    }
    if (this.participantform.get('ParticipantName3').value === '') {
      this.participantform.get('ParticipantPercent3').reset(null);
      this.participantform.get('FeePercent3').reset(null);
    } else {
      if (this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName4').value
        || this.participantform.get('ParticipantName3').value === this.participantform.get('ParticipantName5').value) {
        multicheckhash.false = 'This Participant Name Already selected';
      } else {
        multicheckhash.true = 'true';
      }
    }
    if (this.participantform.get('ParticipantName2').value === '') {
      this.participantform.get('ParticipantPercent2').reset(null);
      this.participantform.get('FeePercent2').reset(null);
    } else {
      if (this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName1').value
        || this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName4').value
        || this.participantform.get('ParticipantName2').value === this.participantform.get('ParticipantName5').value) {
        multicheckhash.false = 'This Participant Name Already selected';
      } else {
        multicheckhash.true = 'true';
      }
    }
    if (this.participantform.get('ParticipantName1').value === '') {
      this.participantform.get('ParticipantPercent1').reset(null);
      this.participantform.get('FeePercent1').reset(null);
    } else {
      if (this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName2').value
        || this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName3').value
        || this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName4').value
        || this.participantform.get('ParticipantName1').value === this.participantform.get('ParticipantName5').value) {
        multicheckhash.false = 'This Participant Name Already selected';
      } else {
        multicheckhash.true = 'true';
      }
    }
    for (const key in multicheckhash) {
      if (key === 'false') {
        this.isparticipantnamevalid = false;
        this.summary = 'Form is Invalid';
        this.textMessage = multicheckhash[key];
      }
    }
  }


  checkvalidations() {
    const multicheckhash: IHash = {};
    this.isformvalid = true;
    if (this.participantform.get('ParticipantName1').value !== ''
      || this.participantform.get('ParticipantName2').value !== ''
      || this.participantform.get('ParticipantName3').value !== ''
      || this.participantform.get('ParticipantName4').value !== ''
      || this.participantform.get('ParticipantName5').value !== ''
      || this.participantform.get('ParticipantPercent1').value != null
      || this.participantform.get('ParticipantPercent2').value != null
      || this.participantform.get('ParticipantPercent3').value != null
      || this.participantform.get('ParticipantPercent4').value != null
      || this.participantform.get('ParticipantPercent5').value != null
      || this.participantform.get('FeePercent1').value != null
      || this.participantform.get('FeePercent2').value != null
      || this.participantform.get('FeePercent3').value != null
      || this.participantform.get('FeePercent4').value != null
      || this.participantform.get('FeePercent5').value != null
    ) {
      if ((this.participantform.get('ParticipantName5').value !== ''
          && this.participantform.get('ParticipantName5').value != null)
        || (this.participantform.get('FeePercent5').value != null
          && this.participantform.get('FeePercent5').value !== '')
        || (this.participantform.get('ParticipantPercent5').value != null
          && this.participantform.get('ParticipantPercent5').value !== '')) {
        if (this.participantform.get('ParticipantName5').value !== ''
          && this.participantform.get('ParticipantName5').value !== null) {
          if (this.participantform.get('ParticipantPercent5').value != null
            && this.participantform.get('ParticipantPercent5').value !== '') {
            if (this.participantform.get('ParticipantPercent5').value >= 0 &&
              this.participantform.get('ParticipantPercent5').value <= 100) {
              if (this.participantform.get('FeePercent5').value != null
                && this.participantform.get('FeePercent5').value !== '') {
                if (this.participantform.get('FeePercent5').value >= 0 &&
                  this.participantform.get('FeePercent5').value <= 100) {
                  multicheckhash.true = 'true';
                } else {
                  multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
                }
              } else {
                multicheckhash.false = 'Fee Percentage 5 is required';
              }
            } else {
              multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
            }
          } else {
            multicheckhash.false = 'Participant Percent 5 is required';
          }
        } else {
          multicheckhash.false = 'Participant Name 5 is missing';
        }
      }

      if ((this.participantform.get('ParticipantName4').value !== ''
          && this.participantform.get('ParticipantName4').value != null)
        || (this.participantform.get('FeePercent4').value != null
          && this.participantform.get('FeePercent4').value !== '')
        || (this.participantform.get('ParticipantPercent4').value != null
          && this.participantform.get('ParticipantPercent4').value !== '')) {
        if (this.participantform.get('ParticipantName4').value !== ''
          && this.participantform.get('ParticipantName4').value !== null) {
          if (this.participantform.get('ParticipantPercent4').value != null
            && this.participantform.get('ParticipantPercent4').value !== '') {
            if (this.participantform.get('ParticipantPercent4').value >= 0 &&
              this.participantform.get('ParticipantPercent4').value <= 100) {
              if (this.participantform.get('FeePercent4').value != null
                && this.participantform.get('FeePercent4').value !== '') {
                if (this.participantform.get('FeePercent4').value >= 0 &&
                  this.participantform.get('FeePercent4').value <= 100) {
                  multicheckhash.true = 'true';
                } else {
                  multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
                }
              } else {
                multicheckhash.false = 'Fee Percentage 4 is required';
              }
            } else {
              multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
            }
          } else {
            multicheckhash.false = 'Participant Percent 4 is required';
          }
        } else {
          multicheckhash.false = 'Participant Name 4 is missing';
        }
      }
      if ((this.participantform.get('ParticipantName3').value !== ''
          && this.participantform.get('ParticipantName3').value != null)
        || (this.participantform.get('FeePercent3').value != null
          && this.participantform.get('FeePercent3').value !== '')
        || (this.participantform.get('ParticipantPercent3').value != null
          && this.participantform.get('ParticipantPercent3').value !== '')) {
        if (this.participantform.get('ParticipantName3').value !== ''
          && this.participantform.get('ParticipantName3').value !== null) {
          if (this.participantform.get('ParticipantPercent3').value != null
            && this.participantform.get('ParticipantPercent3').value !== '') {
            if (this.participantform.get('ParticipantPercent3').value >= 0 &&
              this.participantform.get('ParticipantPercent3').value <= 100) {
              if (this.participantform.get('FeePercent3').value != null
                && this.participantform.get('FeePercent3').value !== '') {
                if (this.participantform.get('FeePercent3').value >= 0 &&
                  this.participantform.get('FeePercent3').value <= 100) {
                  multicheckhash.true = 'true';
                } else {
                  multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
                }
              } else {
                multicheckhash.false = 'Fee Percentage 3 is required';
              }
            } else {
              multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
            }
          } else {
            multicheckhash.false = 'Participant Percent 3 is required';
          }
        } else {
          multicheckhash.false = 'Participant Name 3 is missing';
        }
      }

      if ((this.participantform.get('ParticipantName2').value !== ''
          && this.participantform.get('ParticipantName2').value != null)
        || (this.participantform.get('FeePercent2').value != null
          && this.participantform.get('FeePercent2').value !== '')
        || (this.participantform.get('ParticipantPercent2').value != null
          && this.participantform.get('ParticipantPercent2').value !== '')) {
        if (this.participantform.get('ParticipantName2').value !== ''
          && this.participantform.get('ParticipantName2').value !== null) {
          if (this.participantform.get('ParticipantPercent2').value != null
            && this.participantform.get('ParticipantPercent2').value !== '') {
            if (this.participantform.get('ParticipantPercent2').value >= 0 &&
              this.participantform.get('ParticipantPercent2').value <= 100) {
              if (this.participantform.get('FeePercent2').value != null
                && this.participantform.get('FeePercent2').value !== '') {
                if (this.participantform.get('FeePercent2').value >= 0 &&
                  this.participantform.get('FeePercent2').value <= 100) {
                  multicheckhash.true = 'true';
                } else {
                  multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
                }
              } else {
                multicheckhash.false = 'Fee Percentage 2 is required';
              }
            } else {
              multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
            }
          } else {
            multicheckhash.false = 'Participant Percent 2 is required';
          }
        } else {
          multicheckhash.false = 'Participant Name 2 is missing';
        }
      }
      if ((this.participantform.get('ParticipantName1').value !== ''
          && this.participantform.get('ParticipantName1').value != null)
        || (this.participantform.get('FeePercent1').value != null
          && this.participantform.get('FeePercent1').value !== '')
        || (this.participantform.get('ParticipantPercent1').value != null
          && this.participantform.get('ParticipantPercent1').value !== '')) {
        if (this.participantform.get('ParticipantName1').value !== ''
          && this.participantform.get('ParticipantName1').value != null) {
          if (this.participantform.get('ParticipantPercent1').value != null
            && this.participantform.get('ParticipantPercent1').value !== '') {
            if (this.participantform.get('ParticipantPercent1').value >= 0 &&
              this.participantform.get('ParticipantPercent1').value <= 100) {
              if (this.participantform.get('FeePercent1').value != null
                && this.participantform.get('FeePercent1').value !== '') {
                if (this.participantform.get('FeePercent1').value >= 0 &&
                  this.participantform.get('FeePercent1').value <= 100) {
                  multicheckhash.true = 'true';
                } else {
                  multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
                }
              } else {
                multicheckhash.false = 'Fee Percentage 1 is required';
              }
            } else {
              multicheckhash.false = 'The decimal field\'s precision is too small to accept the numeric you attempted to add';
            }
          } else {
            multicheckhash.false = 'Participant Percent 1 is required';
          }
        } else {
          multicheckhash.false = 'Participant Name 1 is missing';
        }
      }

      for (const key in multicheckhash) {
        if (key === 'false') {
          this.isformvalid = false;
          this.summary = 'Form is Invalid';
          this.textMessage = multicheckhash[key];
        }
      }
    } else {
      this.isformvalid = false;
      this.summary = 'Form is Invalid';
      this.textMessage = 'At least one Participant Name should be selected';
    }
  }
}


export interface IHash {
  [key: string]: string;
}

export interface IHashnumber {
  [key: string]: number;
}
