<div class="catalyst-footer fixed-bottom" style="z-index: auto;">
    <div class="container">
        <div class="row py-3">
            <div class="col-12 order-1 order-md-0">
                <p class="float-left order-1 order-md-0 pt-2 pt-md-0"> © 2021 Liberty Mutual | <span>Powered by <a
                            href="https://catalyst-catalog.lmig.com/#/" target="_blank">Catalyst</a></span></p>
                    <p class="float-right"><span> Environment: {{AppEnv}} | Version: {{AppVersion}}</span></p>
            </div>
        </div>
    </div>
</div>
