// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,

//   //APIEndpoint: 'https://xu6jnut6x7-vpce-02b2052bb9bf81b7a.execute-api.us-east-1.amazonaws.com/test/greetings/',
//   APIEndpoint: 'https://znzpfnau2b-vpce-02b2052bb9bf81b7a.execute-api.us-east-1.amazonaws.com/development/greetings/',
//   STSServer: 'https://login.microsoftonline.com/08a83339-90e7-49bf-9075-957ccd561bf1/v2.0',
//   authWellknownEndpoint: 'https://login.microsoftonline.com/08a83339-90e7-49bf-9075-957ccd561bf1/v2.0',
//   clientId: 'a0e96f72-389a-4315-8463-60aa4c2d1fa0',
//   scope: 'openid profile email a0e96f72-389a-4315-8463-60aa4c2d1fa0/.default',
//   responseType: 'id_token token',
//   maxIdTokenIatOffsetAllowedInSeconds: 600,
//   AppEnv: 'development',
//   AppVersion: '1.1.0',
//   Homepage: 'https://csobooking-dev.lmig.com',
//   PowerBI:'https://app.powerbi.com/groups/3ab6ad2f-6d74-473b-acf0-a3195290cc5d/list',

// };

export const environment = {
  production: false,

  //APIEndpoint: 'https://xu6jnut6x7-vpce-02b2052bb9bf81b7a.execute-api.us-east-1.amazonaws.com/test/greetings/',
  
  // APIEndpoint: 'https://znzpfnau2b-vpce-02b2052bb9bf81b7a.execute-api.us-east-1.amazonaws.com/development/greetings/',
  APIEndpoint: 'https://zp39q27u8g-vpce-02b2052bb9bf81b7a.execute-api.us-east-1.amazonaws.com/test/greetings/',
  STSServer: 'https://login.microsoftonline.com/08a83339-90e7-49bf-9075-957ccd561bf1/v2.0',
  authWellknownEndpoint: 'https://login.microsoftonline.com/08a83339-90e7-49bf-9075-957ccd561bf1/v2.0',
  clientId: '5eb1ee2d-55fa-4fde-b1c4-589a9cf6e552',
  scope: 'openid profile email 5eb1ee2d-55fa-4fde-b1c4-589a9cf6e552/.default',
  responseType: 'id_token token',
  maxIdTokenIatOffsetAllowedInSeconds: 600,
  AppEnv: 'develop',
  AppVersion: '1.1.0',
  Homepage: 'https://csobooking-staging.lmig.com',
  PowerBI:'https://app.powerbi.com/groups/3ab6ad2f-6d74-473b-acf0-a3195290cc5d/list',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
