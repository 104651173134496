<p-toast position="top-right">
  <ng-template let-message pTemplate="message">
    <div style="font-weight: bold;text-align: center; margin:0.2rem auto; font-size: 14px; padding: 2px; width: 87%">
      <span innerHtml="{{summary}}"></span>
    </div>
    <div style="text-align: center; font-size: 14px; padding: 2px; margin:0.2rem auto; width: 87%;">
      <span innerHtml="{{textMessage}}"></span>
    </div>
  </ng-template>
</p-toast>
<div class="container" id="addedit" style="font-size: small;margin-top: 10px;">

  <div *ngIf="loading$ | async" class="overlay">
    <div class="center">
      <mat-progress-spinner color="primary" diameter=80 mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </div>


  <div fxLayout="row">
    <mat-card style="width:100%;
   border-radius: 5px;
   box-shadow: 0px 1px 10px -1px;">

      <mat-card-header>
        <mat-card-title>PRIMARY INFO:</mat-card-title>
      </mat-card-header>
      <form [formGroup]="primaryinfoform" class="form" name="primaryinfoform">
        <mat-card-content>
          <table style="width:90%">
            <tr>
              <td>
                <mat-form-field style="top:-0.68em;width: 13rem;">
                  <input formControlName="TransactionID" id="TransactionID" matInput placeholder="Transaction ID"
                         type="text">
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="example-full-width">
                  <input [matAutocomplete]="trxntypeauto" aria-label="Number" formControlName="TxnType" matInput placeholder="Trx Type"
                         type="text">
                  <mat-autocomplete #trxntypeauto="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let option of trxntypefilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="example-full-width">
                  <input [matAutocomplete]="trxnsourceauto" aria-label="Number" formControlName="TxnSource" matInput placeholder="Trx Source"
                         type="text">
                  <mat-autocomplete #trxnsourceauto="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let option of trxnSourcefilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="example-full-width">
                  <input [matAutocomplete]="accountperiodauto" aria-label="Number" formControlName="AccountPeriod" matInput
                         placeholder="Acct Period" type="text">
                  <mat-autocomplete #accountperiodauto="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let option of accountperiodfilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="example-full-width">
                  <input [matAutocomplete]="sourceauto" aria-label="Number" formControlName="Source" matInput placeholder="Source"
                         type="text">
                  <mat-autocomplete #sourceauto="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let option of sourcefilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                <mat-form-field class="example-full-width">
                  <input aria-label="Number" formControlName="WhoRequested" matInput maxLength="50" placeholder="Who Requested"
                         type="text"> <!--[matAutocomplete]="whorequestedauto"-->
                  <!-- <mat-autocomplete autoActiveFirstOption #whorequestedauto="matAutocomplete">
                    <mat-option *ngFor="let option of whorequestedfilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete> -->
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="example-full-width">
                  <input aria-label="Number" formControlName="AccountName" matInput maxLength="255" placeholder="Account Name"
                         type="text"> <!--[matAutocomplete]="accountnameauto"-->
                  <!-- <mat-autocomplete autoActiveFirstOption #accountnameauto="matAutocomplete">
                    <mat-option *ngFor="let option of accountnamefilteredOptions | async" [value]="option"
                      matTooltip={{option}}>
                      {{option}}
                    </mat-option>
                  </mat-autocomplete> -->
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="example-full-width">
                  <input aria-label="Number" formControlName="Policy" matInput maxLength="15" placeholder="Policy #"
                         type="text">
                  <!--[matAutocomplete]="policynumberauto"-->
                  <!-- <mat-autocomplete autoActiveFirstOption #policynumberauto="matAutocomplete">
                    <mat-option *ngFor="let option of policynumberfilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete> -->
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="example-full-width">
                  <input [matAutocomplete]="codeauto" aria-label="Number" formControlName="ComplexityCode" matInput
                         placeholder="Complexity Code" type="text">
                  <mat-autocomplete #codeauto="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let option of codefilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                <mat-form-field class="example-full-width">
                  <input [matAutocomplete]="profitcenterauto" aria-label="Number" formControlName="ProfitCenter" matInput
                         placeholder="Profit Center" type="text">
                  <mat-autocomplete #profitcenterauto="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let option of profitcenterfilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="example-full-width">
                  <input [matAutocomplete]="isfacultativeauto" aria-label="Number" formControlName="Fac" matInput placeholder="Fac"
                         type="text">
                  <mat-autocomplete #isfacultativeauto="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let option of isfacultativefilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <td>
                <label>Eff Date</label>
                <!-- <mat-form-field style="top:-0.68em;width: 13.9rem;">
                  <input matInput [matDatepicker]="dp4" (click)="dp4.open()" formControlName="EffDate" id="EffDate"
                    placeholder="Eff Date">
                  <mat-datepicker-toggle matSuffix [for]="dp4"></mat-datepicker-toggle>
                  <mat-datepicker #dp4 disabled="false"></mat-datepicker>
                </mat-form-field> -->
                <kendo-datepicker
                  (valueChange)="datechange('EffDate', $event)" [format]="format1" [incompleteDateValidation]="true"
                  [min]="minDate" formControlName="EffDate"
                ></kendo-datepicker>
                <kendo-formerror *ngIf="primaryinfoform.controls['EffDate'].invalid">Eff Date is invalid!
                </kendo-formerror>

              </td>
              <td>
                <mat-form-field class="example-full-width">
                  <input [matAutocomplete]="isxolauto" aria-label="Number" formControlName="XOL" matInput placeholder="XOL"
                         type="text">
                  <mat-autocomplete #isxolauto="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let option of isxolfilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                <mat-form-field class="example-full-width">
                  <input [matAutocomplete]="IsIISResponsibleauto" aria-label="Number" formControlName="IISResponsible" matInput
                         placeholder="IIS Responsible" type="text">
                  <mat-autocomplete #IsIISResponsibleauto="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let option of iisresponsiblefilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="example-full-width">
                  <input [matAutocomplete]="reinsurancetypeauto" aria-label="Number" formControlName="Reins" matInput placeholder="Reins"
                         type="text">
                  <mat-autocomplete #reinsurancetypeauto="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let option of reinsurancetypefilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="example-full-width">
                  <input [matAutocomplete]="quotashareauto" aria-label="Number" formControlName="QuotaShare" matInput placeholder="Quota Share"
                         type="text">
                  <mat-autocomplete #quotashareauto="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let option of quotasharefilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="example-full-width">
                  <input [matAutocomplete]="issuingpaperlistauto" aria-label="Number" formControlName="IssuingPaper" matInput
                         placeholder="Issuing Paper" type="text">
                  <mat-autocomplete #issuingpaperlistauto="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let option of issuingpaperlistfilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
            </tr>
          </table>

        </mat-card-content>
      </form>
    </mat-card>
  </div>
  <br/>

  <div fxLayout="row" fxLayoutGap="30px">
    <div fxFlex="25%">
      <mat-card style="
     border-radius: 5px;
     box-shadow: 0px 1px 10px -1px;height: 100%;">
        <mat-card-header>
          <mat-card-title>MONETARY DETAILS:</mat-card-title>
        </mat-card-header>
        <form [formGroup]="monetarydetailsform" class="form" name="monetarydetailsform">
          <mat-card-content>
            <div class="row">
              <div class="childrow">
                <div>
                  <mat-form-field style="top:-0.68em;width: 13.9rem;">
                    <input (focusout)="limitfocusOutFunction()" formControlName="Limits" id="Limits" matInput
                           maxLength="15" placeholder="Limit">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="childrow">
                <div>
                  <mat-form-field style="top:-0.68em;width: 13.9rem;">
                    <input (focusout)="partoffocusOutFunction()" formControlName="PartOf" id="PartOf" matInput
                           maxLength="15" placeholder="Part of">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="childrow">
                <div>
                  <mat-form-field style="top:-0.68em;width: 13.9rem;">
                    <input (focusout)="attachmentfocusOutFunction()" formControlName="Attachment" id="Attachment"
                           matInput maxLength="15" placeholder="Attachment">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="childrow">
                <div>
                  <mat-form-field style="top:-0.68em;width: 13.9rem;">
                    <input (focusout)="trxamountfocusOutFunction()" (ngModelChange)="modelChanged($event)"
                           formControlName="TrxAmount"
                           id="TrxAmount" matInput maxLength="15" placeholder="Trx Amount">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="childrow">
                <div>
                  <mat-form-field style="top:-0.68em;width: 13.9rem;">
                    <input (focusout)="TaxesfocusOutFunction()" (ngModelChange)="taxesChanged($event)" formControlName="Taxes"
                           id="Taxes" matInput maxLength="9" placeholder="Tax">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="childrow">
                <div>
                  <mat-form-field style="top:-0.68em;width: 13.9rem;">
                    <input (focusout)="FeesfocusOutFunction()" (ngModelChange)="feesChanged($event)" formControlName="Fees"
                           id="Fees" matInput maxLength="9" placeholder="Fees">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="childrow">
                <div>
                  <mat-form-field style="top:-0.68em;width: 13.9rem;">
                    <input (focusout)="SurchargesfocusOutFunction()" (ngModelChange)="surchargesChanged($event)"
                           formControlName="Surcharges"
                           id="Surcharges" matInput maxLength="9" placeholder="Surcharges">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="childrow">
                <div>
                  <mat-form-field style="top:-0.68em;width: 13.9rem;">
                    <input formControlName="CalculatedTFS" id="CalculatedTFS"
                           matInput maxLength="9" placeholder="Calculated TFS">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="childrow">
                <div>
                  <mat-form-field style="top:-0.68em;width: 13.9rem;">
                    <input (focusout)="commissionfocusOutFunction()" (ngModelChange)="commissionmodelChanged($event)"
                           formControlName="Commission"
                           id="Commission" matInput maxLength="6" placeholder="Commision (%)">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="childrow">
                <div>
                  <mat-form-field style="top:-0.68em;width: 13.9rem;">
                    <input formControlName="CommissionD" id="CommissionD" matInput
                           maxLength="9" placeholder="Commision ($)">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="childrow">
                <div>
                  <mat-form-field style="top:-0.68em;width: 13.9rem;">
                    <input (focusout)="writtenlinefocusOutFunction()" formControlName="WrittenLine" id="WrittenLine"
                           matInput
                           maxLength="9" placeholder="Written Line (%)">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="childrow">
                <mat-form-field class="example-full-width">
                  <input [matAutocomplete]="currencyauto" aria-label="Number" formControlName="Currency" matInput placeholder="Currency"
                         type="text">
                  <mat-autocomplete #currencyauto="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let option of currencyfilteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </form>
      </mat-card>
    </div>
    <div fxFlex>
      <div fxLayout="row">
        <mat-card style="width:100%;
       border-radius: 5px;
       box-shadow: 0px 1px 10px -1px;">
          <mat-card-header>
            <mat-card-title>PARTICIPANT DETAILS (at least one participant details is required):</mat-card-title>
          </mat-card-header>
          <form [formGroup]="participantform" class="form" name="participantform">
            <mat-card-content>
              <table>
                <tbody>
                <tr>
                  <td class="childrow">
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <mat-form-field class="example-full-width">
                          <input [matAutocomplete]="participantname1auto" aria-label="Number" formControlName="ParticipantName1" matInput
                                 placeholder="Participant Name 1" type="text">
                          <mat-autocomplete #participantname1auto="matAutocomplete" (optionSelected)="perticipantname1focusOutFunction()"
                                            autoActiveFirstOption>
                            <mat-option *ngFor="let option of participantname1filteredOptions | async"
                                        [value]="option">
                              {{option}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <div>
                          <mat-form-field style="width: 13.9rem;">
                            <input (focusout)="percentage1focusOutFunction()" formControlName="ParticipantPercent1"
                                   id="ParticipantPercent1"
                                   matInput maxLength="8" placeholder="Participant (%)">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <div>
                          <mat-form-field style="width: 13.9rem;">
                            <input (focusout)="fee1focusOutFunction()" formControlName="FeePercent1" id="FeePercent1"
                                   matInput
                                   maxLength="9" placeholder="Fee (%)">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <mat-form-field class="example-full-width">
                          <input (selectionChange)="changeState()" [matAutocomplete]="participantname2auto" aria-label="Number" formControlName="ParticipantName2"
                                 matInput placeholder="Participant Name 2"
                                 type="text">
                          <mat-autocomplete #participantname2auto="matAutocomplete" (optionSelected)="perticipantname2focusOutFunction()"
                                            autoActiveFirstOption>
                            <mat-option *ngFor="let option of participantname2filteredOptions | async"
                                        [value]="option">
                              {{option}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <div>
                          <mat-form-field style="width: 13.9rem;">
                            <input (focusout)="percentage2focusOutFunction()" formControlName="ParticipantPercent2"
                                   id="ParticipantPercent2"
                                   matInput maxLength="8" placeholder="Participant (%)">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <div>
                          <mat-form-field style="width: 13.9rem;">
                            <input (focusout)="fee2focusOutFunction()" formControlName="FeePercent2" id="FeePercent2"
                                   matInput
                                   maxLength="9" placeholder="Fee (%)">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <mat-form-field class="example-full-width">
                          <input (selectionChange)="changeState()" [matAutocomplete]="participantname3auto" aria-label="Number"
                                 formControlName="ParticipantName3" matInput placeholder="Participant Name 3"
                                 type="text">
                          <mat-autocomplete #participantname3auto="matAutocomplete" (optionSelected)="perticipantname3focusOutFunction()"
                                            autoActiveFirstOption>
                            <mat-option *ngFor="let option of participantname3filteredOptions | async"
                                        [value]="option">
                              {{option}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <div>
                          <mat-form-field style="width: 13.9rem;">
                            <input (focusout)="percentage3focusOutFunction()" formControlName="ParticipantPercent3"
                                   id="ParticipantPercent3"
                                   matInput maxLength="8" placeholder="Participant (%)">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <div>
                          <mat-form-field style="width: 13.9rem;">
                            <input (focusout)="fee3focusOutFunction()" formControlName="FeePercent3" id="FeePercent3"
                                   matInput
                                   maxLength="9" placeholder="Fee (%)">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <mat-form-field class="example-full-width">
                          <input (selectionChange)="changeState()" [matAutocomplete]="participantname4auto" aria-label="Number"
                                 formControlName="ParticipantName4" matInput placeholder="Participant Name 4"
                                 type="text">
                          <mat-autocomplete #participantname4auto="matAutocomplete" (optionSelected)="perticipantname4focusOutFunction()"
                                            autoActiveFirstOption>
                            <mat-option *ngFor="let option of participantname4filteredOptions | async"
                                        [value]="option">
                              {{option}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <div>
                          <mat-form-field style="width: 13.9rem;">
                            <input (focusout)="percentage4focusOutFunction()" formControlName="ParticipantPercent4"
                                   id="ParticipantPercent4"
                                   matInput maxLength="8" placeholder="Participant (%)">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <div>
                          <mat-form-field style="width: 13.9rem;">
                            <input (focusout)="fee4focusOutFunction()" formControlName="FeePercent4" id="FeePercent4"
                                   matInput
                                   maxLength="9" placeholder="Fee (%)">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <mat-form-field class="example-full-width">
                          <input (selectionChange)="changeState()" [matAutocomplete]="participantname5auto" aria-label="Number"
                                 formControlName="ParticipantName5" matInput placeholder="Participant Name 5"
                                 type="text">
                          <mat-autocomplete #participantname5auto="matAutocomplete" (optionSelected)="perticipantname5focusOutFunction()"
                                            autoActiveFirstOption>
                            <mat-option *ngFor="let option of participantname5filteredOptions | async"
                                        [value]="option">
                              {{option}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <div>
                          <mat-form-field style="width: 13.9rem;">
                            <input (focusout)="percentage5focusOutFunction()" formControlName="ParticipantPercent5"
                                   id="ParticipantPercent5"
                                   matInput maxLength="8" placeholder="Participant (%)">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="childrow">
                      <div class="col-8 col-md-6">
                        <div>
                          <mat-form-field style="width: 13.9rem;">
                            <input (focusout)="fee5focusOutFunction()" formControlName="FeePercent5" id="FeePercent5"
                                   matInput
                                   maxLength="9" placeholder="Fee (%)">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </mat-card-content>
          </form>
        </mat-card>
      </div>
      <br/>
      <div fxLayout="row" fxLayoutGap="30px">
        <div fxFlex="40%">
          <mat-card style="
          border-radius: 5px;
          box-shadow: 0px 1px 10px -1px;">
            <mat-card-header>
              <mat-card-title>TRANSMISSION INFO:</mat-card-title>
            </mat-card-header>
            <form [formGroup]="mfxtransmissionform" class="form" name="mfxtransmissionform">
              <mat-card-content>
                <table>
                  <thead></thead>
                  <tbody>
                  <tr>
                    <td>
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field style="width:100%">
                            <input [matAutocomplete]="trxstatusauto" aria-label="Number" formControlName="TrxnStatus" matInput
                                   placeholder="Trx Status" type="text">
                            <mat-autocomplete #trxstatusauto="matAutocomplete" autoActiveFirstOption>
                              <mat-option *ngFor="let option of trxstatusfilteredOptions | async" [value]="option"
                                          matTooltip={{option}}>
                                {{option}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label style="margin-top: 1em;">Received to Booking</label>
                      <!-- <mat-form-field style="top:-0.68em;width: 13.9rem;">
                        <input formControlName="ReceivedtoBooking" matInput [matDatepicker]="dprecevied"
                          (click)="dprecevied.open()" id="ReceivedtoBooking" placeholder="Received to Booking">
                        <mat-datepicker-toggle matSuffix [for]="dprecevied"></mat-datepicker-toggle>
                        <mat-datepicker #dprecevied disabled="false"></mat-datepicker>
                      </mat-form-field> -->
                      <kendo-datetimepicker (valueChange)="datechange('ReceivedtoBooking', $event)"
                                            [format]="format" [incompleteDateValidation]="true"
                                            [min]="minDate" formControlName="ReceivedtoBooking">
                      </kendo-datetimepicker>
                      <kendo-formerror *ngIf="mfxtransmissionform.controls['ReceivedtoBooking'].invalid">Received to
                        Booking is invalid!
                      </kendo-formerror>
                    </td>
                    <!-- </div>
                  </div>
                </div> -->
                  </tr>
                  <tr>
                    <!--td><input style="width:180px;height:30px;"></td-->
                    <!-- <div class="row">
                      <div class="col-8 col-md-6">
                        <div> -->
                    <td>
                      <label style="margin-top: 2em;">Completed Date</label>
                      <!-- <mat-form-field style="top:-0.68em;width: 13.9rem;">
                        <input matInput [matDatepicker]="dpcompleted" (click)="dpcompleted.open()"
                          formControlName="WhenRcvdFromMFX" id="WhenRcvdFromMFX" placeholder="Completed Date">
                        <mat-datepicker-toggle matSuffix [for]="dpcompleted"></mat-datepicker-toggle>
                        <mat-datepicker #dpcompleted disabled="false"></mat-datepicker>
                      </mat-form-field> -->
                      <kendo-datetimepicker (valueChange)="datechange('WhenRcvdFromMFX', $event)"
                                            [format]="format" [incompleteDateValidation]="true"
                                            [min]="minDate" formControlName="WhenRcvdFromMFX"
                                            style="margin-left: 2.2em;">
                      </kendo-datetimepicker>
                      <kendo-formerror *ngIf="mfxtransmissionform.controls['WhenRcvdFromMFX'].invalid">Completed Date is
                        invalid!
                      </kendo-formerror>
                    </td>
                    <!-- </div>
                  </div>
               </div> -->
                  </tr>
                  <tr>
                    <!--td><input style="width:180px;height:30px;"></td-->
                    <!-- <div class="row">
                      <div class="col-8 col-md-6">
                        <div> -->
                    <td>
                      <label style="margin-top: 2em;">When Reconciled</label>
                      <!-- <mat-form-field style="top:-0.68em;width: 13.9rem;">
                        <input formControlName="WhenReconciled" id="WhenReconciled" matInput [matDatepicker]="dp1"
                          (click)="dp1.open()" placeholder="When Reconciled">
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                      </mat-form-field> -->
                      <kendo-datetimepicker (valueChange)="datechange('WhenReconciled', $event)"
                                            [format]="format" [incompleteDateValidation]="true"
                                            [min]="minDate" formControlName="WhenReconciled"
                                            style="margin-left: 1.9em;">
                      </kendo-datetimepicker>
                      <kendo-formerror *ngIf="mfxtransmissionform.controls['WhenReconciled'].invalid">When Reconciled is
                        invalid!
                      </kendo-formerror>
                    </td>
                    <!-- </div>
                  </div>
                </div> -->
                  </tr>
                  <tr>
                    <!--td>Recon Status</td-->
                    <!--&nbsp;&nbsp;-->
                    <!--td><input style="width:180px;height:30px;"></td-->
                    <div class="row">
                      <div class="col-8 col-md-6">
                        <div>
                          <!--class="form-group"-->
                          <mat-form-field style="width:18.9em;">
                            <input formControlName="ReconciliationStatus" id="ReconciliationStatus" matInput
                                   maxLength="25"
                                   placeholder="Recon Status">
                          </mat-form-field>
                          <!--input style="width: 190px; height: 45px;" autocomplete="disabled" class="form-control" id="insuredName" name="insuredName" placeholder=" " type="text"-->
                        </div>
                      </div>
                    </div>
                  </tr>
                  </tbody>

                </table>
              </mat-card-content>
            </form>
          </mat-card>
        </div>
        <div fxFlex>
          <mat-card style="
   border-radius: 5px;
   box-shadow: 0px 1px 10px -1px;height: 100%;">

            <mat-card-header>
              <mat-card-title>UPDATE INFO:</mat-card-title>
            </mat-card-header>
            <form [formGroup]="updateinfoform" class="form" name="updateinfoform">
              <mat-card-content style="margin: 15px; margin-left: 15px; margin-bottom: 5px;">
                <div class="row">
                  <div class="col-8 col-md-6">
                    <div>
                      <mat-form-field style="top:-0.68em;width: 13.9rem;">
                        <input formControlName="WhoCreated" id="WhoCreated" matInput placeholder="Who Created">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8 col-md-6">
                    <div>
                      <mat-form-field style="top:-0.68em;width: 13.9rem;">
                        <input formControlName="WhenCreated" id="WhenCreated" matInput placeholder="When Created">
                        <!--matInput [matDatepicker]="dp2"
                          (click)="dp2.open()"
                         <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                        <mat-datepicker #dp2 disabled="true"></mat-datepicker> -->
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8 col-md-6">
                    <div>
                      <mat-form-field style="top:-0.68em;width: 13.9rem;">
                        <input formControlName="WhoUpdated" id="WhoUpdated" matInput placeholder="Who Updated">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8 col-md-6">
                    <div>
                      <mat-form-field style="top:-0.68em;width: 13.9rem;">
                        <input formControlName="WhenUpdated" id="WhenUpdated" matInput placeholder="When Updated">
                        <!-- matInput [matDatepicker]="dp3"
                          (click)="dp3.open()"
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3 disabled="true"></mat-datepicker-->
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </form>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <br/>
  <div fxLayout="row">
    <mat-card style="width: 100%; display: flex;
      border-radius: 5px;
      box-shadow: 0px 1px 10px -1px;
      ">
      <form [formGroup]="glupdateform" class="form" name="glupdateform" style="width: 100%;">
        <mat-card-content style="width:100%">
          <mat-form-field style="width:100%;">
            <textarea formControlName="Notes" id="Notes" matInput maxLength="255" placeholder="General Notes" rows="3"
                      style="width:100%"></textarea>
          </mat-form-field>
        </mat-card-content>
      </form>
    </mat-card>
  </div>
  <br/>
</div>
