<header class="catalyst-header">
    <nav class="navbar navbar-expand-lg navbar-light fixed-top bg-primary catalyst-brand-header"
        [ngClass]="{ 'expanded': menuOpen }">
        <div style="width: 100%;">

            <div fxLayout="row" fxLayoutAlign="center" fxFlexFill>
                <div fxLayoutAlign="start">
                    <a class="navbar-brand">
                        <img src="assets/images/LMI_H_BLUE_RGB.svg" alt="Liberty Mutual Logo"
                            class="d-inline-block align-top liberty-logo">
                    </a>
                </div>
                <div fxLayout="column" fxLayoutAlign="center">
                    <div>
                        <font size="+1"><b>CSO Booking Log</b></font>
                    </div>
                    <div style="vertical-align: middle;">
                        <nav *ngIf="isshow">
                            <ol class="breadcrumb" style="height: 10px; vertical-align: top; font-size: xx-small;">
                                <li class="breadcrumb-item"><a (click)="home()" style="color:rgb(14, 14, 14)">Home</a>
                                </li>
                                <li class="breadcrumb-item" style="color:rgb(14, 14, 14);"><a
                                        style="color:rgb(14, 14, 14);">Transactions</a></li>
                            </ol>
                        </nav>
                        <nav aria-label="breadcrumb" *ngIf="istransactionshow">
                            <ol class="breadcrumb" style="height: 10px; vertical-align: top; font-size: xx-small;">
                                <li class="breadcrumb-item"><a (click)="home()" style="color:rgb(14, 14, 14);">Home</a>
                                </li>
                                <li class="breadcrumb-item"><a (click)="transactions()"
                                        style="color:rgb(14, 14, 14);">Transactions</a></li>
                                <li class="breadcrumb-item" style="color:rgb(14, 14, 14);"><a
                                        style="color:rgb(14, 14, 14);">Open</a></li>
                            </ol>
                        </nav>
                        <nav aria-label="breadcrumb" *ngIf="isusermaintenanceshow">
                            <ol class="breadcrumb" style="height: 10px; vertical-align: top; font-size: xx-small;">
                                <li class="breadcrumb-item"><a (click)="home()" style="color:rgb(14, 14, 14);">Home</a>
                                </li>
                                <li class="breadcrumb-item" style="color:rgb(14, 14, 14);"><a
                                        style="color:rgb(14, 14, 14);">User Maintenance</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    <div fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="end">
                            <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="toggleMenu()">
                                <!-- <i class="fa fa-fw fa-bars" [ngClass]="{ 'fa-times': menuOpen }"></i> -->
                                <span class="k-icon k-font-icon k-i-more-vertical k-i-more-v k-i-vbars" [ngClass]="{ 'k-i-times': menuOpen }"></span>
                            </button>
                            <div class="collapse navbar-collapse" [ngClass]="{ 'show': menuOpen }"
                                id="navbarNavDropdown" *ngIf="isAuthenticated">
                                <p-menubar styleClass="ui-menubar" [model]="menuItems"></p-menubar>
                                <!-- <ul class="navbar-nav ml-auto" *ngIf="menuItems?.length > 0">
                                    <li class="nav-item" *ngFor="let subItem of menuItems" style="padding-left: 10px; padding-right: 5px;">
                                        <a (click)="subItem.command()">
                                            {{subItem.label}}
                                        </a>
                                    </li>
                                </ul> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </nav>
</header>
<div id="menu-fade" [ngClass]="{'visible': menuOpen}" (click)="toggleMenu()"></div>