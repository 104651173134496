import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  IsAdmin: string;

  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    this.IsAdmin = sessionStorage.getItem('IsAdmin');
    if (this.IsAdmin === '1') {
      return true;
    } else {
        this.router.navigate(['home'])
      return false;
    }
  }

}
