<p-toast position="top-right"></p-toast>
<ng-container>
    <user-conf-dialog   [actionType]="actionType" 
        (toggleUserDialog)="toggleUserDialog()"></user-conf-dialog>
</ng-container>
<div  id="usermaintenance" class="container" style="font-size: small;margin-top: 10px;">

    <div class="overlay" *ngIf="loading$ | async">
        <div class="center">
          <mat-progress-spinner diameter=80 mode="indeterminate" color="primary">
          </mat-progress-spinner>
        </div>
      </div>
    
    <mat-expansion-panel [expanded]="true" class="mt-4">
        <mat-expansion-panel-header style="height:50px;">
            <mat-panel-title
                style=" padding: 25px; width: 100%;color: black; font-weight: bold;height:50px;margin-bottom: 15px;">
                <span *ngIf="!advDetailsFlag">User Maintenance</span>
                <span *ngIf="advDetailsFlag"> {{actionType}} User </span>
            </mat-panel-title>
    
        </mat-expansion-panel-header>
        <div class="wrapper">
            <fieldset class="card p-lg-2 p-md-4 p-" id="section-name">
    
                <div class="fields card-body">
                    <div class="row">
                        <kendo-label style="margin-right:12px;  " [for]="FirstName" class="k-label" text="First Name : "
                            [ngClass]="{'required ' : advDetailsFlag}">
                        </kendo-label>
                        <kendo-textbox #FirstName name="firstNameTxt" [(ngModel)]="firstNameTxt"
                         maxlength="100" (keyup)="checkRequired()" (keyup.enter)="filterRecord()"></kendo-textbox>
                         <button *ngIf="!advDetailsFlag" style="margin-left: 28px;width:70px"
                         (click)="filterRecord()" kendoButton>Search</button>
                    </div>
                    <div class="row mt-2 col-md-12"></div>
                    <div class="row">
                        <kendo-label style="margin-right:12px; color: red;" [for]="LastName" class="k-label font-style" text="Last Name : "
                            [ngClass]="{'required' : advDetailsFlag}">
                        </kendo-label>
                        <kendo-textbox #LastName name="lastNameTxt" [(ngModel)]="lastNameTxt" 
                        (keyup)="checkRequired()" (keyup.enter)="filterRecord()" style="margin-right: 30px; "
                            maxlength="100"></kendo-textbox>
                    <button *ngIf="!advDetailsFlag" style="margin-right: 16px;width:70px" 
                    (click)="onCancelClick()" kendoButton>Reset</button>
                    </div>
                    <div class="row mt-2 col-md-12"></div>
                    <div class="row">
                        <kendo-label [for]="nnumber" class="k-label" style="margin-right:16px;" text="N Number  :  "
                            [ngClass]="{'required' : advDetailsFlag}"></kendo-label>
                        <kendo-textbox #nnumber name="nNumberTxt" [(ngModel)]="nNumberTxt" style="margin-right: 30px;"
                            maxlength="10" (keyup)="checkRequired()"  (keyup.enter)="filterRecord()"></kendo-textbox>
                        <button *ngIf="!advDetailsFlag" style="margin-right: 16px;width:70px;" kendoButton
                            (click)="onAddClick()"> Add </button>                  
                    </div>
                    <div class="row mt-2 col-md-12"></div>
                    <!-- <div class="row">
                        <kendo-label [ngStyle]="{'margin-right': advDetailsFlag == false ? '13px;' : '22px;' }"
                            class="k-label" text="Domain ID  :  "></kendo-label>
                        <kendo-textbox #domain style="margin-right: 30px;" maxlength="50">
                        </kendo-textbox>
                    </div> -->
    
                </div>
                <div class="row mt-1 col-md-12"></div>
                <div class="fields card-body mb50" *ngIf="!advDetailsFlag">
                    <legend class="col-form-label" style="margin-bottom: 1rem;">Search Results</legend>
                    <kendo-grid #gridUser 
                        [data]="GridPolicyData"
                        [group]="group"
                          [skip]="state.skip"
                          [sort]="state.sort"
                          [filter]="state.filter"
                          [pageable]="true"
                          [pageSize]="50"
                          [sortable]="true"
                          [filterable]="true"
                          [reorderable]="true"
                          [selectable]="selectableSettings"
                          [resizable]="true"  
                          (dataStateChange)="dataStateChange($event)"
                               [height]="600" kendoGridSelectBy="NNumber"
                                  [selectedKeys]="mySelection"
                                  (cellClick)="onCellClick($event)" (dblclick)="onDblClick($event)"
                                  >
    
                        
    
                            <ng-template kendoGridToolbarTemplate>
                                <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
                            </ng-template>
    
    
                            <kendo-grid-column *ngFor="let column of grid" field="{{column.field}}" class="gridcolor"
                            title="{{column.title}}"   width="{{column.width < 70? '70': column.width}}"
                                 editor="{{column.type}}"  format="{{column.format}}" filter="{{column.type}}"
                                 [headerStyle]="{'background-color': '#666','color': '#fff'}">
                             </kendo-grid-column>
                             <kendo-grid-excel [fetchData]="allData" fileName="UserDetail.xlsx">
                            </kendo-grid-excel> 
    
                            <ng-template kendoGridNoRecordsTemplate>
    
                            </ng-template>
                        </kendo-grid>
    
                </div>
    
                <div class="row col-md-12 mb50" *ngIf="advDetailsFlag">
                    
                    <div class="fields card-body AddUser mr300 ">
    
                        <div class="panel panel-default  bordershadow" style="width: 230px; margin-left: 320px;margin-top: -165px;">
                            
    
                            <div class="panel-heading required">Roles</div>
                            <div class="panel-body bordershadow-inner fondsige">
                                <ul class="k-radio-list fondsige">
                                    <li class="k-radio-item" *ngFor="let d of roleTypeList;index as indexele;">
                                        <input type="radio" [id]="d" [value]="d.id" [(ngModel)]="roleSelected"
                                            kendoRadioButton (change)="checkRequired()" />
                                        <kendo-label class="k-radio-label" [for]="d" [text]="d.name"></kendo-label>
                                    </li>
                                </ul>
                            </div>
    
                            <div class="admin-checkbox fondsige"> <input type="checkbox" #admin [(ngModel)]="adminSelected"
                                     kendoCheckBox  (change)="checkRequired()"/> 
                                <kendo-label class="k-checkbox-label" [for]="admin" text="Admin"></kendo-label>
                            </div>
                        </div>
    
                        <div class="panel panel-default  bordershadow"
                            style="width: 230px; margin-top: -150px;margin-left: 100px;height: 100px;">
    
                            <div class="wrap " *ngIf="advDetailsFlag">
                                <label class="k-checkbox-label" for="activeCheck">Active</label>
                                <input type="checkbox" name="activeCheckBox" id="activeCheck" [(ngModel)]="activeCheck"
                                    kendoCheckBox />
    
    
                            </div>
                            <button  class="mr50 mt-2 ml-1 text-bold" name="addUserBtn" [disabled]="!isSubmitted"
                                id="addUserBtn" kendoButton  (click)="addUpdateUser($event)"> {{actionType =="Add"? "Add"
                                :"Update" }} </button>
                            <button id="cancelBtn" class="text-bold mt-2" name="cancelBtn" (click)="onCancelClick()" kendoButton >
                                Cancel </button>
                        </div>
                    </div>
                    
    
                </div>
    
    
            </fieldset>
        </div>
    </mat-expansion-panel>

</div>


