import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './example-product-routes';

// Angular AOT compatibility
export const childRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);

@NgModule({
  imports: [childRoutingModule],
  exports: [RouterModule]
})
export class ExampleProductRoutingModule { }
