import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NavigationService} from 'src/app/services/navigation.service';
import {ApiService} from 'src/app/services/api.service';
import {SelectionModel} from '@angular/cdk/collections';
import {Transactionsgrid} from 'src/app/components/Models/transactionsgrid.model';
import {GenericResponse} from 'src/app/components/Models/generic-response.model';
import {GenericResponse2} from 'src/app/components/Models/genericresponse2.model';
import {Transactions} from 'src/app/components/Models/transactions.model';
import {BehaviorSubject} from 'rxjs';
import {DatePipe, DecimalPipe} from '@angular/common';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {isNull} from 'util';
// Kendo imports
import {ColumnReorderEvent, GridDataResult, SelectableSettings} from '@progress/kendo-angular-grid';
import {State} from '@progress/kendo-data-query';
import {FilterState, GridColumn} from 'src/app/components/Models/gridfilter.model';
import {FormatSettings} from '@progress/kendo-angular-dateinputs';


@Component({
  selector: 'catalyst-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  providers: [DatePipe, DecimalPipe],
  // encapsulation: ViewEncapsulation.None,
})


export class TransactionsComponent implements OnInit, OnDestroy {

  public gridData: any;
  public transationdata: any[];
  public mySelection: string[] = [];
  response: any;
  genericresponse: GenericResponse;
  genericresponse2: GenericResponse2;
  transactions: Transactions[];
  transactiongridlistglobal: Transactionsgrid[] = [];
  loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  currentpagenumber: number;
  totalpages: number;
  totalcount: number;
  issearchon = false;
  searchpagenumber = 1;
  // shailendra
  filterstates: any = new FilterState();
  gridfilters: any = new GridColumn();
  gridcolumnname: any = ['TransactionID', 'WhoRequested', 'TransactionTypeCode', 'AccountingPeriodCode', 'AccountName',
    'PolicyNumber', 'IssuingPaper', 'EffectiveDate',
    'ProfitCenter', 'TrxAmount', 'Taxes', 'Fees', 'Surcharges', 'CalculatedTFS', 'Commission',
    'TransactionTypeDesc', 'WhenSentToMFX', 'WhenReconciled',
    'WhenRcvdFromMFX', 'WhoUpdated', 'WhenUpdated', 'Notes'];
  gridColumnOrder: any = [
    {field: 'TransactionID', title: 'Transaction ID', width: 140, type: 'Text'},
    {field: 'WhoRequested', title: 'Who Requested', width: 150, type: 'Text'},
    {field: 'TransactionType', title: 'Transaction Type', width: 170, type: 'Text'},
    {field: 'AccountingPeriod', title: 'Accounting Period', width: 170, type: 'Text'},
    {field: 'AccountName', title: 'Account Name', width: 250, type: 'Text'},
    {field: 'PolicyNumber', title: 'Policy Number', width: 140, type: 'Text'},
    {field: 'IssuingPaper', title: 'Issuing Paper', width: 140, type: 'Text'},
    {field: 'EffectiveDate', title: 'Effective Date', width: 200, type: 'Date'},
    {field: 'ProfitCenter', title: 'Profit Center', width: 130, type: 'Text'},
    {field: 'TrxAmount', title: 'TRX Amount', width: 130, type: 'Text'},
    {field: 'Taxes', title: 'Taxes', width: 100, type: 'Text'},
    {field: 'Fees', title: 'Fees', width: 100, type: 'Text'},
    {field: 'Surcharges', title: 'Surcharges', width: 130, type: 'Text'},
    {field: 'CalculatedTFS', title: 'Calc TFS', width: 120, type: 'Text'},
    {field: 'Commission', title: 'Commission(%)', width: 140, type: 'Text'},
    {field: 'TransactionStatus', title: 'Transaction Status', width: 170, type: 'Text'},
    {field: 'ReceivedtoBooking', title: 'Received to Booking', width: 200, type: 'Date'},
    {field: 'CompletedDate', title: 'Completed Date', width: 200, type: 'Date'},
    {field: 'DateRec', title: 'Date Reconciled', width: 200, type: 'Date'},
    {field: 'WhoUpdated', title: 'Who Updated', width: 140, type: 'Text'},
    {field: 'WhenUpdated', title: 'When Updated', width: 200, type: 'Date'},
    {field: 'Notes', title: 'Notes', width: 200, type: 'Text'},
  ];
  // end
  public selectableSettings: SelectableSettings;
  public gridHeight = {
    height: 'calc(100vh - 130px)'
  };
  gridcolumnmap: any = {
    TransactionType: 'TransactionTypeDesc',
    AccountingPeriod: 'AccountingPeriodCode',
    TransactionStatus: 'TransactionStatusCode',
    CompletedDate: 'WhenRcvdFromMFX',
    ReceivedtoBooking: 'WhenSentToMFX',
    DateRec: 'WhenReconciled'
  };
  public gridDataResult: GridDataResult = {data: [], total: 0};
  public state: State;
  displayedColumns: string[] = ['Edit', 'TransactionID', 'WhoRequested', 'TransactionType', 'AccountingPeriod', 'AccountName',
    'PolicyNumber', 'IssuingPaper', 'EffectiveDate', 'ProfitCenter',
    'TRXAmount', 'Taxes', 'Fee', 'Surcharges',
    'CalcTF5', 'Commission', 'TransactionStatus', 'ReceivedtoBooking', 'CompletedDate', 'DateRec', 'WhoUpdated', 'WhenUpdated', 'Notes'];
  selection = new SelectionModel<Transactionsgrid>(false, []);
  public format: FormatSettings = {
    displayFormat: 'yyyy-MM-dd',
    inputFormat: 'yyyy-MM-dd'
  };

  constructor(private router: Router, private navigationService: NavigationService,
              private apiService: ApiService, private datepipe: DatePipe,
              private decimalpipe: DecimalPipe) {

    this.selectableSettings = {
      checkboxOnly: false,
      mode: 'single',
      drag: false
    };
  }

  ngOnInit(): void {
    this.navigationService.removeAllMenus();
    this.navigationService.showtab();
    this.navigationService.hidetransactiontab();
    this.navigationService.hideaddpage();
    this.navigationService.hideusermaintenancepage();
    this.navigationService.hideusermaintenancetab();
    this.navigationService.hideupdatebuttonflag();
    this.navigationService.hideopenbyrowidflag();
    this.navigationService.onaddpage = false;
    this.navigationService.onupdatepage = false;
    this.navigationService.onusermaintenancepage = false;
    this.navigationService.redirecttotrxn = false;
    this.navigationService.redirecttohome = false;
    this.setGridOrder();
    this.refreshdata();
    this.defaultsort();
    this.clearFilters();
    this.dataStateChange(this.state);
    this.navigationService.rowid = null;
  }

  trackByColField(index, col): string {
    return col.field;
  }

  setGridOrder() {
    if (!isNull(sessionStorage.getItem('Transaction_order'))) {
      this.gridColumnOrder = JSON.parse(sessionStorage.getItem('Transaction_order'));
    }
  }


  refreshdata() {
    this.navigationService.isrefresh.subscribe(data => {
      if (data === true) {
        if (!this.isFilterData()) {
          this.loaddata(this.searchpagenumber);
        } else {
          this.filterservicecall();
        }

      }
    });
  }


  onCellClick(e) {
    this.navigationService.rowid = e.dataItem.TransactionID;
  }

  onGridDoubleClick() {
    if (this.navigationService.rowid > 0) {
      this.router.navigate(['add-edit']);
    } else {
      console.log(this.navigationService.rowid + 'else condition');
    }
  }

  SetSelectedItem(e): void {

    if (e.TransactionID.toString().length > 0 && this.navigationService.rowid !== e.TransactionID) {

      this.navigationService.rowid = e.TransactionID;
    } else {
      this.navigationService.rowid = null;
    }
  }

  isFilterData() {
    let isfilterstat = false;
    this.gridcolumnname.forEach(element => {
      const state = this.filterstates[element];
      if (state.filter.filters[0].operator !== '' && state.filter.filters[0].operator !== '') {
        isfilterstat = true;
      }
      if (state.filter.filters[0].value !== '' && state.filter.filters[0].value != null) {
        isfilterstat = true;
      }
      if (state.sort[0].dir !== '' && state.sort[0].dir != null) {
        isfilterstat = true;
      }
    });
    return isfilterstat;
  }

// shailendra
  dataStateChange($event) {
    let field = '';
    this.searchpagenumber = $event.skip === 0 ? 1 : ($event.skip + 10) / 10;
    this.state = $event;
    this.filterstates = new FilterState();
    const isfilterstat = this.isFilterData();

    if (($event.filter.filters.length === 0) && $event.sort.length === 0 && !isfilterstat) {
      sessionStorage.removeItem('Transaction_filter');
      this.loaddata(this.searchpagenumber);
    } else {
      if ($event.filter.filters.length > 0 || ($event.sort && $event.sort.length > 0)) {
        if ($event.filter && $event.filter.filters.length > 0) {
          $event.filter.filters.forEach(
            obj => {
              field = isNotNullOrUndefined(this.gridcolumnmap[obj.field]) ?
                this.gridcolumnmap[obj.field] : obj.field;
              this.filterstates[field].filter.filters = [obj];
              this.filterstates[field].filter.logic = $event.filter.logic;

            }
          );
        }
        if ($event.sort && $event.sort.length > 0) {
          field = isNotNullOrUndefined(this.gridcolumnmap[$event.sort[0].field]) ? this.gridcolumnmap[$event.sort[0].field] : $event.sort[0].field;
          this.filterstates[field].sort[0] = $event.sort[0];
        }
      }

      this.gridcolumnname.forEach(element => {
        const state = this.filterstates[element];
        if (state.filter.filters[0].operator !== '' && state.filter.filters[0].operator !== '') {
          this.gridfilters[element].operator = state.filter.filters[0].operator;
        } else {
          state.filter.filters[0].operator = null;
          this.gridfilters[element].operator = state.filter.filters[0].operator;
        }
        if (state.filter.filters[0].value !== '' && state.filter.filters[0].value
          != null && (state.filter.filters[0].operator !== 'isnotnull' ||
            state.filter.filters[0].operator !== 'isnull' || state.filter.filters[0].operator !== 'isemplty')) {
          if (element === 'EffectiveDate' || element === 'WhenUpdated' || element === 'WhenReconciled'
            || element === 'WhenRcvdFromMFX' || element === 'WhenSentToMFX') {
            const st = {...state.filter.filters[0].value};
            state.filter.filters[0].value = this.datepipe.transform(state.filter.filters[0].value, 'yyyy/MM/dd');
          }
          this.gridfilters[element].value = state.filter.filters[0].value;
        } else {
          state.filter.filters[0].value = null;
          this.gridfilters[element].value = state.filter.filters[0].value;
        }
        if (state.sort[0].dir !== '' && state.sort[0].dir != null) {
          this.gridfilters[element].sort = state.sort[0];
        } else {
          state.sort[0].dir = null;
          this.gridfilters[element].sort = state.sort[0];
        }
      });
      this.filterservicecall();
    }
  }


  public clearFilters() {
    this.state = JSON.parse(sessionStorage.getItem('Transaction_filter'));

    if (isNull((this.state))) {
      this.state = {
        skip: 0,
        take: 10,
        filter: {
          logic: 'and',
          filters: []
        },
        sort: []
      };

    }
  }

// end

  filterservicecall() {
    this.loadingSubject.next(true);
    this.apiService.getTransactionsbyfilter(this.gridfilters, this.searchpagenumber)
      .subscribe(data => {
        this.commongridbind(data);
      });
  }

  defaultsort() {
    this.navigationService.isdefaultsort.subscribe(defaultdata => {
      if (defaultdata === true) {
        this.clearFilters();
        this.filterstates = new FilterState();
        this.gridfilters = new GridColumn();
        this.searchpagenumber = 1;
        this.state.skip = 0;
        this.loaddata(this.searchpagenumber);
      }
    });
  }


  loaddata(pagenumber: number) {
    this.issearchon = false;
    this.loadingSubject.next(true);
    this.apiService.getPaginatedTransactions(pagenumber)
      .subscribe(data => {
        this.commongridbind(data);
      });
  }


  commongridbind(data: any) {
    this.genericresponse = data as GenericResponse;
    this.currentpagenumber = this.genericresponse.body.pagenumber;
    this.totalpages = this.genericresponse.body.totalpages;
    this.totalcount = this.genericresponse.body.totalcount;
    this.transactions = this.genericresponse.body.results;
    const transactiongridlist: Transactionsgrid[] = [];
    this.transactions.forEach((value) => {
      const transactiongrid: Transactionsgrid = new Transactionsgrid();
      transactiongrid.TransactionID = value.TransactionID;
      transactiongrid.WhoRequested = value.WhoRequested;
      transactiongrid.TransactionType = value.TransactionTypeDesc;
      transactiongrid.AccountingPeriod = value.AccountingPeriodCode;
      transactiongrid.AccountName = value.AccountName;
      transactiongrid.PolicyNumber = value.PolicyNumber;
      transactiongrid.IssuingPaper = value.IssuingPaper;
      if (value.EffectiveDate !== ''
        && value.EffectiveDate != null) {
        const effectivesplit = value.EffectiveDate.split('T');
        transactiongrid.EffectiveDate = this.datepipe.transform(effectivesplit[0], 'MM-dd-yyyy');
      } else {
        transactiongrid.EffectiveDate = null;
      }
      transactiongrid.ProfitCenter = value.ProfitCenter;
      if (value.TrxAmount != null) {
        transactiongrid.TrxAmount = this.decimalpipe.transform(value.TrxAmount, '1.2-2', 'en');
      } else {
        transactiongrid.TrxAmount = null;
      }
      if (value.Taxes != null) {
        transactiongrid.Taxes = this.decimalpipe.transform(value.Taxes, '1.2-2', 'en');
      } else {
        transactiongrid.Taxes = null;
      }
      if (value.Fees != null) {
        transactiongrid.Fees = this.decimalpipe.transform(value.Fees, '1.2-2', 'en');
      } else {
        transactiongrid.Fees = null;
      }
      if (value.Surcharges != null) {
        transactiongrid.Surcharges = this.decimalpipe.transform(value.Surcharges, '1.2-2', 'en');
      } else {
        transactiongrid.Surcharges = null;
      }
      if (value.CalculatedTFS != null) {
        transactiongrid.CalculatedTFS = this.decimalpipe.transform(value.CalculatedTFS, '1.2-2', 'en');
      } else {
        transactiongrid.CalculatedTFS = null;
      }
      if (value.Commission != null) {
        transactiongrid.Commission = this.decimalpipe.transform(value.Commission, '1.2-2');
      } else {
        transactiongrid.Commission = null;
      }
      transactiongrid.TransactionStatus = value.TransactionStatusCode;
      if (value.WhenSentToMFX !== ''
        && value.WhenSentToMFX != null) {
        const whensentsplit = value.WhenSentToMFX.split('T');
        transactiongrid.ReceivedtoBooking = this.datepipe.transform(whensentsplit[0], 'MM-dd-yyyy');
      } else {
        transactiongrid.ReceivedtoBooking = null;
      }
      if (value.WhenRcvdFromMFX !== ''
        && value.WhenRcvdFromMFX != null) {
        const whenrcvdsplit = value.WhenRcvdFromMFX.split('T');
        transactiongrid.CompletedDate = this.datepipe.transform(whenrcvdsplit[0], 'MM-dd-yyyy');
      } else {
        transactiongrid.CompletedDate = null;
      }
      if (value.WhenReconciled !== ''
        && value.WhenReconciled != null) {
        const whenreconciledsplit = value.WhenReconciled.split('T');
        transactiongrid.DateRec = this.datepipe.transform(whenreconciledsplit[0], 'MM-dd-yyyy');
      } else {
        transactiongrid.DateRec = null;
      }
      transactiongrid.WhoUpdated = value.WhoUpdated;
      if (value.WhenUpdated !== ''
        && value.WhenUpdated != null) {
        const whenupdatedsplit = value.WhenUpdated.split('T');
        transactiongrid.WhenUpdated = this.datepipe.transform(whenupdatedsplit[0], 'MM-dd-yyyy');
      } else {
        transactiongrid.WhenUpdated = null;
      }
      transactiongrid.Notes = value.Notes;
      transactiongridlist.push(transactiongrid);
    });
    this.transactiongridlistglobal = transactiongridlist;
    this.gridDataResult = {data: this.transactiongridlistglobal, total: this.totalcount};
    this.gridData = this.gridDataResult;
    this.loadingSubject.next(false);
  }

  columnReorderEvent(order: ColumnReorderEvent) {
    this.gridColumnOrder.splice(order.newIndex, 0, this.gridColumnOrder.splice(order.oldIndex, 1)[0]);
    sessionStorage.setItem('Transaction_order', JSON.stringify(this.gridColumnOrder));
  }

  ngOnDestroy() {
    if (this.state.sort.length > 0 || this.state.filter.filters.length > 0) {
      sessionStorage.setItem('Transaction_filter', JSON.stringify(this.state));
    } else {
      sessionStorage.removeItem('Transaction_filter');
    }

  }


}



