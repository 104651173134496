export class Transactions {
    TransactionID: number;
    TransactionTypeCode: string;
    TransactionStatusCode: string;
    AccountingPeriodCode: string;
    AccountName: string;
    ProfitCenter: string;
    PolicyNumber: string;
    EffectiveDate: string;
    Limits: number;
    Attachment: number;
    PartOf: number;
    ParticipationPct: number;
    TrxAmount: number;
    TFS: number;
    Commission: number;
    IsFacultative: number;
    IsQuoteShare: number;
    IsXOL: number;
    IsIISResponsible: number;
    ReinsuranceType: string;
    WhenReconciled: string;
    ReconciliationStatus: string;
    WhoRequested: string;
    WhenSentToMFX: string;
    WhenRcvdFromMFX: string;
    SSSourceID: number;
    WhenUpdated: string;
    WhoUpdated: string;
    WhenCreated: string;
    WhoCreated: string;
    Notes: string;
    ComplexityCodes: number;
    Taxes: number;
    Fees: number;
    Surcharges: number;
    CalculatedTFS: number;
    CommissionD: number;
    ParticipantName1: number;
    ParticipantPercent1: number;
    FeePercent1: number;
    ParticipantName2: number;
    ParticipantPercent2: number;
    FeePercent2: number;
    ParticipantName3: number;
    ParticipantPercent3: number;
    FeePercent3: number;
    ParticipantName4: number;
    ParticipantPercent4: number;
    FeePercent4: number;
    ParticipantName5: number;
    ParticipantPercent5: number;
    FeePercent5: number;
    Source: string;
    Currency: string;
    WrittenLine: number;
    IssuingPaper: string;
    TransactionTypeDesc: string;
    TransactionSourceCode: string;
}
