     <kendo-grid [navigable]="true"
     [selectable]="selectableSettings"
     [data]="gridData"
     kendoGridSelectBy="TransactionID"
     [selectedKeys]="mySelection"
     [loading]="loading$ | async"
     [sortable]="true"
     [reorderable]="true"
     [resizable]="true"
     [pageable]="true"
     [ngStyle]="gridHeight"
     [scrollable]="false"
     [pageSize]="state.take"
     [skip]="state.skip"
     (dataStateChange)="dataStateChange($event)"
     (columnReorder)="columnReorderEvent($event)"
     (cellClick)="onCellClick($event)"
     (dblclick)="onGridDoubleClick()"
     [columnMenu]="true"
     [filterable]="true"
     [filter]="state.filter"
     >
       <ng-container >
       <kendo-grid-column *ngFor=" let col of gridColumnOrder; trackBy: trackByColField" [field]="col.field" [title]="col.title" [width]="col.width" name='column' >
         <ng-container [ngSwitch]="col.type">
           <ng-template kendoGridFilterCellTemplate let-filter let-column="column" *ngSwitchCase="'Date'">
           <kendo-grid-date-filter-cell
             [showOperators]="true"
             [column]="column"
             [filter]="filter">
           </kendo-grid-date-filter-cell>
           <ng-template kendoGridColumnMenuTemplate let-service="service">
             <kendo-grid-columnmenu-sort [service]="service">
             </kendo-grid-columnmenu-sort>
           </ng-template>
         </ng-template>
           <ng-template kendoGridColumnMenuTemplate let-service="service" *ngSwitchCase="'Text'">

           <kendo-grid-columnmenu-sort [service]="service">
           </kendo-grid-columnmenu-sort>
         </ng-template>
         </ng-container>
       </kendo-grid-column>
       </ng-container>
     </kendo-grid>



