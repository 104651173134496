<p-toast class="custom-toast" position="top-right">
    <ng-template let-message pTemplate="message">
        <div style="font-weight: bold;text-align: center;margin-bottom:0.2rem;">
            <span innerHtml="{{innerhtml2}}"></span>
        </div>
        <div style="text-align: center;">
          <p  innerHtml="{{innerhtml}}"></p>
        </div>
      </ng-template>
</p-toast>
<h2 mat-dialog-title style="background-color: lightgray; border-bottom: 2px #000;">
Report Filters
<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" (click)="close()" color="warn">X</mat-icon>
</button>
<hr/>
</h2>
<mat-dialog-content>
        <form class="form" name="reportfilterForm" [formGroup]="reportfilterForm">
                <div class="overlay" *ngIf="loading$ | async">
                    <div class="center">
                        <mat-progress-spinner diameter=80 mode="indeterminate" color="primary">
                        </mat-progress-spinner>
                    </div>
                </div>
                <div class="p-4" style="padding: 0.5rem !important; overflow: auto;">
                    <div class="container">
                        <div class="row">
                            <br/><br/>
                            <table>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <!--td>Effective Date from</td-->
                                        <td>
                                            <mat-form-field style="width: 13rem;">
                                                <mat-label>Effective Date from</mat-label>
                                                <input formControlName="effectivedatefrom" id="effectivedatefrom"
                                                    matInput [matDatepicker]="dp1">
                                                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #dp1 disabled="false"></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                        <!--td>Effective Date to</td-->
                                        <td>
                                            <mat-form-field style="width: 13rem;margin-left: 5rem;" dateFormatUSUK>
                                                <mat-label>Effective Date to</mat-label>
                                                <input formControlName="effectivedateto" id="effectivedateto" matInput
                                                    [matDatepicker]="dp2">
                                                <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #dp2 disabled="false"></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <!--td>Completed Date from</td-->
                                        <td>
                                            <mat-form-field style="width: 13rem;">
                                                <mat-label>Completed Date from</mat-label>
                                                <input formControlName="completeddatefrom" id="completeddatefrom"
                                                    matInput [matDatepicker]="dp3">
                                                <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #dp3 disabled="false"></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                        <!--td>Completed Date to</td-->
                                        <td>
                                            <mat-form-field style="width: 13rem;margin-left: 5rem;" dateFormatUSUK>
                                                <mat-label>Completed Date to</mat-label>
                                                <input formControlName="completeddateto" id="completeddateto" matInput
                                                    [matDatepicker]="dp4">
                                                <mat-datepicker-toggle matSuffix [for]="dp4"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #dp4 disabled="false"></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <!--td>Date Reconciled from</td-->
                                        <td>
                                            <mat-form-field style="width: 13rem;">
                                                <mat-label>Date Reconciled from</mat-label>
                                                <input formControlName="datereconciledfrom" id="datereconciledfrom"
                                                    matInput [matDatepicker]="dp5">
                                                <mat-datepicker-toggle matSuffix [for]="dp5"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #dp5 disabled="false"></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                        <!--td>Date Reconciled to</td-->
                                        <td>
                                            <mat-form-field style="width: 13rem;margin-left: 5rem;" dateFormatUSUK>
                                                <mat-label>Date Reconciled to</mat-label>
                                                <input formControlName="datereconciledto" id="datereconciledto" matInput
                                                    [matDatepicker]="dp6">
                                                <mat-datepicker-toggle matSuffix [for]="dp6"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #dp6 disabled="false"></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <!--td>Received to Booking Date from</td-->
                                        <td>
                                            <mat-form-field style="width: 13rem;">
                                                <mat-label>Received to Booking Date from</mat-label>
                                                <input formControlName="receivedtobookingdatefrom"
                                                    id="receivedtobookingdatefrom" matInput [matDatepicker]="dp7">
                                                <mat-datepicker-toggle matSuffix [for]="dp7"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #dp7 disabled="false"></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                        <!--td>Received to Booking Date to</td-->
                                        <td>
                                            <mat-form-field style="width: 13rem;margin-left: 5rem;" dateFormatUSUK>
                                                <mat-label>Received to Booking Date to</mat-label>
                                                <input formControlName="receivedtobookingdateto"
                                                    id="receivedtobookingdateto" matInput [matDatepicker]="dp8">
                                                <mat-datepicker-toggle matSuffix [for]="dp8"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #dp8 disabled="false"></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <!--td>Transaction Status</td-->
                                        <td>
                                            <div class="row">
                                                <div class="col-12" style="flex: 0 0 68%;">
                                                    <label for="endorsementType">Transaction Status</label>
                                                    <mat-selection-list formControlName="trxnstatus" id="trxnstatus"
                                                        [multiple]="true" [(ngModel)]="selectedtrxnstatus">

                                                        <mat-list-option *ngFor="let trxnlist of TransactionStatusList"
                                                            [value]="trxnlist">
                                                            {{trxnlist}}
                                                        </mat-list-option>
                                                    </mat-selection-list>

                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="rowcustom">
                                                <div class="col-12">
                                                    <label for="endorsementType">Transaction Type</label>
                                                    <mat-selection-list formControlName="trxntype" id="trxntype"
                                                        [multiple]="true" [(ngModel)]="selectedtrxntype">
                                                        <mat-list-option *ngFor="let trxntype of TransactionTypeList"
                                                            [value]="trxntype">
                                                            {{trxntype}}
                                                        </mat-list-option>
                                                    </mat-selection-list>

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <!--td>Profit Center</td-->
                                        <td>
                                            <div class="row">
                                                <div class="col-12" style="flex: 0 0 68%;">
                                                    <label for="endorsementType">Profit Center</label>
                                                    <mat-selection-list formControlName="profitcenter" id="profitcenter"
                                                        [multiple]="true" [(ngModel)]="selectedprofitcenter">
                                                        <mat-list-option *ngFor="let profitcenter of ProfitCenterList"
                                                            [value]="profitcenter">
                                                            {{profitcenter}}
                                                        </mat-list-option>
                                                    </mat-selection-list>
                                                </div>
                                            </div>
                                        </td>
                                        <!--td>Accounting Period</td-->
                                        <td>
                                            <div class="rowcustom">
                                                <div class="col-12">
                                                    <label for="endorsementType">Accounting Period</label>
                                                    <mat-selection-list formControlName="accountperiod"
                                                        id="accountperiod" [multiple]="true"
                                                        [(ngModel)]="selectedaccountperiod">
                                                        <mat-list-option *ngFor="let accountperiod of AccountPeriodList"
                                                            [value]="accountperiod">
                                                            {{accountperiod}}
                                                        </mat-list-option>
                                                    </mat-selection-list>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row" style="margin-left: 185px;">
                            <div class="col-sm-4 offset-sm-4" style="display: contents;">
                            </div>
                        </div>
                    </div>
                </div>
        </form>
</mat-dialog-content>
<mat-dialog-actions style="background-color: #efefef;">
    <div style="text-align: center; width: 100%;">
    <button type="button" class="btn btn-outline-secondary" style=" font-weight: bold;
    color: cornflowerblue; background-color: white;" (click)="resetall()">Reset All</button>
                    <button type="button" class="btn btn-success" style="margin-left: 25px;"
                        (click)="exportToExcel()">Continue Export to
                        Excel</button>
                    <button type="button" class="btn btn-danger" style="margin-left: 25px;"
                        (click)="close()">Cancel</button>&nbsp;
    </div>
</mat-dialog-actions>