import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {Session} from 'src/app/components/Models/session.model';
import {environment} from 'src/environments/environment'

@Injectable()
export class NavigationService {


public rowid: number;
public unhideonexcelclose = false;
powerbilink:string;
public homepopup = false;
public onaddpage = false;
public onupdatepage = false;
public onusermaintenancepage = false;
public trxnpopup = false;
public redirecttotrxn = false;
public redirecttohome = false;
public whoCreatedUpdated: string;
public isforbidden = new BehaviorSubject<boolean>(false);
public authguardloader = new BehaviorSubject<boolean>(false);
public checked = false;
public firsttimeonanypage = true;
public session: Session = new Session();
private updatetransaction = new BehaviorSubject<boolean>(false);
private searchtextflag = new BehaviorSubject<boolean>(false);
private totalCost = new BehaviorSubject<boolean>(false);
private opentransaction = new BehaviorSubject<boolean>(false);
private usermaintenance = new BehaviorSubject<boolean>(false);
private showupdatebutton = new BehaviorSubject<boolean>(false);
private addtransaction = new BehaviorSubject<boolean>(false);
private onaddpageflag = new BehaviorSubject<boolean>(false);
private onusermaintenancepageflag = new BehaviorSubject<boolean>(false);
private copytransaction = new BehaviorSubject<boolean>(false);
private copytransactionupdate = new BehaviorSubject<boolean>(false);
private search = new BehaviorSubject<boolean>(false);
private refresh = new BehaviorSubject<boolean>(false);
private openbyrowid = new BehaviorSubject<boolean>(false);
private defaultsort = new BehaviorSubject<boolean>(false);
private removeAllMenusItems = new BehaviorSubject<boolean>(true);
public istabdisplay = this.totalCost.asObservable();
public isopentransaction = this.opentransaction.asObservable();
public isusermaintenance = this.usermaintenance.asObservable();
public isupdatetransaction = this.updatetransaction.asObservable();
public isshowupdatebutton = this.showupdatebutton.asObservable();
public isonaddpage = this.onaddpageflag.asObservable();
public isonusermaintenancepage = this.onusermaintenancepageflag.asObservable();
public isaddtransaction = this.addtransaction.asObservable();
public iscopytransaction = this.copytransaction.asObservable();
public iscopytransactionupdate = this.copytransactionupdate.asObservable();
public isrefresh = this.refresh.asObservable();
public issearch = this.search.asObservable();
public isopenbyrowid = this.openbyrowid.asObservable();
public isdefaultsort = this.defaultsort.asObservable();
public issearchtext = this.searchtextflag.asObservable();
public clearMenuBar = this.removeAllMenusItems.asObservable();

constructor(private readonly router: Router) { 
  this.powerbilink = environment.PowerBI;
}
  showtab() {
    this.totalCost.next(true);
  }
  hidetab() {
    this.totalCost.next(false);
  }
  showtransactiontab() {
    console.log(this.rowid);
    this.opentransaction.next(true);
  }
  hidetransactiontab() {
    this.opentransaction.next(false);
  }
  showusermaintenancetab() {
    this.usermaintenance.next(true);
  }
  hideusermaintenancetab() {
    this.usermaintenance.next(false);
  }
  navigateToHome() {
    this.router.navigateByUrl('');
  }

  addnewtransaction() {
  this.addtransaction.next(true);
  }

  stopaddnewtransaction() {
    this.addtransaction.next(false);
  }

  updatethistransaction() {
      this.updatetransaction.next(true);
    }

  stopupdatethistransaction() {
      this.updatetransaction.next(false);
  }

  showusermaintenancepage() {
    this.onusermaintenancepageflag.next(true);
  }

  hideusermaintenancepage() {
    this.onusermaintenancepageflag.next(false);
  }

  showaddpage() {
      this.onaddpageflag.next(true);
    }
  hideaddpage() {
      this.onaddpageflag.next(false);
    }
  showupdatebuttonflag() {
    this.showupdatebutton.next(true);
    }

  hideupdatebuttonflag() {
  this.showupdatebutton.next(false);
    }

  copytransactionflag() {
  this.copytransaction.next(true);
    }

  stopcopytransactionflag() {
  this.copytransaction.next(false);
     }

  copytransactionupdateflag() {
  this.copytransactionupdate.next(true);
    }

  stopcopytransactionupdateflag() {
    this.copytransactionupdate.next(false);
      }

  refreshflag() {
      this.refresh.next(true);
    }
  openbyrowidflag() {
     this.openbyrowid.next(true);
    }
  hideopenbyrowidflag() {
     this.openbyrowid.next(false);
    }
  defaultsortflag() {
     this.defaultsort.next(true);
    }
  hidedefaultsortflag() {
     this.defaultsort.next(false);
    }
  removeAllMenus() {
      this.removeAllMenusItems.next(true);
    }
  navigateToExternalSite() {
    window.open(this.powerbilink, '_blank');
  }
}
