export function getIcon(severity: string) {
    let icon = '';

    switch (severity) {
        case 'success':
            icon = 'fas fa-check-circle';
            break;
        case 'error':
            icon = 'far fa-times-circle';
            break;
        case 'info':
            icon = 'fas fa-info-circle';
            break;
        case 'warn':
            icon = 'fas fa-exclamation-circle';
            break;
    }

    return icon;
}
