import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientJsonpModule, HttpErrorResponse} from '@angular/common/http';
import {GenericResponse} from 'src/app/components/Models/generic-response.model';
import {Observable, throwError} from 'rxjs';
import {ExcelandTransactionResponse} from 'src/app/components/Models/excelresponse.model';
import {MastersResponse} from 'src/app/components/Models/mastersdata.model';
import {TransactionByIDResponse} from 'src/app/components/Models/transactionbyIDResponse.model';
import {PolicyNumber} from 'src/app/components/Models/policynumber.model';
import {WhoRequested} from 'src/app/components/Models/whorequested.model';
import {AccountName} from 'src/app/components/Models/accountname.model';
import {AccountPeriod} from 'src/app/components/Models/accountperiod.model';
import {ExcelAccountPeriod} from 'src/app/components/Models/excelaccountingperiod.model';
import {Currency} from 'src/app/components/Models/currency.model';
import {GenericResponse2} from 'src/app/components/Models/genericresponse2.model';
import { AuthorizationGuard } from './authorization.guard';
import { environment } from '../../environments/environment';
import {Session} from 'src/app/components/Models/session.model';
import {SessionResponse, SessionLoginResponse} from 'src/app/components/Models/sessionresponse.model';
import {catchError, map} from 'rxjs/operators';
import { error } from 'console';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
exportExcelCount: number;
  headers: any;
    // devapiurl = 'https://si8j7kb46g-vpce-02b2052bb9bf81b7a.execute-api.us-east-1.amazonaws.com/development/greetings/';
    // testapiurl = 'https://xu6jnut6x7-vpce-02b2052bb9bf81b7a.execute-api.us-east-1.amazonaws.com/test/greetings/';
  apiurl = ''; // this.devapiurl;
  //  apiurl = this.testapiurl;


  constructor(private httpClient: HttpClient, private authService: AuthorizationGuard) {
    this.exportExcelCount = 0;
    this.apiurl = environment.APIEndpoint;
    this.headers = new HttpHeaders({Authorization: this.authService.getAuthorizationHeaderValue()});
  }

  public getTransactions() {
    const api = this.apiurl + 'gettransaction';
    return this.httpClient.get(api, {headers: this.headers});
  }

  public getPaginatedTransactions(pagenumber: number): Observable<GenericResponse> {
    const page = pagenumber;
    const api = this.apiurl + 'paginated/' + page;
    return this.httpClient.get<GenericResponse>(api, {headers: this.headers});
  }

  public getjsonpPolicySearchCallbackName(): string {
    const str = 'ng_jsonp_callback_' + this.exportExcelCount ;
    return str;
  }

  public getExcelData(body: any) {
    const api = this.apiurl + 'export/' + this.getjsonpPolicySearchCallbackName();
    return this.httpClient.post<ExcelandTransactionResponse>(api, body,
      {headers: this.headers, observe: 'response'});
  }

  // public getExcelResult(uid) {
  //   const api = this.apiurl + 'exportResult/' + this.getjsonpPolicySearchCallbackName() + '/' + uid;
  //   return this.httpClient.get<ExcelandTransactionResponse>(api,
  //     {headers: this.headers, observe: 'response'});
  // }

  public getExcelDataS3(api) {
    console.log('getExcelDataS3', api);
    return this.httpClient.jsonp(api,  'callback').pipe(map(res => res as any));

  }

  public getTransactionsbyID(transactionid: number) {
    const api = this.apiurl + 'transaction/' + transactionid;
    return this.httpClient.get<TransactionByIDResponse>(api, {headers: this.headers});
  }

  public getmastersdata() {
    const api = this.apiurl + 'masters';
    return this.httpClient.get<MastersResponse>(api, {headers: this.headers});
  }

  public getmasterspolicynumber() {
    const api = this.apiurl + 'masters/policynumber';
    return this.httpClient.get<PolicyNumber>(api, {headers: this.headers});
  }

  public getmasterwhorequested() {
    const api = this.apiurl + 'masters/whorequested';
    return this.httpClient.get<WhoRequested>(api, {headers: this.headers});
  }

  public getmasteraccountname() {
    const api = this.apiurl + 'masters/accountname';
    return this.httpClient.get<AccountName>(api, {headers: this.headers});
  }

  public getmasteraccountperiod() {
    const api = this.apiurl + 'masters/period';
    return this.httpClient.get<AccountPeriod>(api, {headers: this.headers});
  }

  public getmastercurrency() {
    const api = this.apiurl + 'masters/currency';
    return this.httpClient.get<Currency>(api, {headers: this.headers});
  }

  public getmasteraccountperiodforExcel() {
    const api = this.apiurl + 'masters/accperiod';
    return this.httpClient.get<ExcelAccountPeriod>(api, {headers: this.headers});
  }

  public postnewtransaction(body: any) {
    const api = this.apiurl + 'transaction';
    return this.httpClient.post<ExcelandTransactionResponse>(api, body, {headers: this.headers});
  }

  public updatetransaction(transactionid: number, body: any) {
    const api = this.apiurl + 'transaction/' + transactionid;
    return this.httpClient.put<ExcelandTransactionResponse>(api, body, {headers: this.headers});
  }

  public getTransactionsbysearchfilter(searchtext: string, pagenumber: number) {
    const api = this.apiurl + 'search/' + searchtext + '/' + pagenumber;
    return this.httpClient.get<GenericResponse2>(api, {headers: this.headers});
  }

  public getTransactionsbyfilter(body: any, pagenumber: number) {
    const api = this.apiurl + 'filtered/' + pagenumber + '/10' ;
    return this.httpClient.post<GenericResponse2>(api, body, {headers: this.headers});
  }

  public getAuthHash(body: Session) {
    const api = this.apiurl + 'session/search';
    return this.httpClient.post<SessionResponse>(api, body, {headers: this.headers});
  }

  public postAuthHash(body: Session) {
    const api = this.apiurl + 'session';
    return this.httpClient.post<SessionLoginResponse>(api, body, {headers: this.headers});
  }

  public putAuthHash(body: Session) {
    const api = this.apiurl + 'session';
    return this.httpClient.put<ExcelandTransactionResponse>(api, body, {headers: this.headers});
  }

  public AuthorizeUser(nnumber: string) {
    const api = this.apiurl + 'users/' + nnumber;
    return this.httpClient.get<ExcelandTransactionResponse>(api, {headers: this.headers});
  }

  public searchUserData(body)  {
    const api  = this.apiurl + 'searchUser';
    return this.httpClient.post<MastersResponse>(api, body, {headers: this.headers});
  }

  public addOrUpdateUser(body) {
    const api  = this.apiurl + 'addOrUpdateUser';
    return this.httpClient.post<MastersResponse>(api, body, {headers: this.headers});
  }

  public getRoles() {
    const api = this.apiurl + 'getRoleDetails'
    return this.httpClient.get<MastersResponse>(api, {headers: this.headers});
  }


  // private handleError(error: HttpErrorResponse) {
  //   if (error.error instanceof ErrorEvent) {
  //    // console.error('An error occurred:', error.error.message);
  //   } else {
  //      this.excelerror.next(true);
  //     //console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
  //   }
  //   return throwError('Something bad happened; please try again later.');
  // }
}
