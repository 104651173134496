import { Component, OnInit, Output, EventEmitter, Renderer2, Input, ChangeDetectorRef } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { NavigationService } from 'src/app/services/navigation.service';
import { ActivatedRoute,NavigationEnd, Router ,} from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExcelExportComponent } from 'src/app/components/excel-export/excel-export.component';
import { MessageService } from 'primeng/api';
import { SaveDialogueComponent } from 'src/app/components/save-dialogue/save-dialogue.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { AuthorizationGuard, CustomUserInfo } from 'src/app/services/authorization.guard';
import { ApiService } from 'src/app/services/api.service';
import { MenuItemContent } from 'primeng';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'catalyst-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  public isshow = false;
  public istransactionshow = false;
  public isusermaintenanceshow = false;
  public isupdateshow = false;
  public isonaddpage = false;
  public isonusermaintenancepage = false;
  value;
  @Output() isMenuOpen = new EventEmitter<boolean>();


  menuItems: MenuItem[];
  menuOpen = false;
  hidden = true;
  displayName: Observable<string>;
  userData$: Observable<any>;
  isforbidden: boolean;

  public settings: Array<any> = [{text: 'Log Out'}];

  @Input() userDisplayName: string;
  @Input() isAuthenticated: boolean;


  menuItemsAllTransactionsPage: MenuItem[] = [
    { label: 'Excel Export', icon: 'pi pi-fw pi-print', command: () => { this.excel_export(); } },
    { label: 'Power BI', icon: 'pi pi-fw pi-external-link', command: () => { this.navigateTo(); } },
    { label: 'Refresh', icon: 'pi pi-fw pi-refresh', command: () => { this.refreshdata(); } },
    { label: 'Add', icon: 'pi pi-fw pi-plus', command: () => { this.addtransaction(); } },
    { label: 'Default Sort', icon: 'pi pi-fw pi-sort', command: () => { this.defaultsort(); } },
  ];
  menuItemsUpdateTransaction: MenuItem[] = [
    { label: 'Copy Record', icon: 'pi pi-fw pi-copy', command: () => { this.copytransactiononupdate(); } },
    { label: 'Update', icon: 'pi pi-fw pi-save', command: () => { this.updatetransactionevent(); } },
  ];
  menuItemsAddTransaction: MenuItem[] = [
    { label: 'Copy Record', icon: 'pi pi-fw pi-copy', command: () => { this.copytransaction(); } },
    { label: 'Add', icon: 'pi pi-fw pi-save', command: () => { this.addnewtransaction(); } },
  ];
  menuItemsAllUserMaintenance: MenuItem[] = [
    { label: 'User Maintenance', icon: 'pi pi-fw pi-user-edit' },
    // { label: 'Excel Export', icon: 'pi pi-fw pi-print', command: () => { this.excel_export(); } },
    { label: 'Power BI', icon: 'pi pi-fw pi-external-link', command: () => { this.navigateTo(); } },
    { label: 'Transactions', icon: 'pi pi-fw pi-list', command: () => { this.navigateToTransactions(); } },
  ];

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private cdref: ChangeDetectorRef,
    private authgauard: AuthorizationGuard,
    private route: ActivatedRoute,
    private readonly navigationService: NavigationService,
    private apiService: ApiService,
    private router: Router, private modalservice: MatDialog,) { }

  ngOnInit() {
    console.log("Header component called")
    this.router.events
          .pipe(
            filter(e => e instanceof NavigationEnd)
          )
          .subscribe((navEnd: NavigationEnd) => {
            this.menuItems = [];
            let role = sessionStorage.getItem('Role')
            if (navEnd.urlAfterRedirects == '/transactions') {
              if (role != '2') {
               this.menuItemsAllTransactionsPage = 
               this.menuItemsAllTransactionsPage.filter(element => element.label !== 'Add')
              }
            }
            if (navEnd.urlAfterRedirects == '/add-edit') {
              if (role != '2') {
                this.menuItemsUpdateTransaction = 
                this.menuItemsUpdateTransaction.filter(element => element.label !== 'Update'
                 && element.label !== 'Copy Record')
              }
            }

            if (navEnd.urlAfterRedirects == '/forbidden')
            {
              this.menuItems = [];
            }
            else 
            {
              if(this.navigationService.firsttimeonanypage)
              {
              const menuItem: MenuItem = { label: 'Settings', icon: 'pi pi-fw pi-cog',
              items: [ { label: this.userDisplayName, icon: 'pi pi-fw pi-user' },
               { label: 'Sign Out', icon: 'pi pi-fw pi-power-off', command: () => { this.logout(); } } ] };
              this.menuItemsAllTransactionsPage.push(menuItem);
              this.menuItemsUpdateTransaction.push(menuItem);
              this.menuItemsAddTransaction.push(menuItem);
              this.menuItemsAllUserMaintenance.push(menuItem);
              this.navigationService.firsttimeonanypage = false;
              }
            }
     });
    this.navigationService.istabdisplay.subscribe((data) => {
      this.isshow = data;
      if (data) {
        this.menuItems = this.menuItemsAllTransactionsPage;
      }
    });
    this.navigationService.isopentransaction.subscribe((data) => {
      this.istransactionshow = data;
    });
    this.navigationService.isusermaintenance.subscribe((data) => {
      this.isusermaintenanceshow = data;
    });
    this.navigationService.isshowupdatebutton.subscribe((data) => {
      this.isupdateshow = data;
      if (data) {
        this.menuItems = this.menuItemsUpdateTransaction;
      }
    });
    this.navigationService.isonaddpage.subscribe((data) => {
      this.isonaddpage = data;
      if (data) {
        this.menuItems = this.menuItemsAddTransaction;
      }
    });
    this.navigationService.isonusermaintenancepage.subscribe((data) => {
      this.isonusermaintenancepage = data;
      if(data) {
        this.menuItems = this.menuItemsAllUserMaintenance;
      }
    })
    this.navigationService.clearMenuBar.subscribe((data) => {
      this.menuItems = [{ label: 'Settings', icon: 'pi pi-fw pi-cog',
      items: [ { label: this.userDisplayName, icon: 'pi pi-fw pi-user' },
      { label: 'Sign Out', icon: 'pi pi-fw pi-power-off', command: () => { this.logout(); } } ] }];
    });
  }

  navigateTo() {
    this.navigationService.navigateToExternalSite();
  }

  navigateToTransactions() {
    this.router.navigate(['transactions'])
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  addtransaction() {
    this.router.navigate(['addtransactio']);
  }

  refreshdata() {
    this.navigationService.refreshflag();
  }

  home() {
    if (this.navigationService.onaddpage === true
      || this.navigationService.onupdatepage === true) {
      this.navigationService.homepopup = true;
      this.navigationService.trxnpopup = false;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.hasBackdrop = true;
      this.modalservice.open(SaveDialogueComponent, dialogConfig);
    } else {
      this.router.navigate(['']);
    }

  }
  updatetransactionevent() {
    this.navigationService.updatethistransaction();
  }

  opentranactionbyrowid() {
    this.navigationService.openbyrowidflag();
  }

  addnewtransaction() {
    this.navigationService.addnewtransaction();
  }

  copytransaction() {
    this.navigationService.copytransactionflag();
  }

  copytransactiononupdate() {
    this.navigationService.copytransactionupdateflag();
  }


  transactions() {
    let role = sessionStorage.getItem('Role')
    if(role === '2'){
      this.navigationService.trxnpopup = true;
      this.navigationService.homepopup = false;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.hasBackdrop = true;
      this.modalservice.open(SaveDialogueComponent, dialogConfig);
    } else if (role === '1') {
      this.router.navigate(['transactions'])
    }    
  }

  defaultsort() {
    this.navigationService.defaultsortflag();
  }


  excel_export() {
    this.navigationService.hidetab();
    this.navigationService.removeAllMenus();
    this.navigationService.unhideonexcelclose = true;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = this.router.url

    this.modalservice.open(ExcelExportComponent, dialogConfig);
  }

  logout() {
    // add session table update
    this.apiService.putAuthHash(this.navigationService.session).subscribe(response => {
      if (response.statusCode === 200) {
        console.log('logout successfully registered');
        this.oidcSecurityService.logoff();
        // navigate to logout page
        this.router.navigate(['logout']);
      } else {
        console.log('logout registeration unsuccessful');
      }
    });

  }

}
