import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'catalyst-example-product-new-application',
  templateUrl: './example-product-new-application.component.html',
  styleUrls: ['./example-product-new-application.component.scss']
})
export class ExampleProductNewApplicationComponent implements OnInit {

  constructor(private readonly navigationService: NavigationService) { }

  ngOnInit() {
  }

  navigateTo() {
    this.navigationService.navigateToExternalSite();
  }

}
