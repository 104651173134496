import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import {Transactions} from 'src/app/components/Models/transactions.model';

@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  constructor() { }


  exportExcel(excelData) {

    const title = excelData.title;
    const header = excelData.headers;
    const Exceldata: Transactions[] = excelData.data;
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Excel Export');
    worksheet.addRow(header);
    Exceldata.forEach(d => {
       worksheet.addRow(d);
    });

    workbook.xlsx.writeBuffer().then((bufferdata) => {
      const blob = new Blob([bufferdata], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title + '.xlsx');
    });
  }
}
