import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'catalyst-save-dialogue',
  templateUrl: './save-dialogue.component.html',
  styleUrls: ['./save-dialogue.component.scss']
})
export class SaveDialogueComponent implements OnInit {

  constructor(private navservice: NavigationService,
              private router: Router,
              public dialogRef: MatDialogRef<SaveDialogueComponent> ) { }

  ngOnInit(): void {
  }

  yes() {
    if (this.navservice.onaddpage === true) {
    this.dialogRef.close();
    this.navservice.addnewtransaction();
    if (this.navservice.trxnpopup === true) {
    this.navservice.redirecttotrxn = true;
    } else if (this.navservice.homepopup === true) {
      this.navservice.redirecttohome = true;
    }
    } else if (this.navservice.onupdatepage === true) {
      this.dialogRef.close();
      this.navservice.updatethistransaction();
      if (this.navservice.trxnpopup === true) {
    this.navservice.redirecttotrxn = true;
    } else if (this.navservice.homepopup === true) {
      this.navservice.redirecttohome = true;
    }
    }
  }

  no() {
    if (this.navservice.trxnpopup === true) {
      this.dialogRef.close();
      this.router.navigate(['transactions']);
      this.navservice.trxnpopup = false;
    } else if (this.navservice.homepopup === true) {
      this.dialogRef.close();
      this.router.navigate(['']);
      this.navservice.homepopup = false;
    }
  }

  close() {
    this.dialogRef.close();
  }

}
