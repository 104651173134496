export class ExcelandTransactionResponse {
statusCode: number;
headers: Headers;
body: any;
message: string;
}

export class Headers {
   'Content-Type': string;
}
