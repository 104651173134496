import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/shared/enums/language';

@Injectable()
export class CatalystTranslateService {

  private readonly defaultLanguage = 'en';
  translateService: TranslateService;

  constructor(private readonly translate: TranslateService) {
    this.translateService = translate;
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang(this.defaultLanguage);
    this.translateService.use(this.defaultLanguage);
  }

  updateLanguage(language: Language) {
    const languageProfile = Language[language];

    this.setLanguage(languageProfile);
  }

  getCurrentLanguage(): string {
    return this.translateService.currentLang;
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  private setLanguage(language: string) {
    this.translateService.use(language);
  }
}
