<fieldset class="card p-lg-4 p-md-3 p-2" id="section-name">
    <div class="row">
        <legend class="col-form-label col-12">Want some help generating forms?</legend>
    </div>
    <div class="fields">
        <div class="row">
            <p>Catalyst has a form generator which uses a GUI to help build your forms. It allows you to add cards, rows, elements and produces the HTML, typescript, jasmine tests and more.</p>
            <p>Simple use the generator to build the form of your liking and paste the markup and typescript into the component ts and component html as indicated.</p>
            <div class="mt-2 w-100 text-right">
                <button type="button" class="btn btn-success" (click)="navigateTo()"><i class="fal fa-fw fa-external-link-alt mr-1"></i>Form Generator</button>
            </div>
        </div>
    </div>
</fieldset>

<fieldset class="card p-lg-4 p-md-3 p-2" id="section-name">
    <div class="row">
        <legend class="col-form-label col-12">New Application</legend>
    </div>
    <div class="fields">
        <div class="row">
            <div class="col-12">
                <div class="form-group "><input autocomplete="disabled" class="form-control" id="name"
                        name="name" placeholder=" " type="text"><label
                        for="name">{{ 'example-product.name' | translate }}</label></div>
            </div>
            <div class="col-12">
                <div class="form-group "><input class="form-control" id="description" type="text"
                        name="description" placeholder=" "><label
                        for="description">{{ 'example-product.description' | translate }}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button type="button" class="btn btn-primary float-right"> <i class="fal fa-fw fa-save"></i>
                    Save</button>
            </div>
        </div>
    </div>
</fieldset>
