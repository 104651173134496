import { Component, OnInit, OnDestroy } from '@angular/core';
import { getIcon } from './helpers/primeng';
import { CatalystTranslateService } from 'src/shared/services/catalyst-translate.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ApiService } from 'src/app/services/api.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthorizationGuard } from './services/authorization.guard';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'catalyst-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  article: any;
  userDisplayName: string;
  isAuthenticated: boolean;
  NNumber: string;
  loader = new BehaviorSubject(false);
  message = 'Authorizing...';
  userData: any;

  constructor(public oidcSecurityService: OidcSecurityService,
              private router: Router,
              private authguard: AuthorizationGuard,
              private readonly catalystTranslateService: CatalystTranslateService,
              private apiService: ApiService,
              private navservice: NavigationService) { }

  ngOnInit() {
    this.navservice.authguardloader.subscribe((value) => {
       if (value) {this.loader.next(true);
       } else {
        this.loader.next(false);
       }
    });
    const datakey = 'greeting';
    this.oidcSecurityService.checkAuth().subscribe((auth) => {
      this.isAuthenticated = auth;
      console.log('is authenticated', auth);
      if (!this.isAuthenticated) {
        this.router.navigate(['autologin']);
      } else {
        this.oidcSecurityService.userData$.subscribe((user) => {
          console.log(user)
          this.NNumber = user.employeeid;
          const usernamesplit = user.name.toString().split(', ');
          this.userDisplayName = usernamesplit[1] +' '+usernamesplit[0];
          this.navservice.whoCreatedUpdated = usernamesplit[1] + ' ' + usernamesplit[0] + '-' + this.NNumber;
          this.navservice.session.NNumber = this.NNumber;
          this.navservice.session.At_hash = user.at_hash;
          this.navservice.isforbidden.subscribe((value) => {
            if (value) {
          this.apiService.getAuthHash(this.navservice.session).subscribe(data => {
            if (data.body.length === 0) {
              this.apiService.postAuthHash(this.navservice.session).subscribe(response => {
                  if (response.body[0].insertedId != null) {
                    console.log(response.body[0].insertedId);
                  } else {
                    console.log('error');
                  }
                });
            } else {
              console.log('Already in audit table', data.body[0].auto_id);
            }
          });
          sessionStorage.setItem('UserNumber', this.NNumber);
          sessionStorage.setItem('UserDisplayName', this.userDisplayName)
          this.apiService.AuthorizeUser(this.NNumber).subscribe((response) => {
            let appAccess = response.body[0].CanAccess
            if(response.statusCode === 200 && appAccess ) {
              this.userData = response.body;
             
              sessionStorage.setItem('Authentication', JSON.stringify(this.userData))
              sessionStorage.setItem('UserKey', JSON.stringify(this.userData[0].UserKey))
              sessionStorage.setItem('IsAdmin', JSON.stringify(this.userData[0].Is_Admin))
              sessionStorage.setItem('Role', JSON.stringify(this.userData[0].Role))
            } else {
              sessionStorage.removeItem('Authentication');
              this.router.navigateByUrl('forbidden')
            }
          })
        }
        });
        });        
      }
    });
  }

  getIcon(severity: string) { return getIcon(severity); }

  ngOnDestroy() {
    console.log('window closed');
  }
}
