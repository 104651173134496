export class Reportfilter {
        EffectiveDateFrom: string;
        EffectiveDateTo: string;
        CompletedDateFrom?: any;
        CompletedDateTo?: any;
        ReconciledDateFrom?: any;
        ReconciledDateTo?: any;
        BookingDateFrom?: any;
        BookingDateTo?: any;
        TransactionStatus?: any;
        TransactionType?: any;
        ProfitCenter?: any;
        IssuingPaper?: any;
        AccountingPeriod?: any;
        uid?: string;
}
