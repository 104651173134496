import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuperUserGuard implements CanActivate {
  IsSuperUser: any;

  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    this.IsSuperUser = sessionStorage.getItem('Role');
    if (this.IsSuperUser === '2') {
      return true;
    } else {
        this.router.navigate(['transactions'])
      return false;
    }
  }

}
