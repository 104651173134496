import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule, TranslateLoader, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CatalystTranslateService } from './services/catalyst-translate.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    CatalystTranslateService
  ],
  exports: [
    TranslateModule
  ]
})
export class SharedModule { }
