<!-- <pre>
  {{userData$ | async | json}}
</pre> -->

<div class="main" id="wrapper">
  <div class="overlay" *ngIf="(loading$ | async)">
    <div class="center">
      <mat-progress-spinner diameter=80 mode="indeterminate" color="primary">
      </mat-progress-spinner>
    </div>
  </div>

  <div class="mat-card-cvr">
    <mat-card style="    height: 300px;
width: 650px;
/*margin-left: 280px;*/
/*margin-right: 150px;*/
/*margin-top: 125px;*/
border-radius: 0px;
/*margin-bottom: 125px;*/
box-shadow: 0px 1px 10px -1px;
 background-color: #ffd000;
">

      <mat-card-header>
        <!--mat-card-title>CSO Booking</mat-card-title-->
        <!--div mat-card-avatar class="example-header-image"></div>
      <mat-card-title>Shiba Inu</mat-card-title>
      <mat-card-subtitle>Dog Breed</mat-card-subtitle-->
      </mat-card-header>
      <mat-card-content>
        <div class="row mb-4">
          <div class="col-12">
          </div>
        </div>
        <div style="float: left;margin-top: 90px;margin-bottom: 30px;">
          <h3 style=" text-align-last: center;
                font-weight: bold;
                font-size: 20px;
                margin-left: 50px;
                margin-right: 80px;
              /*color: #ffff;*/
           ">Welcome to CSO Booking Log
          </h3>
        </div>
        <div>
          <ul style=" margin-bottom: 180px; list-style-type: none;"
            [ngStyle]="{'margin-top' : isRoleAccess ? '40px' : '105px'}">
            <li [ngStyle]="{'margin-top' : isAdmin ? '0px' : '75px' }" >
              <button type="button" *ngIf="isAdmin" class="btn btn-sm btn-primary" style="    margin: 10px;
          width: 160px; background-color: #e2f0fd;" (click)="userMaintenance()">Administration</button>
            </li>
            <li>
              <button type="button" *ngIf="isRoleAccess" class="btn btn-sm btn-primary" style="    margin: 10px;
                width: 160px; background-color: #e2f0fd;" (click)="transactions()">Booking Log</button>
            </li>
            <li>
              <button type="button" *ngIf="isRoleAccess" class="btn btn-sm btn-primary" style="     margin: 10px;
                width: 160px;background-color: #e2f0fd;" (click)="excel_export()">Export To Excel</button>
            </li>

          </ul>

        </div>
      </mat-card-content>
      <!--mat-card-actions>
      <button mat-button>LIKE</button>
      <button mat-button>SHARE</button>
    </mat-card-actions-->
    </mat-card>

  </div>

</div>