import { Routes } from '@angular/router';
import { ProductType } from 'src/shared/enums/product-type';
import { ExampleProductComponent } from './example-product.component';
import { ExampleProductHomeComponent } from './components/home/example-product-home.component';
import { ExampleProductNewApplicationComponent } from './components/new-application/example-product-new-application.component';

export const routes: Routes = [
    {
        path: ProductType.ExampleProduct,
        component: ExampleProductComponent,
        children: [
            {
                path: '',
                component: ExampleProductHomeComponent
            },
            {
                path: 'home',
                component: ExampleProductHomeComponent
            },
            {
                path: 'new-application',
                component: ExampleProductNewApplicationComponent
            }
        ]
    }
];
