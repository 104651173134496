import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { process, State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, SelectableSettings } from '@progress/kendo-angular-grid';
import { NavigationService } from 'src/app/services/navigation.service';
import { usersModel } from 'src/app/components/Models/user.model';
import { actions, stringIsNullOrEmpty } from 'src/app/helpers/common';
import { AdminGrid } from 'src/app/components/Models/searchResultGrid';
import { ApiService } from 'src/app/services/api.service';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { UserConfirmDialogComponent } from 'src/app/components/confirm-dialog/userconfirm-dialog.component';


@Component({
  selector: 'catalyst-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.scss']
})
export class UserMaintenanceComponent implements OnInit {

  loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();

  readonly grid = AdminGrid;
  public selectableSettings: SelectableSettings;
  public mySelection: string[] = [];
  public group: any[] = [];
  public NNumber: string;

  isSubmitted: boolean = false;
  // public userDetailsList : usersModel[] = [];

  advDetailsFlag: boolean = false;
  activeCheck: boolean = false;
  adminSelected: boolean = false;

  clickedRowItem: any;
  actionType: string;

  gridData: any[] = [];

  roleSelected: any = "0";

  firstNameTxt: string = '';
  lastNameTxt: string = '';
  domainTxt: string = '';
  nNumberTxt: string = '';

  public roleTypeList = [];

  displayGrid: boolean;
  ObectData: any;
  sessionData: any;
  securityUserKey: number;
  private _UserName: string;

  @ViewChild(UserConfirmDialogComponent) userDialog: UserConfirmDialogComponent;
  userOpenDialog: boolean = false;

  constructor(
    private navigationService: NavigationService, public apiService: ApiService,
    public datePipe: DatePipe, public messageService: MessageService
  ) { this.allData = this.allData.bind(this) }

  public GridPolicyData: GridDataResult;
  public gridDataResult: GridDataResult = { data: [], total: 0 };

  public state: State = {
    skip: 0,

    take: 1000,
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public gridView: GridDataResult;

  userInformation: any;

  // public userdetail : GridDataResult = process(this.userDetailsList, this.state)

  ngOnInit(): void {
    this.advDetailsFlag = false;
    this.adminSelected = false;
    this.activeCheck = false;
    this.actionType = actions.Add;
    this.NNumber = sessionStorage.getItem('UserNumber');
    this._UserName = sessionStorage.getItem('UserDisplayName');
    this.securityUserKey = parseInt(sessionStorage.getItem('UserKey'), 10);
    this.loadingSubject.next(true);

    // console.log(this.NNumber, this._UserName, this.securityUserKey)

    this.getRolesInformation();

    this.navigationService.removeAllMenus();
    this.navigationService.onaddpage = false;
    this.navigationService.onupdatepage = false;
    this.navigationService.onusermaintenancepage = true;
    this.navigationService.showusermaintenancepage();
    this.navigationService.hideaddpage();
    this.navigationService.hidetransactiontab();
    this.navigationService.showusermaintenancetab();
    this.navigationService.hideupdatebuttonflag();
  }

  getRolesInformation() {
    this.apiService.getRoles().subscribe((data) => {
      if (data.statusCode === 200) {
        this.roleTypeList = data.body.results
        // console.log(this.roleTypeList)
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: 'Error Getting Roles Details'
        });
      }
      this.loadingSubject.next(false);
    });
  }


  public toggleUserDialog() {
    this.userOpenDialog = !this.userOpenDialog;
    if(!this.userOpenDialog) {
      this.onCancelClick();
    }
  }

  checkRequired() {
    if ((!stringIsNullOrEmpty(this.firstNameTxt.trim())) && !stringIsNullOrEmpty(this.lastNameTxt.trim()) &&
      !stringIsNullOrEmpty(this.nNumberTxt.trim()) && (this.roleSelected > 0 || this.adminSelected) ) {
      this.isSubmitted = true;
    } else {
      this.isSubmitted = false;
    }
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick(event) {
    let rowIndex;
    if (event.composed) {
      rowIndex = event.target.parentElement.rowIndex;
      console.log(rowIndex);

      if (rowIndex !== undefined) {
        // this.loadingSubject.next(true);
        console.log(this.clickedRowItem);

        if (this.clickedRowItem.Is_Admin === 'true') {
          this.adminSelected = true;
        } else if (this.clickedRowItem.Is_Admin === 'false') {
          this.adminSelected = false;
        }

        if (this.clickedRowItem.CanAccess === 'true') {
          this.activeCheck = true;
        } else if (this.clickedRowItem.CanAccess === 'false') {
          this.activeCheck = false;
        }

        if (!stringIsNullOrEmpty(this.clickedRowItem.CreatedBy) &&
          !stringIsNullOrEmpty(this.clickedRowItem.UName)) {
          this.actionType = actions.Update;
          this.firstNameTxt = this.clickedRowItem.FirstName;
          this.lastNameTxt = this.clickedRowItem.LastName;
          this.nNumberTxt = this.clickedRowItem.NNumber;
          this.roleSelected = this.clickedRowItem.RoleId;
          this.showAdv();
          this.checkRequired();
          this.loadingSubject.next(false);
        }
      }
    }
  }

  onAddClick() {
    this.clickedRowItem = [];
    this.activeCheck = true;
    this.showAdv();
  }

  onCancelClick() {
    this.firstNameTxt = "";
    this.lastNameTxt = "";
    this.nNumberTxt = "";
    this.roleSelected = "0";
    this.advDetailsFlag = false;
    this.adminSelected = false;
    this.activeCheck = false;
    this.isSubmitted = false;
    this.actionType = actions.Add;
    this.clickedRowItem = [];
    this.gridData = [];
    const state: State = {
      skip: 0,
      take: 100,
      filter: {
        logic: 'and',
        filters: [{ field: 'username', operator: 'contains', value: '' }]
      }
    };
    const newState = state as DataStateChangeEvent;
    this.dataStateChange(newState);
  }

  public allData(): ExcelExportData {
    this.loadingSubject.next(true);
    const tempdata = this.gridData;
    console.log('TEMPDATA.. ', tempdata);
    const excelState: State = Object.assign({}, this.state);
    excelState.skip = 0;
    excelState.take = tempdata.length;
    const result: ExcelExportData = {
      data: process(tempdata, excelState).data
    };
    this.loadingSubject.next(false);
    return result;
  }

  showAdv() {
    this.advDetailsFlag = true;
  }

  filterRecord() {
    this.loadingSubject.next(true);
    const state: State = {
      skip: 0,
      take: 100,
      filter: {
        logic: 'and',
        filters: []
      }
    };

    const newState = state as DataStateChangeEvent;
    this.dataStateChange(newState);
    this.advDetailsFlag = false;
    let payload_data = {
      firstName: this.firstNameTxt ? this.firstNameTxt : '',
      lastName: this.lastNameTxt ? this.lastNameTxt : '',
      nNumber: this.nNumberTxt ? this.nNumberTxt : ''
    }

    this.apiService.searchUserData(payload_data).subscribe((data) => {
      if (data.statusCode === 200) {

        data.body.results.forEach(rec => {
          rec.CreatedDate = rec.CreatedDate != null ? new Date(this.datePipe.transform(rec.CreatedDate, 'MM/dd/yyyy')) : null;
        });

        this.gridData = data.body.results;

        console.log(data.body.results);

        const newState = this.state as DataStateChangeEvent
        console.log(newState);
        this.dataStateChange(newState);

        if (!(this.gridData.length > 0)) {

          this.messageService.add({
            severity: 'info',
            summary: 'Info:-',
            detail: 'No Results Found for the Specified Filters.',
          });
        }
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: 'Data Not Found'
        });
        this.loadingSubject.next(false);
      }
      this.loadingSubject.next(false);
    }, (error) => {
      this.loadingSubject.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error on load Policy, please try to search the Policy again',
        detail: error.body
      });
    });
  }

  public dataStateChange($event): void {
    this.state = $event;
    console.log(this.state);
    this.GridPolicyData = process(this.gridData, this.state);
  }

  addUpdateUser(e) {
    this.sessionData = JSON.parse(sessionStorage.getItem('Authentication'));
    this.ObectData = this.sessionData[0];
    if (this.validateUserDetails()) {
      this.loadingSubject.next(true);
      const user = {
        Action: this.actionType.trim(),
        FirstName: this.firstNameTxt.trim(),
        LastName: this.lastNameTxt.trim(),
        NNumber: this.nNumberTxt.trim(),
        Is_Admin: this.adminSelected ? this.adminSelected : false,
        Role: this.roleSelected,
        CrUpdBy: this.ObectData.UserKey,
        UserKey: actions.Add ? null : this.clickedRowItem.securityUserKey,
        CanAccess: this.activeCheck ? this.activeCheck : false
      }
      if (user.Action === actions.Add) {
        this.apiService.addOrUpdateUser(user).subscribe(response => {
          if (response.statusCode === 200) {
            if (this.actionType === actions.Add) {
              // this.messageService.add({
              //   severity: 'success',
              //   summary: 'Success:-',
              //   detail: 'User has been Added successfully.',
              // });
              this.userDialog.opened = true;
              this.userDialog.actionType = user.Action;
              this.toggleUserDialog();
            } else {
              // this.messageService.add({
              //   severity: 'success',
              //   summary: 'Success:-',
              //   detail: 'User details have been updated successfully.',
              // });
              this.userDialog.opened = true;
              this.userDialog.actionType = user.Action;
              this.toggleUserDialog();
            }
            // this.onCancelClick();
          } else {
            // this.messageService.add({
            //   severity: 'success',
            //   summary: 'Success:-',
            //   detail: 'User details have been updated successfully.',
            // });
            this.userDialog.opened = true;
            this.userDialog.actionType = actions.Update;
            this.toggleUserDialog();
          }
          this.loadingSubject.next(false);
        }, error => {
          if (!stringIsNullOrEmpty(error.error)) {
            const errorRec = error.error;
            if (!stringIsNullOrEmpty(errorRec.statusCode) && errorRec.statusCode === 500) {
              if (!stringIsNullOrEmpty(errorRec.body)) {
                console.log(errorRec)
                this.messageService.add({
                  severity: 'error',
                  summary: 'Some Error Occured',
                  detail: errorRec.body
                });
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Some Error Occured',
                  detail: errorRec.body,
                });
              }
            }
          }
          this.loadingSubject.next(false);
        });
      } else if (user.Action === actions.Update) {
        user.UserKey = this.clickedRowItem.UserKey;
        this.apiService.addOrUpdateUser(user).subscribe(response => {
          if (response.statusCode === 200) {
            if (this.actionType === actions.Update) {
              // this.messageService.add({
              //   severity: 'success',
              //   summary: 'Success:-',
              //   detail: 'User details have been updated successfully',
              // });
              this.userDialog.opened = true;
              this.userDialog.actionType = user.Action;
              this.toggleUserDialog();              
            }
            // this.onCancelClick();
            
          }
          this.loadingSubject.next(false);
        }, error => {
          if (!stringIsNullOrEmpty(error.error)) {
            const errorRec = error.error;
            if (!stringIsNullOrEmpty(errorRec.statusCode) && errorRec.statusCode === 500) {
              if (!stringIsNullOrEmpty(errorRec.body)) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Some Error Occurred.',
                  detail: errorRec.body
                });
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Some Error Occured',
                  detail: errorRec.body,
                });
              }
            }
          }
          this.loadingSubject.next(false);
        });
      }
    }
  }

  validateUserDetails() {
    if (this.actionType === actions.Add && ((this.nNumberTxt === this.NNumber) ||
      (this.firstNameTxt === this._UserName && !stringIsNullOrEmpty(this._UserName)))) {
      // alert('You cannot add your own permissions.')
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning:-',
        detail: 'You cannot add your own permissions.',
      });
      return false;
    }
    if (this.actionType === actions.Update && this.clickedRowItem.UserKey === this.securityUserKey) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning:-',
        detail: 'You cannot modify your own permissions.',
      });
      return false;
    }
    return true;
  }

}
