import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'catalyst-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  homepage: string;

  constructor() { }

  ngOnInit(): void {
    this.homepage = environment.Homepage;
  }

}
