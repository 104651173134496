import { Component, OnInit } from '@angular/core';
import { CatalystTranslateService } from 'src/shared/services/catalyst-translate.service';
import { Language } from 'src/shared/enums/language';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'catalyst-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  AppEnv: string;
  AppVersion: string;

  currentLang = 'en';
  constructor(private readonly catalystTranslateService: CatalystTranslateService) { }

  ngOnInit() {
    // Subscribe to the translate service when the language is changed
    this.catalystTranslateService.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });
    this.AppEnv = environment.AppEnv;
    this.AppVersion = environment.AppVersion;
  }

  changeLanguage(lang: string) {
    this.catalystTranslateService.updateLanguage(Language[lang]);
  }

}
