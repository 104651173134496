<div class="main" id="wrapper">
    <div class="overlay" *ngIf="(loader | async)">
        <div class="center">
            <mat-progress-spinner diameter=80 mode="indeterminate" color="primary">
            </mat-progress-spinner>
            <span style="position: absolute;font-size: 1.5rem;">{{message}}
            </span>
        </div>
    </div>
    <div class="container-fluid px-0" id="sticky">
        <catalyst-header [userDisplayName]="userDisplayName" [isAuthenticated]="isAuthenticated"></catalyst-header>
    </div>
    <div class="container" id="mainbody">
        
        <div class="row mb-1">
            <div class="col-12">
                <!--h2>Test {{article}}</h2-->
                <!-- Enable if you want breadcrumbs. Docs: https://catalyst-component-library.lmig.com/
                    <catalyst-breadcrumbs [items]="breadcrumbs" [translationEnabled]="false"></catalyst-breadcrumbs> -->
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>

    
</div>

<div class="container-fluid px-0">
    <catalyst-footer></catalyst-footer>
</div>
