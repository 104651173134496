import {Component, Directive, OnDestroy, OnInit, ViewEncapsulation, Injectable, Inject} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NavigationService} from 'src/app/services/navigation.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl, FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ExcelExportService} from 'src/app/services/excel-export.service';
import {ApiService} from 'src/app/services/api.service';
import {Reportfilter} from 'src/app/components/Models/reportfilter.model';
import {Transactions} from 'src/app/components/Models/transactions.model';
import {TransactionType} from 'src/app/components/Models/transactiontype.model';
import {ProfitCenter} from 'src/app/components/Models/profitcenter.model';
import {TransactionStatus} from 'src/app/components/Models/transactionstatus.model';
import {Accountperiod} from 'src/app/components/Models/excelaccountingperiod.model';
import {ExcelModel} from 'src/app/components/Models/excelexport.model';
import {BehaviorSubject} from 'rxjs';
import {MessageService} from 'primeng/api';
import {DatePipe, DecimalPipe} from '@angular/common';
import {environment} from 'src/environments/environment';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, NativeDateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';


@Component({
  selector: 'catalyst-excel-export',
  templateUrl: './excel-export.component.html',
  styleUrls: ['./excel-export.component.scss'],
  providers: [MessageService, DatePipe, DecimalPipe]
})
export class ExcelExportComponent implements OnInit {

  public reportfilterForm: FormGroup;
  responsedata: any;
  dataForExcel = [];
  loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  today: Date;
  lastDayOfMonth: Date;
  message;
  TransactionTypeList: string[] = [];
  TransactionStatusList: string[] = [];
  AccountPeriodList: string[] = [];
  ProfitCenterList: string[] = [];
  selectedtrxnstatus: string[] = [];
  selectedtrxntype: string[] = [];
  selectedprofitcenter: string[] = [];
  selectedaccountperiod: string[] = [];
  masterresponsebody: any;
  isformvalid = false;
  isdatevalid = false;
  TransactionType: IHash = {};
  TransactionStatus: IHash = {};
  innerhtml: any = '';
  innerhtml2: any = '';
  headers = ['Transaction ID',
    'Who Requested',
    'Transaction Type',
    'Transaction Source',
    'Source',
    'Accounting Period',
    'Account Name',
    'Policy Number',
    'Issuing Paper',
    'Effective Date',
    'Complexity Codes',
    'Profit Center',
    'Fac',
    'Quote Share',
    'XOL',
    'IIS Responsible',
    'Reins',
    'Currency',
    'Limit',
    'Part Of',
    'Attachment',
    'Trx Amount',
    'TFS',
    'Commission',
    'Written Line%',
    'Trx Status',
    'Received to Booking',
    'Completed Date',
    'Date Reconciled',
    'When Created',
    'Who Created',
    'When Updated',
    'Who Updated',
    'Notes',
    'Taxes',
    'Fees',
    'Surcharges',
    'Calculated TFS',
    'Participant 1',
    'Participant 1 %',
    'Participant 1 Fee%',
    'Participant 2',
    'Participant 2 %',
    'Participant 2 Fee%',
    'Participant 3',
    'Participant 3 %',
    'Participant 3 Fee%',
    'Participant 4',
    'Participant 4 %',
    'Participant 4 Fee%',
    'Participant 5',
    'Participant 5 %',
    'Participant 5 Fee%'];
  routeData: string;

  constructor(private navigationService: NavigationService,
              public dialogRef: MatDialogRef<ExcelExportComponent>, private excelservice: ExcelExportService,
              private apiservice: ApiService, private messageService: MessageService,
              private datepipe: DatePipe, private fb: FormBuilder,
              private decimalpipe: DecimalPipe,
              @Inject(MAT_DIALOG_DATA) public data: string) {
  }

  ngOnInit(): void {

    this.loadingSubject.next(true);
    this.today = new Date();
    this.lastDayOfMonth = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0);
    this.routeData = this.data;
    this.reportfilterForm = this.fb.group({
      effectivedatefrom: new FormControl(null),
      effectivedateto: new FormControl(this.lastDayOfMonth),
      completeddatefrom: new FormControl(null),
      completeddateto: new FormControl(this.lastDayOfMonth),
      datereconciledfrom: new FormControl(null),
      datereconciledto: new FormControl(this.lastDayOfMonth),
      receivedtobookingdatefrom: new FormControl(null),
      receivedtobookingdateto: new FormControl(this.lastDayOfMonth),
      trxnstatus: new FormControl(null),
      trxntype: new FormControl(null),
      profitcenter: new FormControl(null),
      accountperiod: new FormControl(null),
    });
    this.apiservice.getmasteraccountperiodforExcel().subscribe(accountperioddata => {
      let accountperiod: Accountperiod[] = [];
      accountperiod = accountperioddata.body;
      accountperiod.forEach((value) => {
        this.AccountPeriodList.push(value['Accounting Period']);
      });
    });
    this.apiservice.getmastersdata().subscribe(masterdata => {
      let transactiontype: TransactionType[] = [];
      let profitcenter: ProfitCenter[] = [];
      let transactionstatus: TransactionStatus[] = [];
      this.masterresponsebody = masterdata.body;
      transactiontype = this.masterresponsebody[7];
      profitcenter = this.masterresponsebody[4];
      transactionstatus = this.masterresponsebody[6];
      transactiontype.forEach((value) => {
        const code: string = value.transactiontype.transactiontypecode;
        const desc: string = value.transactiontype.transactiontypedesc;
        this.TransactionTypeList.push(desc);
        this.TransactionType[desc] = code;
      });
      profitcenter.forEach((value) => {
        this.ProfitCenterList.push(value.profitcenter.profitcenter);
      });
      transactionstatus.forEach((value) => {
        const code: string = value.transactionstatus.code;
        const desc: string = value.transactionstatus.description;
        this.TransactionStatusList.push(desc);
        this.TransactionStatus[desc] = code;
      });
      this.loadingSubject.next(false);
    });
  }

  resetall() {
    this.reportfilterForm.patchValue({
      effectivedatefrom: null,
      effectivedateto: this.lastDayOfMonth,
      completeddatefrom: null,
      completeddateto: this.lastDayOfMonth,
      datereconciledfrom: null,
      datereconciledto: this.lastDayOfMonth,
      receivedtobookingdatefrom: null,
      receivedtobookingdateto: this.lastDayOfMonth,
      trxnstatus: [],
      trxntype: [],
      profitcenter: [],
      accountperiod: []
    });
  }

  checkdatevalidation() {
    this.isdatevalid = true;
    const effdatefrom = this.reportfilterForm.get('effectivedatefrom').status;
    const effdateto = this.reportfilterForm.get('effectivedateto').status;
    const compdatefrom = this.reportfilterForm.get('completeddatefrom').status;
    const compdateto = this.reportfilterForm.get('completeddateto').status;
    const recondatefrom = this.reportfilterForm.get('datereconciledfrom').status;
    const recondateto = this.reportfilterForm.get('datereconciledto').status;
    const bookingdatefrom = this.reportfilterForm.get('receivedtobookingdatefrom').status;
    const bookingtodate = this.reportfilterForm.get('receivedtobookingdateto').status;

    if (effdatefrom === 'INVALID' || effdateto === 'INVALID' ||
      compdatefrom === 'INVALID' || compdateto === 'INVALID' ||
      recondatefrom === 'INVALID' || recondateto === 'INVALID' ||
      bookingdatefrom === 'INVALID' || bookingtodate === 'INVALID') {
      this.isdatevalid = false;
      this.message = 'For example, you may have entered text in a numeric or date field or a number that is larger  \
        than the field size setting permits.';
    }
  }


  checkvalidations() {
    // aanchal handle blank/ null values
    const effdatefrom = this.datepipe.transform(this.reportfilterForm.get('effectivedatefrom').value, 'yyyy-MM-dd');
    const effdateto = this.datepipe.transform(this.reportfilterForm.get('effectivedateto').value, 'yyyy-MM-dd');
    const compdatefrom = this.datepipe.transform(this.reportfilterForm.get('completeddatefrom').value, 'yyyy-MM-dd');
    const compdateto = this.datepipe.transform(this.reportfilterForm.get('completeddateto').value, 'yyyy-MM-dd');
    const recondatefrom = this.datepipe.transform(this.reportfilterForm.get('datereconciledfrom').value, 'yyyy-MM-dd');
    const recondateto = this.datepipe.transform(this.reportfilterForm.get('datereconciledto').value, 'yyyy-MM-dd');
    const bookingdatefrom = this.datepipe.transform(this.reportfilterForm.get('receivedtobookingdatefrom').value, 'yyyy-MM-dd');
    const bookingtodate = this.datepipe.transform(this.reportfilterForm.get('receivedtobookingdateto').value, 'yyyy-MM-dd');

    if ((effdatefrom != null || effdatefrom !== '') && (effdateto != null || effdateto !== '')
      && (effdatefrom > effdateto)) {
      this.message = 'Effective Date To should be Greater than Effective Date From';
      this.isformvalid = false;
    } else if ((compdatefrom != null || compdatefrom !== '') && (compdateto != null || compdateto !== '')
      && (compdatefrom > compdateto)) {
      this.message = 'Completed Date To should be Greater than Completed Date From';
      this.isformvalid = false;
    } else if ((recondatefrom != null || recondatefrom !== '') && (recondateto != null || recondateto !== '')
      && (recondatefrom > recondateto)) {
      this.message = 'Date Reconciled To should be Greater than Date Reconciled From';
      this.isformvalid = false;
    } else if ((bookingdatefrom != null || bookingdatefrom !== '') && (bookingtodate != null || bookingtodate !== '')
      && (bookingdatefrom > bookingtodate)) {
      this.message = 'Received to booking Date To should be Greater than Received to booking Date From';
      this.isformvalid = false;
    } else {
      this.isformvalid = true;
    }
  }


  close() {
    if (this.navigationService.unhideonexcelclose) {
      if (this.routeData === '/user-maintenance') {
        this.navigationService.removeAllMenus();
        this.navigationService.showusermaintenancepage();
        this.navigationService.hideaddpage();
        this.navigationService.hidetransactiontab();
        this.navigationService.showusermaintenancetab();
        this.navigationService.hideupdatebuttonflag();
      } else {
        this.navigationService.showtab();
      }

      this.dialogRef.close();
    } else {
      this.dialogRef.close();
    }
  }

  exportToExcel() {
    this.checkdatevalidation();
    this.checkvalidations();
    if (this.isformvalid && this.isdatevalid) {
      this.loadingSubject.next(true);
      const reportfilter: Reportfilter = new Reportfilter();
      reportfilter.EffectiveDateFrom = this.datepipe.transform(this.reportfilterForm.get('effectivedatefrom').value, 'yyyy-MM-dd');
      reportfilter.EffectiveDateTo = this.datepipe.transform(this.reportfilterForm.get('effectivedateto').value, 'yyyy-MM-dd');
      reportfilter.CompletedDateFrom = this.datepipe.transform(this.reportfilterForm.get('completeddatefrom').value, 'yyyy-MM-dd');
      reportfilter.CompletedDateTo = this.datepipe.transform(this.reportfilterForm.get('completeddateto').value, 'yyyy-MM-dd');
      reportfilter.ReconciledDateFrom = this.datepipe.transform(this.reportfilterForm.get('datereconciledfrom').value, 'yyyy-MM-dd');
      reportfilter.ReconciledDateTo = this.datepipe.transform(this.reportfilterForm.get('datereconciledto').value, 'yyyy-MM-dd');
      reportfilter.BookingDateFrom = this.datepipe.transform(this.reportfilterForm.get('receivedtobookingdatefrom').value, 'yyyy-MM-dd');
      reportfilter.BookingDateTo = this.datepipe.transform(this.reportfilterForm.get('receivedtobookingdateto').value, 'yyyy-MM-dd');
      if (this.selectedtrxnstatus.length > 0) {
        const trxnstatuscodelist: string[] = [];
        this.selectedtrxnstatus.forEach((value) => {
          trxnstatuscodelist.push(this.TransactionStatus[value]);
        });
        reportfilter.TransactionStatus = trxnstatuscodelist.toString();
      } else {
        reportfilter.TransactionStatus = null;
      }
      if (this.selectedtrxntype.length > 0) {
        const trxntypecodelist: string[] = [];
        this.selectedtrxntype.forEach((value) => {
          trxntypecodelist.push(this.TransactionType[value]);
        });
        reportfilter.TransactionType = trxntypecodelist.toString();
      } else {
        reportfilter.TransactionType = null;
      }
      reportfilter.ProfitCenter = this.selectedprofitcenter.length > 0 ? this.selectedprofitcenter.toString() : null;
      reportfilter.AccountingPeriod = this.selectedaccountperiod.length > 0 ? this.selectedaccountperiod.toString() : null;
      console.log(reportfilter);
      this.apiservice.getExcelData(reportfilter).subscribe(
        data => {
          const dataForExcel1: ExcelModel[] = [];
          this.responsedata = data.body.body;
          if (this.responsedata <= 0) {
            this.innerhtml2 = 'Warning: No Records Found';
            this.innerhtml = 'No Records Found for the Applied filters';
            this.messageService.add({
              severity: 'warn',
              summary: 'No Records Found',
              detail: '',
            });
            this.loadingSubject.next(false);
          } else {
            this.apiservice.getExcelDataS3(this.responsedata).subscribe(
              (res: any) => {
                this.apiservice.exportExcelCount++;
                console.log('response', res);
                this.responsedata = res.results;
                if (this.responsedata.length > 0) {
                  this.innerhtml2 = 'Success: Please Wait';
                  this.innerhtml = 'The Excel is being Downloaded';
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Please Wait',
                    detail: '',
                  });
                  // console.log(this.responsedata);
                  this.responsedata.forEach((row: ExcelModel) => {
                    row.Limits = this.decimalpipe.transform(row.Limits, '1.2-2', 'en');
                    row.PartOf = this.decimalpipe.transform(row.PartOf, '1.2-2', 'en');
                    row.Attachment = this.decimalpipe.transform(row.Attachment, '1.2-2', 'en');
                    row.TrxAmount = this.decimalpipe.transform(row.TrxAmount, '1.2-2', 'en');
                    row.Taxes = this.decimalpipe.transform(row.Taxes, '1.2-2', 'en');
                    row.Fees = this.decimalpipe.transform(row.Fees, '1.2-2', 'en');
                    row.Surcharges = this.decimalpipe.transform(row.Surcharges, '1.2-2', 'en');
                    row.CalculatedTFS = this.decimalpipe.transform(row.CalculatedTFS, '1.2-2', 'en');
                    this.dataForExcel.push(Object.values(row));
                    dataForExcel1.push(row);
                  });
                  const reportData = {
                    title: 'ExcelExport',
                    data: this.dataForExcel,
                    headers: this.headers
                  };
                  this.excelservice.exportExcel(reportData);
                  this.dataForExcel = [];
                } else {
                  this.innerhtml2 = 'Warning: No Records Found';
                  this.innerhtml = 'No Records Found for the Applied filters';
                  this.messageService.add({
                    severity: 'warn',
                    summary: 'No Records Found',
                    detail: '',
                  });
                }
                this.loadingSubject.next(false);
              },
              err => {
                console.log('err', err);
              }
            );
          }

        }, (error: HttpErrorResponse) => {
          switch (error.status ) {
            case 501:
            case 0:
            case 504:
            this.innerhtml2 = 'Warning: Excel Export Limit Reached';
            this.innerhtml = 'The number of records you are trying to export exceeds the set limit of 7500. ' +
              'If you need to export more records than the threshold permits either break up the search ' +
              'criteria into separate manageable chunks or visit the BI reporting link ' +
              '<a href="' + environment.PowerBI + '" target="_blank">Power BI</a>' +
              ' . BI report tool has real time connectivity with the system and contains all the required search parameters.';
            this.messageService.add({
              severity: 'warn',
              summary: 'Excel Export Limit Reached',
              detail: '',
              life: 20000
            });
            break;
            case 502 :
              this.innerhtml2 = 'Warning: No Records Found';
              this.innerhtml = 'No Records Found for the Applied filters';
              this.messageService.add({
                severity: 'warn',
                summary: 'No Records Found',
                detail: '',
              });
              break;
          }
          this.loadingSubject.next(false);
        });
    } else {
      this.innerhtml2 = 'Warning: The value you entered is not valid for this field';
      this.innerhtml = this.message;
      this.messageService.add({
        severity: 'warn',
        summary: 'The value you entered is not valid for this field',
        detail: '',
        life: 5000
      });
    }
  }
}

export interface IHash {
  [key: string]: string;
}

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if (typeof value === 'string') {
      let str = null;
      if ((value.indexOf('/') > -1)) {
        str = value.split('/');
      } else if ((value.indexOf('-') > -1)) {
        str = value.split('-');
      } else if ((value.indexOf('.') > -1)) {
        str = value.split('.');
      } else if (value !== '' && value != null) {
        str = 'invalid date';
        const year1 = Number(str[2]);
        const month1 = Number(str[1]) - 1;
        const date1 = Number(str[0]);
        return new Date(year1, month1, date1);
      }
      if (str === null) {
        return null;
      }
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      if (date > 12) {
        return new Date(year, month, date);
      } else if (isNaN(date) || isNaN(month) || isNaN(year)) {
        return new Date(year, month, date);
      }
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}

