<div class="row mb-2">
    <div class="col-6">
        <h1>Example Product</h1>
    </div>
    <div class="col-6">
        <button type="button" [routerLink]="['new-application']" class="btn btn-primary btn-rounded float-right"> <i
                class="fal fa-fw fa-file-plus"></i> New application</button>
    </div>
</div>

<fieldset class="card p-lg-4 p-md-3 p-2" id="section-name">
    <div class="row">
        <legend class="col-form-label col-12">Product Module Design</legend>
    </div>
    <div class="fields">
        <p>The Catalyst Lite template has been set up using a modular design taking advantage of  <a href="https://angular.io/guide/architecture-modules" target="_blank">angular modules.</a></p>
        <p>This allows support for multi product development with each product having their own module, components, routing and more.</p>
        <p>There are 3 <b>core</b> modules and a module for each product:</p>
        <ul>
            <li><b>App Module</b> - <small>This is the core bootstrapped module importing other modules</small></li>
            <li><b>Shared Module</b> - <small>This allows common artifacts to be shared e.g. enums, services across the modules.</small></li>
            <li><b>Test Module</b> - <small>This contains helpful artifacts required for testing to be shared e.g. mocked pipes</small></li>
            <li>Product <i>N</i> Module - <small>This is the product specific module for your business application with independent routing</small></li>
        </ul>

        <div class="row">
            <div class="col-12 text-center">
                <img src="assets/images/TODO REMOVE/module design.png" />
            </div>
        </div>
    </div>
</fieldset>
