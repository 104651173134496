import { ColumnSetting } from './columnsetting';


export const AdminGrid: ColumnSetting[] = [
    { field: 'UName', title: 'User Name', type: 'text', primarykey: true, width: '180', editable: false },
    { field: 'NNumber', title: 'NNumber', type: 'text', primarykey: true, width: '130', editable: false },
    { field: 'Role', title: 'Role Type', type: 'text', width: '100', editable: false},
    { field: 'CreatedDate', title: 'Created Date', width: '180', type: 'date', format: 'MM/dd/yyyy', editable: false },
    { field: 'CreatedBy', title: 'Added By', width: '155', editable: false, type: 'text' },
    { field: 'UpdatedBy', title: 'Updated By', width: '155', type: 'text', editable: false },
  ];